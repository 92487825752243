import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderLoginComponent } from './provider/provider-login/provider-login.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ProviderBaseComponent } from './provider/provider-base/provider-base.component';
import { ProviderPasswordResetComponent } from './provider/provider-password-reset/provider-password-reset.component';
import { ProviderRegistrationComponent } from './provider/provider-registration/provider-registration.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ProviderUsernameForgotComponent } from './provider/provider-username-forgot/provider-username-forgot';
import { AppFormsModule } from '../forms/app-forms.module';
import { DnErrorDisplayModule } from '../dn-common/dn-error-display/dn-error-display.module';
import { YmlLoaderService } from '../shared/services/yml-loader.service';
import { AppRoutingModule } from '../app-routing.module';
import { FormsModule } from '@angular/forms';
import { DnCommonModule } from '../dn-common/dn-common.module';
import { ProviderEmailVerifiedComponent } from './provider/provider-email-verified/provider-email-verified.component';
import { JourneyPasswordResetComponent } from './journey-password-reset/journey-password-reset.component';
import { JourneyRecoverUsernameComponent } from './journey-recover-username/journey-recover-username.component';
import { JourneyLoginComponent } from './journey-login/journey-login.component';
import { JourneyChangeEmailComponent } from './journey-change-email/journey-change-email.component';
import { ProviderProfileComponent } from './provider/provider-profile/provider-profile.component';
import { JourneyChangePasswordComponent } from './journey-change-password/journey-change-password.component';
import { JourneyLogoutComponent } from './journey-logout/journey-logout.component';

@NgModule({
  declarations: [
    ProviderLoginComponent,
    ProviderBaseComponent,
    ProviderPasswordResetComponent,
    ProviderRegistrationComponent,
    ProviderUsernameForgotComponent,
    ProviderEmailVerifiedComponent,
    JourneyPasswordResetComponent,
    JourneyRecoverUsernameComponent,
    JourneyLoginComponent,
    JourneyChangeEmailComponent,
    ProviderProfileComponent,
    JourneyChangePasswordComponent,
    JourneyLogoutComponent
  ],
  imports: [
    NgbNavModule,
    AppRoutingModule,
    CommonModule,
    AppFormsModule,
    FormsModule,
    DnErrorDisplayModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: YmlLoaderService
      },
      defaultLanguage: 'en'
    }),
    DnCommonModule
  ]
})
export class JourneyModule { }
