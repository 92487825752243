import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForgeRockService } from '../../../shared/services/forge-rock.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'app-provider-login',
  templateUrl: './provider-login.component.html',
  styleUrl: './provider-login.component.css'
})
export class ProviderLoginComponent implements OnInit, OnDestroy {
  showSpinner = false;
  subscription !: Subscription;
  createAccountUrl !: string;
  constructor(private FRService: ForgeRockService,
    protected router: Router,
    private route:ActivatedRoute,
    protected userService: UserService){}

  ngOnInit(): void {
    let userType = this.route.parent?.snapshot.data['type'];
    this.FRService.loadConfig(userType).subscribe((config => {
      this.createAccountUrl = config.redirects?.find((r) => r.name == 'createAccount')?.value;
    }));
    this.subscription = this.FRService.OnLoginSuccess.subscribe((resp:any)=>{
      if (resp && resp.loginSuccess) {
        this.showSpinner = resp.loginSuccess;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }

  onForgotUsername(e: any){
    e.preventDefault();
    this.router.navigate(['../username'], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }

  onForgotPassword(e:Event) {
    e.preventDefault();
    this.router.navigate(['../password'], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }
}
