import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppFormsService {

  @Output() onShowErrors : EventEmitter<any> = new EventEmitter<any>();
  @Output() onEmailChanged = new BehaviorSubject<any>({});

  constructor() { }

  public showErrors(val:any) {
      this.onShowErrors.emit(val);
  }

  private userNameSubject = new BehaviorSubject<string>('');
  public onUserNameChanged = this.userNameSubject.asObservable();

  public setUsername(username: string) {
    this.userNameSubject.next(username);
  }

}
