import n from "./index.js";
class u extends n {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the callback prompt.
   */
  getPrompt() {
    return this.getOutputByName("prompt", "");
  }
  /**
   * Gets the callback's list of pre-defined security questions.
   */
  getPredefinedQuestions() {
    return this.getOutputByName("predefinedQuestions", []);
  }
  /**
   * Sets the callback's security question.
   */
  setQuestion(t) {
    this.setValue("question", t);
  }
  /**
   * Sets the callback's security question answer.
   */
  setAnswer(t) {
    this.setValue("answer", t);
  }
  setValue(t, s) {
    if (!this.payload.input) throw new Error("KBA payload is missing input");
    const e = this.payload.input.find(i => i.name.endsWith(t));
    if (!e) throw new Error(`No input has name ending in "${t}"`);
    e.value = s;
  }
}
export { u as default };