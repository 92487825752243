import e from "./index.js";
class r extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the message to display while polling.
   */
  getMessage() {
    return this.getOutputByName("message", "");
  }
  /**
   * Gets the polling interval in milliseconds.
   */
  getWaitTime() {
    return Number(this.getOutputByName("waitTime", 0));
  }
}
export { r as default };