import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, ValidationErrors } from '@angular/forms';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { AppFormsService } from '../app-forms.service';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { UsernameGuidelinesModalComponent } from '../../dn-common/components/username-guidelines-modal/username-guidelines-modal.component';

@Component({
  selector: 'input-username',
  templateUrl: './input-username.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputUsernameComponent extends AbstractErrorControlComponent implements OnInit, AfterViewInit  {
  @Input() username !: string | undefined;
  @Input() name = "username";
  @Input() passwordOnly : boolean = true;
  @Input() showUsernameGuidelines : boolean = false;
  @Input() inlineForgotUsername = false;
  @Input() autofocus = false;
  protected _editable = true;
  @Input() set editable(val:boolean) {
    this._editable = val;
  } 
  protected _isUnique: ValidationErrors | null = null;
  @Input()
  public set isUnique(val: ValidationErrors | null) {
    this._isUnique = val;
  }

  override dispayErrorList = ['userNameAlphanumericOnly','minlength','userNameShouldNotMatch','uniqueValue','failedPolicy'];

  usernameMinLen : any = null;
  usernameMaxLen : any = null;

  constructor(private modalService : NgbModal,
    private router: Router,
    private route:ActivatedRoute,
    private loginForm: NgForm,
    override formsService: AppFormsService
) {
super(formsService);
}

ngOnInit(): void {
  if ( !this.passwordOnly ) {
    this.usernameMinLen = environment.common.constants.userNameMinLength;
    this.usernameMaxLen = environment.common.constants.userNameMaxLength;
  }
}
override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.showErrors(this.dispayErrorList);
}

  onForgotUsername(e:Event) {
    e.preventDefault();
    let action = 'forgot';
    let userEmail =  this.loginForm.form.controls['userEmail'];
    this.router.navigate(['../username',action], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }

  onUsernameChange() {
    this.formsService.setUsername (this.loginForm.form.controls['username'].value);
  }

  openUsernameGuidelinesModal() {
		const modalRef = this.modalService.open(UsernameGuidelinesModalComponent, { size: 'lg', centered: true, keyboard: false });
	}

}
