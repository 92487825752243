import {Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { UserService } from '../../shared/services/user.service';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { catchError } from 'rxjs';

@Component({
  selector: 'terms-of-use',
  templateUrl: './terms-of-use.component.html'
})
export class TermsOfUseComponent implements OnInit {
  @Input() touContent !: string;
  @Input() touCheckBoxName !: string;

  @Output() touAcceptedEvent = new EventEmitter<void>();

  iAgreeCheckbox: boolean = false;
  touLastHeaderVisible: boolean = false;
  iAgreedInSessionStorage = false;
  isRegistered = false;
  isRunning = false;
  constructor(public router: Router, public activatedRoute: ActivatedRoute,
              private userService: UserService,
              private FRService:ForgeRockService
  ) {
  };

  ngOnInit(): void {
    this.iAgreeCheckbox = false;
    if(this.userService.touAccepted == true ) {
      this.iAgreeCheckbox = true;
      this.touLastHeaderVisible = true;
    }
    this.isRegistered = this.userService.registered;
  }

  onAccept() {
    if(this.iAgreeCheckbox) {
      //sessionStorage.setItem('iAgreeChecked', JSON.stringify(this.iAgreeCheckbox))
      this.submitTou(true);
    }
  }

  onCheckUncheck(){
    if(this.iAgreeCheckbox==true){
      this.iAgreeCheckbox = false;
      this.submitTou(false);
    }
  }

  submitTou(accepted:boolean) {
    this.isRunning = true;
    this.userService.acceptTou(accepted,this.touAcceptedEvent).pipe(
      catchError((err)=>{
        this.isRunning = false;
        throw err;
      })
      ).subscribe((val)=>{
      this.isRunning=false
    });
  }

  onDisagree() {
    this.userService.touAccepted = false;
    this.router.navigate(["home"])
  }

  onDisclaimerScroll(e:Event): any {
    let disclaimerContainer = document.getElementById("disclaimer-div");
    if (disclaimerContainer) {
      let disclaimerContainerRect = disclaimerContainer.getBoundingClientRect();
      let disclaimerContainerBottom = disclaimerContainerRect.bottom;

      let disclaimerEndElm = document.getElementsByClassName("disclaimer-end");
      if (disclaimerEndElm && !this.touLastHeaderVisible) {
        let disclaimerEndRect = disclaimerEndElm[0].getBoundingClientRect();
        let disclaimerEndTop = disclaimerEndRect.top;
        let disclaimerEndBottom = disclaimerEndRect.bottom;
        this.touLastHeaderVisible = (disclaimerEndTop > 0) && (disclaimerEndBottom < disclaimerContainerBottom);
      }
    }
  }

  disableIAgreeCheckbox () {
    if (!this.touLastHeaderVisible)
      return true;
    else
      return false;
  }

  isAccepted() {
    return this.userService.touAccepted;
  }
}
