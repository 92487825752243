import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppFormsService } from '../app-forms.service';
import { UserService } from '../../shared/services/user.service';
import { UserModel } from '../../shared/services/forge-rock.interface';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { NgForm, ValidationErrors } from '@angular/forms';
import { catchError, of, Subscription } from 'rxjs';

@Component({
  selector: 'verify-email',
  templateUrl: './verify-email.component.html'
})
export class VerifyEmailComponent implements OnInit, AfterViewInit, OnDestroy  {
  @Input() instructions !: string;
  loginRedirect !: string;
  @Input() 
  set loginUrl(val:string) {
    this.loginRedirect = val;
  }
  @ViewChild('errorControl', { static: true }) _errorControl!: DnErrorControlDirective;
  @ViewChild('verifyEmailForm', { static: true }) verifyEmailForm !: NgForm;

  @Output() onVerifyEmail = new EventEmitter<any>();

  unverifiedEmail !: any;
  running = false;
  uniqueEmailError : ValidationErrors | null = null;

  private subscription !: Subscription | undefined;
  constructor(private formsService: AppFormsService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
) {}

  ngOnInit(): void {
    this.unverifiedEmail = this.userService.unVerifiedEmail;
  }

  ngAfterViewInit(): void {
    this.subscription = this.verifyEmailForm.valueChanges?.subscribe((val)=>{
        setTimeout(()=>  this.uniqueEmailError = null);
    })
  }

  emailReadOnly() {
    return (this.userService.emailConfirmed == true);
  }

  onSubmit() {
    this.showErrors();
    if (this.verifyEmailForm.invalid) {
      return;
    }

    this.running = true;
    this.userService.uniqueEmail(this.verifyEmailForm.value.businessEmail,this.onVerifyEmail).pipe(
      catchError((error)=>{
        let message = error && error.code ? {[error.message]:true}: undefined;
        return of(message);
      })).subscribe((val) => {
          this.running = false;
          let errors = (val == undefined || val == true) ? null : val;
          let control = this.verifyEmailForm.controls["businessEmail"];
          control.setErrors(errors );
          this.uniqueEmailError =errors;
      });
  }

  private showErrors() {
    this.formsService.showErrors(true);
    this._errorControl.showErrors();
  }

  private hideErrors() {
    this.formsService.showErrors(false);
    this._errorControl.hideErrors();
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
