import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {DnErrorDisplayModule} from "../dn-common/dn-error-display/dn-error-display.module";
import { InputPasswordComponent } from './input-password/input-password.component';
import { InputEmailComponent } from './input-email/input-email.component';
import {RegistrationFormComponent} from "./registration-form/registration-form.component";
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DnCommonModule } from '../dn-common/dn-common.module';
import { InputUsernameComponent } from './input-username/input-username.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { InputOtpComponent } from './input-otp/input-otp.component';
import { OtpChoicesComponent } from './otp-choices/otp-choices.component';
import { InputIdentityComponent } from './input-identity/input-identity.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { YmlLoaderService } from '../shared/services/yml-loader.service';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';


@NgModule({
  declarations: [
    InputPasswordComponent,
    InputEmailComponent,
    RegistrationFormComponent,
    InputUsernameComponent,
    TermsOfUseComponent,
    InputOtpComponent,
    OtpChoicesComponent,
    InputIdentityComponent,
    ConfirmEmailComponent,
    VerifyEmailComponent
  ],
  imports: [
    CommonModule,
    NgbPopoverModule,
    FormsModule,
    DnErrorDisplayModule,
    DnCommonModule, NgxMaskDirective, NgxMaskPipe,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: YmlLoaderService
      },
      defaultLanguage: 'en'
    })
  ],
  exports: [
    RegistrationFormComponent,
    TermsOfUseComponent,
    InputOtpComponent,
    InputIdentityComponent,
    ConfirmEmailComponent,
    VerifyEmailComponent,
    InputUsernameComponent,
    OtpChoicesComponent,
    InputPasswordComponent,
    InputEmailComponent
  ],
  providers: [provideNgxMask()]
})
export class AppFormsModule { }
