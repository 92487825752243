import { DEFAULT_TIMEOUT as o, DEFAULT_OAUTH_THRESHOLD as s, PREFIX as l } from "./constants.js";
import { fetchWellKnown as a, convertWellKnown as f } from "./helpers.js";
function w(n) {
  return {
    ...n,
    oauthThreshold: n.oauthThreshold || s,
    logLevel: n.logLevel || "none",
    platformHeader: n.platformHeader || !1,
    prefix: n.prefix || l
  };
}
class c {
  /**
   * Sets the default options.
   *
   * @param options The options to use as defaults
   */
  static set(e) {
    if (!this.isValid(e)) throw new Error("Configuration is invalid");
    e.serverConfig && this.validateServerConfig(e.serverConfig), this.options = {
      ...w(e)
    };
  }
  /**
   * @method setAsync - Asynchronously calls the WellKnown endpoint to collect the APIs for OAuth
   * @param {AsyncConfigOptions} options - config options with wellknown endpoint URL
   * @returns {Promise<void>} - Returns a success or failure message object
   */
  static async setAsync(e) {
    if (!e.serverConfig.wellknown) throw new Error("Missing well-known property. Use `Config.set` method if not using well-known endpoint.");
    e.serverConfig.baseUrl && console.warn("The baseUrl property passed in will be ignored, and replaced with well-known origin.");
    const r = await a(e),
      i = f(r);
    delete e.serverConfig.wellknown;
    const t = e;
    t.serverConfig = i, this.set(t);
  }
  /**
   * Merges the provided options with the default options.  Ensures a server configuration exists.
   *
   * @param options The options to merge with defaults
   */
  static get(e) {
    if (!this.options && !e) throw new Error("Configuration has not been set");
    const r = {
      ...this.options,
      ...e
    };
    if (!r.serverConfig || !r.serverConfig.baseUrl) throw new Error("Server configuration has not been set");
    return r;
  }
  static isValid(e) {
    return !!(e && e.serverConfig);
  }
  static validateServerConfig(e) {
    e.timeout || (e.timeout = o);
    const r = e.baseUrl;
    r && r.charAt(r.length - 1) !== "/" && (e.baseUrl = r + "/");
  }
}
export { c as default };