<form id="identityVerificationForm" name="identityVerificationForm" #identityVerificationForm="ngForm"
      dnErrorControl="errorControl" #errorControl="dnErrorControl" scrollToErrorForm novalidate
      (ngSubmit)="verifyIdentity()">

  <input class="form-control" type="hidden" id="Err" name="Err" [(ngModel)]="identityError.message" #errModel="ngModel">

  <div class="mt-3 form-group">
    <label for="monthDay">{{'registration.verify-identity-label' | translate}}</label>
    <div class="col-sm-3 mt-1">
      <input type="text" class="form-control" id="monthDay" name="monthDay" #answer="ngModel"
            [(ngModel)]="monthDay" monthDay [errCtrl]="errModel" mask="M0/d0" [leadZeroDateTime]="true" [validation]="false"
            [showMaskTyped]="false"
            [disabled]="verified"
            required
            placeholder="MM/DD"
            [autofocus]="true"
            dnDisplayErrors [errorTarget]="errTarget">
    </div>
    <div>
      <div dnErrorTarget #errTarget="dnErrorTarget"></div>
    </div>
  </div>
  <div class="mt-3 d-flex justify-content-center">
    <button type="submit" class="btn btn-primary"  *ngIf="!verified"
            title="Verify Identity">{{'registration.verify-identity-button' | translate}} <i class="fa-solid fa-spinner" *ngIf="submitted"></i>
    </button>
  </div>
</form>
