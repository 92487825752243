
<form id="passwordResetForm" name="passwordResetForm" #passwordResetForm="ngForm" (ngSubmit)="onSubmit()"
      dnErrorControl="errorControl" #errorControl="dnErrorControl" novalidate scrollToErrorForm>

    <dn-confirmation #dnConfirm></dn-confirmation>

    <div>
      <input class="form-control" type="hidden" id="Err" name="Err" [(ngModel)]="errorCode"
              dnDisplayErrors #ErrModel="ngModel" [hasFrError]="errorMessage"
              [errorMessages]="{'loginFailureUnknown': errorMessage}">
    </div>

    <div class="row" *ngIf='nextStage == Stage.USERNAME'>
      <span [innerHTML]="headerText"></span>
      <input-username  name="username" [passwordOnly]="true" [showUsernameGuidelines]="false">
      </input-username>
      <div class="smaller-font">
        <div class="mt-1 d-flex justify-content-end">
            <a href class="link" (click)="onForgotUsername($event)">Forgot username</a>
        </div>
      </div>
    </div>

    <otp-choices [stage]="nextStage" (OnOtpMethodSelected)="setOtpChoice($event)" *ngIf="nextStage == Stage.OTP_METHOD"></otp-choices>

    <input-otp name="onetimePassword" *ngIf="nextStage == Stage.OTP || nextStage == Stage.OTP_RESEND"
      [disabled]="resending"
      [otpInstruction]="otpInstruction"
      [otpMethod]="otpChoice.otpMethod"
      [otpMaskedValue]="otpChoice.otpMaskedValue"
      (OnResendCode)="handleResendCode($event)"
      [stage]="nextStage"
      [autofocus]="nextStage == Stage.OTP || nextStage == Stage.OTP_RESEND">
    </input-otp>


    <div class="col-sm-12"  *ngIf="nextStage == Stage.PASSWORD">
      <p>{{resetWarning}}</p>
      <input-password name="passwords" [passwordOnly]="false" [registeredUsername]="username"></input-password>
    </div>

    <div class="mt-4 d-flex justify-content-center">
        <button type="submit" class="btn btn-primary"  #subButton
            [disabled]="!passwordResetForm.form.valid || submitted || resending">{{nextStage == Stage.PASSWORD ? buttonText : otpButtonText}} <i class="fa-solid fa-spinner" *ngIf="submitted"></i></button>
    </div>

</form>
