<dn-progress-spinner *ngIf="_update || showSpinner"></dn-progress-spinner>
<div class="row text-success"  *ngIf="!_update">
    <h5 class="mt-3 d-flex justify-content-center pt-5 text-success">
        <i class="fa-solid fa-circle-check fa-2xl"></i><br>
    </h5>
    <h5 class="d-flex justify-content-center">
        <p>{{headerText}}</p>
    </h5>
</div>
<div class="row">
  <div class="col-8 mt-3 mb-5   mx-auto">
    <div class="mb-3">
      <p [innerHTML]="confirmEmailTitleValue"></p>
      <span [innerHTML]="confirmEmailContentValue"></span>
        <span *ngIf="params.loginRedirect && !params.stopRedirect"> You will be redirected to the login page in <b>{{delay}} seconds.</b> You can also <a href="{{params.loginRedirect}}" class="link" (click)="showSpinner=true"> click here to log in.</a> </span>
        <div *ngIf="params.loginRedirect && params.stopRedirect==true">
          <span> Please click the button below to log in to your ProviderDNA account.</span>
            <div class="col-sm-8 mt-3 mb-3 d-flex justify-content-center">
              <a href="{{params.loginRedirect}}" (click)="showSpinner=true" class="btn btn-primary">Log in</a>
            </div>
        </div>
    </div>
  </div>
</div>



