import r from "./index.js";
class i extends r {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the index position of the confirmation's default answer.
   */
  getDefaultOption() {
    return Number(this.getOutputByName("defaultOption", 0));
  }
  /**
   * Gets the confirmation's message type.
   */
  getMessageType() {
    return Number(this.getOutputByName("messageType", 0));
  }
  /**
   * Gets the confirmation's possible answers.
   */
  getOptions() {
    return this.getOutputByName("options", []);
  }
  /**
   * Gets the confirmation's option type.
   */
  getOptionType() {
    return Number(this.getOutputByName("optionType", 0));
  }
  /**
   * Gets the confirmation's prompt.
   */
  getPrompt() {
    return this.getOutputByName("prompt", "");
  }
  /**
   * Set option index.
   */
  setOptionIndex(t) {
    if (t !== 0 && t !== 1) throw new Error(`"${t}" is not a valid choice`);
    this.setInputValue(t);
  }
  /**
   * Set option value.
   */
  setOptionValue(t) {
    const e = this.getOptions().indexOf(t);
    if (e === -1) throw new Error(`"${t}" is not a valid choice`);
    this.setInputValue(e);
  }
}
export { i as default };