import l from "../util/middleware.js";
import { withTimeout as a } from "../util/timeout.js";
import { ActionTypes as u } from "./enums.js";
function h(n, r) {
  if (!n.authorization_endpoint) throw new Error("Wellknown endpoint did not return `authorization_endpoint`");
  const t = new URL(n.authorization_endpoint).origin;
  let e, i;
  n.issuer.includes("/am/") ? (e = `${n.issuer.replace("oauth2", "json")}/authenticate`, i = `${n.issuer.replace("oauth2", "json")}/sessions`) : n.issuer.includes("/as/");
  const s = {
    ...(e ? {
      authenticate: new URL(e).pathname
    } : {}),
    authorize: new URL(n.authorization_endpoint).pathname,
    accessToken: new URL(n.token_endpoint).pathname,
    endSession: new URL(n.end_session_endpoint).pathname,
    userInfo: new URL(n.userinfo_endpoint).pathname,
    revoke: new URL(n.revocation_endpoint).pathname,
    ...(i ? {
      sessions: new URL(i).pathname
    } : {})
  };
  return {
    baseUrl: t,
    paths: s
  };
}
async function m(n) {
  if (!n.serverConfig.wellknown) throw new Error("wellknown URL is missing in options");
  const o = l({
    url: new URL(n.serverConfig?.wellknown),
    init: {
      method: "GET"
    }
  }, {
    type: u.WellKnown,
    payload: {}
  })(n.middleware);
  return await (await a(fetch(o.url.toString(), o.init), n.serverConfig.timeout)).json();
}
export { h as convertWellKnown, m as fetchWellKnown };