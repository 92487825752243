import e from "./index.js";
class r extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the reCAPTCHA site key.
   */
  getSiteKey() {
    return this.getOutputByName("recaptchaSiteKey", "");
  }
  /**
   * Get the api url
   */
  getApiUrl() {
    return this.getOutputByName("captchaApiUri", "");
  }
  /**
   * Get the class name
   */
  getElementClass() {
    return this.getOutputByName("captchaDivClass", "");
  }
  /**
   * Sets the reCAPTCHA result.
   */
  setResult(t) {
    this.setInputValue(t);
  }
  /**
   * Set client client error
   */
  setClientError(t) {
    this.setInputValue(t, "IDToken1clientError");
  }
  /**
   * Set the recaptcha payload
   */
  setPayload(t) {
    this.setInputValue(t, "IDToken1payload");
  }
  /**
   * Set the recaptcha action
   */
  setAction(t) {
    this.setInputValue(t, "IDToken1action");
  }
}
export { r as default };