import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { EmailConfirmStatus, FRStepError, Stage, UserModel } from '../../shared/services/forge-rock.interface';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { ValidationErrors } from '@angular/forms';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { DnConfirmationComponent } from '../../dn-common/components/dn-confirmation/dn-confirmation.component';

@Component({
  selector: 'abstract-journey',
  templateUrl: './abstract-journey.component.html'
})
export abstract class AbstractJourneyComponent implements OnInit, AfterViewInit {
  protected tree ?: string; 
  protected nextStage : Stage | undefined = undefined;
  protected userType ?: string;
  protected loginRedirect !: string;
  protected otpChoice !: any;
  protected resending = false;
  protected isRegistration = false;

  @Output() OnJourneyStarted  = new BehaviorSubject<Stage|undefined>(undefined);
  @Output() OnJourneyStepError  = new BehaviorSubject<FRStepError>({} as FRStepError);

  @ViewChild('errorControl', { static: true }) _errorControl!: DnErrorControlDirective;
  @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;


  constructor(protected FRService: ForgeRockService,
              protected router: Router, 
              protected route: ActivatedRoute,
              protected userService: UserService){}

  abstract getTreeName():string;        
  
  ngOnInit(): void {
    this.isRegistration = (this.getTreeName() == 'registration');
    this.tree = this.FRService.findTreeName(this.getTreeName());
    this.userType = this.route.parent?.snapshot.data['type'];
    if (!this.tree) {
      throw new ErrorEvent("Configuration error - tree not found");
    }
    this.FRService.loadConfig(this.userType).subscribe((config) => {       
      this.loginRedirect = config.redirects?.find(r => r.userType == this.userType && r.name == 'login')?.value;
    });
  }

  ngAfterViewInit(): void {
    this.setUpJourney();
  }
   
  protected restartJourney() {
    window.location.reload();
  }
  
  protected showErrors() {
    this._errorControl.showErrors();
  }

  private handleAuthenticationError() {
    this.router.navigate(['/unauthorized']);
  }
 
  private setUpJourney() {
    let goto = this.route.snapshot.queryParamMap.get("goto"); //for login redirect
    
    this.FRService.loadConfig(this.userType).subscribe((config) => {       
      this.loginRedirect = config.redirects?.find(r => r.userType == this.userType && r.name == 'login')?.value;
      let redirect_uri = goto ||  this.loginRedirect ||  window.location.href;
      this.FRService.setConfig({redirectUri: redirect_uri, tree: this.tree })
      this.startJourney();
    });
  } 

  private startJourney() {
    this.FRService.nextStep().then((nextStage) => {
      this.nextStage = nextStage;
      if (this.nextStage == Stage.LOGIN) {
        this.updateUserModel(this.nextStage,false,undefined)
      }
      this.OnJourneyStarted.next(this.nextStage);
  });
  }

  protected nextStageInJourney(formData ?:any) {
    let model = this.buildModel(formData);
    let submittingStage = this.nextStage;
    let isValidatingOnly = model.validatePassword || model.validateUsername;
    return this.FRService.nextStep(model).then((nextStage)=>{
        let err = undefined;
        let violations = this.checkForPolicyViolations(submittingStage);
        if (nextStage == Stage.LOGIN_ERROR) {
            err = 'loginError';
        }else{
          this.nextStage = nextStage;
        }
        this.updateUserModel(submittingStage, isValidatingOnly, violations != null ? violations : err);
    },(err)=>{
      this.updateUserModel(submittingStage,isValidatingOnly,err);
      throw err;
    });
  }

  protected handleResendCode(choice:string) {
    if (this.nextStage == Stage.OTP_RESEND) {
        let resendNDx = this.FRService.getChoices(Stage.OTP_RESEND)?.findIndex((c:any)=>c.toLowerCase().includes(choice));  
        this.otpChoice = {choiceIndex:resendNDx};
        this.resending = true;
        return this.nextStageInJourney({otpChoice: this.otpChoice}).then((nextStage)=>{
          this.resending = false;
          if (choice == 'resend') {
            this.dnConfirmation.show('One-time passcode has been resent. Please check your email.', 'success', 5000).subscribe();
          }
        },(err)=>{
          this.resending = false;
        });
    }else{
      return Promise.resolve("");
    } 
  }


  protected get Stage() {
    return Stage; 
  }

  protected onForgotPassword(e:Event) {
    e.preventDefault();
    this.router.navigate(['../password'], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }

  protected onForgotUsername(e:Event) {
    e.preventDefault();
    this.router.navigate(['../username'], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }

  protected confirmSuccess(sentVerificattionCode:any) {
    let url = `${this.userType}/confirm-email`
    this.router.navigate([url],{skipLocationChange:true,
                                queryParams: {status: sentVerificattionCode ? EmailConfirmStatus.VERIFICATION_CODE_SENT: EmailConfirmStatus.REGISTRATION_SUCCESS}});
  }


  private buildModel(formData ?:any) {
    formData = formData ? formData : this.userService.user;
    let model : UserModel = {} as UserModel;
    model.userType = this.userType;
    switch (this.nextStage) {
      case Stage.TOU: 
            model.touAccepted = true;
            break;
      case Stage.USER_IDENTITY: 
            model.identityAnswer = formData.identityAnswer; 
            break;  
      case Stage.VERIFIED_EMAIL:
            model.unverifiedEmail = formData.unverifiedEmail;  
            break;
      case Stage.USERNAME:
        model.validateUsername = formData.validateUsername;
        model.username = formData.username;  
        break;            
      case Stage.USER_CREATION:
      case Stage.LOGIN:
            model.username = formData.username;
            model.password = formData.password;
            model.dnSecurityEmail = formData.verifiedEmail;
            model.unverifiedEmail = formData.unverifiedEmail;
           break;      
      case Stage.OTP_METHOD:
      case Stage.OTP_RESEND:  
            model.otpChoice = formData.otpChoice;break;
      case Stage.OTP:
            model.password = formData.password; break;
      case Stage.PASSWORD:
      case Stage.CURRENT_PASSWORD:
            model.validatePassword = formData.validatePassword;
            model.password = formData.password; break;

    }
    return model;
  }

  private updateUserModel(currentStage:Stage|undefined,isValidatingOnly:boolean, error:any) {
    if (this.isRegistration) {
        switch (currentStage) {
          case Stage.LOGIN:
                this.userService.uniqueEmail("",new EventEmitter<any>()).subscribe((val) =>{
                  this.userService.emailConfirmed = {success: false} ;
                });
                break;
          case Stage.TOU: 
              this.userService.touAccepted = true; break;
          case Stage.USER_IDENTITY: 
              if (error) this.startJourney();
              this.userService.identityVerified = !(error || currentStage == this.nextStage); break;  
          case Stage.VERIFIED_EMAIL:
              this.userService.emailConfirmed = error ? {error: error} : {success: !(currentStage == this.nextStage)} ; break;
          case Stage.USERNAME:
              this.userService.username = error  ? {error: error} : {success: true} ; 
              break;  
          case Stage.PASSWORD:      
          case Stage.USER_CREATION:
              if (isValidatingOnly) {
                this.userService.validatedPassword = error ? {error: error} : {success: true}
              }else{
                this.userService.registered = error ? {error: error} :  {success: !(currentStage == this.nextStage)}; 
              }
              break;
        }
    }
  }

  protected checkForPolicyViolations(currentStage:Stage | undefined) {
    let policyViolations:ValidationErrors | null = null;
    if (this.isRegistration && currentStage && [Stage.USERNAME,Stage.PASSWORD,Stage.USER_CREATION].includes(currentStage)) {
            policyViolations = this.FRService.getCurrentPolicyViolations();
    }
    return  policyViolations;
  }

}
