import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';
import { EmailConfirmStatus, Stage, UserModel } from '../../shared/services/forge-rock.interface';

@Component({
  selector: 'journey-recover-username',
  templateUrl: './journey-recover-username.component.html'
})
export class JourneyRecoverUsernameComponent  extends AbstractJourneyComponent implements AfterViewInit {
 @Input() buttonText = 'Submit';
 @Input() otpButtonText : string = 'Submit';
 @Input() otpInstruction !: string;
 @Input() headerText !: string;

  autofocus = true;
  verifiedEmail !: string;
  editable = true;
  protected submitted = false;
  private sentVerificattionCode = false;

  @ViewChild('recoverUsernameForm', { static: true }) ngForm !: NgForm;
  @Output() onUsernameRequested = new EventEmitter<any>();

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  override getTreeName(): string {
    return 'recoverUsername';
  }

  onSubmit() {
    this.showErrors();
    if(this.ngForm.invalid) {
      return;
    }
    let formdata = this.ngForm.form.value;
    let userCredentials : UserModel = {} as UserModel;
    userCredentials.unverifiedEmail = formdata.userEmail;
    this.submitted = true

    this.contineJournery(userCredentials).then((nextStage)=>{
      this.submitted = false;
      if (this.sentVerificattionCode) {
        this.onUsernameRequested.emit({ unverifiedEmail: formdata.userEmail, loginRedirect: this.loginRedirect })
        this.sentVerificattionCode = false
      }
    },(err)=>{
      this.submitted = false;
     });
  }

  private contineJournery(credentials:any) {
    return this.nextStageInJourney(credentials).then((nextStage)=>{
      if (this.nextStage == Stage.EMAIL_SENT) {
        this.sentVerificattionCode = true;
        this.contineJournery(credentials);
      }
    });
  }


}
