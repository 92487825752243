import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'confirm-email',
  templateUrl: './confirm-email.component.html'
})
export class ConfirmEmailComponent implements OnInit {
  params :any = {}
  title = "";
  content = "";
  _update = false;
  @Input() headerText = "Success!"
  @Input() 
  set confirmEmailTitle(val:string) {
    this.title = val; 
  } 
  @Input() 
  set confirmEmailContent(val:string){
    this.content = val;
  }
  @Input()  
  set data(val:any) {
    this.params = val;
  }
  @Input()
  set updateTranslation(val:boolean) {
    if (val) {
      this.translateText();
    }
  }
  constructor(private translate: TranslateService,private _sanitizer:DomSanitizer ){}
  showSpinner = false;
  confirmEmailAddressValue !: string;
  confirmEmailTitleValue !: string;
  confirmEmailContentValue!: string;

  delay = (environment.common.fr.redirectDelay / 1000);

  ngOnInit(): void {
    this.translateText();
  }

  translateText() {
    this._update = true;
    this.translate.get('support.phone').subscribe(phone => {
      this.params.phone = phone;
      this.translate.get([this.title,this.content], this.params).subscribe((values)=>{
        this.confirmEmailTitleValue = values[this.title];
        this.confirmEmailContentValue = values[this.content];
        this._update = false;
      });
    })

      
  }
}
