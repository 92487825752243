<div dnErrorControl="username" #errorControl="dnErrorControl">
    <div class="mt-3 form-group">
        <label for="username">Username</label>
        <ng-container *ngIf="passwordOnly == true && inlineForgotUsername">
                    <ng-container *ngTemplateOutlet="forgotUsername; context: {$implicit: 'd-none d-md-flex justify-content-end mt-md-n4'}"></ng-container>
                </ng-container>
        <div class="input-group">
            <input type="text" class="form-control" [id]="name" [name]="name" placeholder="Enter username"
                [disabled]="!_editable"
                [(ngModel)]="username" [autofocus]="autofocus"
                [ngClass]="{'rounded-right-border': showUsernameGuidelines!=true}"
                required [minlength]="usernameMinLen" [maxlength]="usernameMaxLen"
                [validUserName]="passwordOnly == false"
                (blur)="onUsernameChange()"
                [uniqueValue]="_isUnique"
                aria-describedby="addon-wrapping-username"
                dnDisplayErrors  [errorTarget]="errTargetusr"
                [errorMessages]="{pattern: 'Please enter a valid username.',
                                 uniqueValue: 'Username already exists, please choose a different user name.',
                                'minlength': 'Username must be at least '+usernameMinLen+' characters long',
                                'maxLength': 'Username must be less than '+usernameMaxLen+' characters long',
                                 failedPolicy:'Please try another username.'}">

            <button *ngIf="showUsernameGuidelines==true" type="button" tabindex="-1" class="input-group-text  rounded-right-border" id="addon-wrapping-username" (click)="openUsernameGuidelinesModal()">
                <i title="Click here for username guidelines" class="fa-solid fa-circle-info text-primary"></i>
            </button>
            <div dnErrorTarget #errTargetusr="dnErrorTarget"></div>
        </div>
  </div>
</div>
<ng-template #forgotUsername let-classes>
    <div [ngClass]="classes">
        <a href class="link" (click)="onForgotUsername($event)">Forgot username</a>
    </div>
 </ng-template>
