<header class="pt-2">
    <div class="d-md-flex">
      <div class="col-xs-12 col-md-6 align-items-baseline">
        <img class="me-2 mb-1" src="assets/images/{{'header.logo' | translate}}" height="25px">
      </div>
      <div class="col-xs-12 col-md-6 d-md-flex justify-content-xs-start justify-content-md-end smaller-font">
          <ul class="nav nav-pills" >
            <li class="nav-item nav-link p-0 pe-3">
                <a href="tel:{{'support.phone' | translate}}" class="link">
                  <i class="fa-solid fa-phone" title="Phone"></i> {{'support.phone' | translate}}</a>
            </li>
            <li class="nav-item nav-link p-0">
              <a href="mailto:{{'support.email' | translate}}" class="link" ><i
                  class="fa-solid fa-envelope" title="Email"></i> {{'support.email' | translate}}</a>
            </li>
          </ul>
        </div>
    </div>
</header>

