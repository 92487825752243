
<div class="row align-items-center">
    <dn-progress-spinner *ngIf="showSpinner"></dn-progress-spinner>
    <div class="col-md-6 p-5 py-md-3" *ngIf="('login.instruction' | translate) != ''">
        <p [innerHTML]="'login.instruction' | translate"></p>
    </div>
    <div class="col-md-4 p-5 py-md-3" *ngIf="('login.instruction' | translate) == ''">

    </div>
    <div class="col-lg-4 col-md-5 col-sm-12 shadow-lg rounded pb-5 bg-white mt-5">
        <div class="row text-primary">
            <h5 class="mt-3 d-flex justify-content-center pt-5">
                <i class="fa-regular fa-user fa-2xl"></i><br>
            </h5>
            <h5 class="d-flex justify-content-center">
            <p>Sign In</p>
        </h5>
        </div>
        <div class="row">
            <journey-login buttonText="{{'login.button-text' | translate}}"
                        otpInstruction="otp.instruction"
            ></journey-login>
        </div>
        <div class="row smaller-font">
            <div class="mt-3 d-flex justify-content-center">
                <a href class="link" (click)="onForgotUsername($event)">Forgot username</a>
                <span class="mx-1">|</span>
                <a href class="link" (click)="onForgotPassword($event)">Forgot password</a>
            </div>
            <div class="mt-3 d-flex justify-content-center">
                <span>Don't have an account? <a [href]="createAccountUrl" class="link">Contact Us</a></span>
            </div>
        </div>
    </div>
</div>
