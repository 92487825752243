import { Component, OnInit } from '@angular/core';
import { FRUser } from '@forgerock/javascript-sdk';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'logout',
  templateUrl: './journey-logout.component.html'
})
export class JourneyLogoutComponent implements OnInit{
  showSpinner = false;
  constructor(protected FRService: ForgeRockService,
    protected router: Router, 
    protected route: ActivatedRoute,
  ){}
  ngOnInit(): void {
    this.showSpinner = true;
    let userType = this.route.parent?.snapshot.data['type'];
    this.FRService.loadConfig(userType).subscribe((config) => {    
      let homePage = config.redirects?.find(r => r.userType == userType && r.name == 'home')?.value;
      this.FRService.setConfig({redirectUri: homePage })   
      this.FRService.logout(homePage);
      this.showSpinner = false;
    });
  }

}
