import a from "./index.js";
class r extends a {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the callback's pauseBehavioralData value.
   * @returns {boolean}
   */
  getPauseBehavioralData() {
    return this.getOutputByName("pauseBehavioralData", !1);
  }
  /**
   * @method setData - Set the result of data collection
   * @param {string} data - Data from calling pingProtect.get()
   * @returns {void}
   */
  setData(t) {
    this.setInputValue(t, /signals/);
  }
  /**
   * @method setClientError - Set the client error message
   * @param {string} errorMessage - Error message
   * @returns {void}
   */
  setClientError(t) {
    this.setInputValue(t, /clientError/);
  }
}
export { r as default };