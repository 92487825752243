
<form id="recoverUsernameForm" name="recoverUsernameForm" #recoverUsernameForm="ngForm"
        (ngSubmit)="onSubmit()" dnErrorControl="errorControl" #errorControl="dnErrorControl" scrollToErrorForm novalidate>

    <dn-confirmation #dnConfirm></dn-confirmation>

    <div class="row" *ngIf="nextStage == Stage.VERIFIED_EMAIL">
        <p [innerHTML]="headerText"></p>
        <input-email name="userEmail" id="userEmail" [userEmail]="verifiedEmail"
        [editable]="editable" [autofocus]="autofocus"></input-email>
    </div>

    <div class="mt-4 d-flex justify-content-center">
        <button type="submit" class="btn btn-primary"  #subButton
            [disabled]="!recoverUsernameForm.form.valid || submitted || resending">{{nextStage == Stage.OTP ? otpButtonText:buttonText}} <i class="fa-solid fa-spinner" *ngIf="submitted"></i></button>
    </div>
</form>
