import { UserInfo } from './../../shared/services/forge-rock.interface';
import { Component, Input, OnInit, ViewChild, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { ControlContainer, FormControl, FormGroup, NgControl, NgForm, NgModel, ValidationErrors } from '@angular/forms';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../../shared/services/user.service';
import { AppFormsService } from '../app-forms.service';

@Component({
  selector: 'input-email',
  templateUrl: './input-email.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputEmailComponent extends AbstractErrorControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() autofocus = false;
  @Input() name = "userEmail";
  @ViewChild('emailCtrl', { static: true }) emailCtrl !: NgModel;
  @Input() isVerifyEmail = false;
  loginRedirect !: string;
  @Input() 
  set loginUrl(val:string) {
    this.loginRedirect = val;
  }
  private ngControl: NgControl | undefined
  protected _userEmail !: string;
  protected _editable : boolean = false;
  protected _isUnique : ValidationErrors | null = null;
  private _subscription !: Subscription | undefined;
  protected showConfirmEmail : boolean = false;
  protected confirmEmail !: string;
  protected showspinner = false;

  showLoginLink = false;
  @Input()
  public set editable(val:boolean) {
    this._editable = val;
  }

  @Input()
  public set userEmail(val:string) {
      this._userEmail = val;
  }

  @Input()
  public set isUnique(val: ValidationErrors | null) {
    this._isUnique = val;
  }

  @Output() onGoHome = new EventEmitter<any>();
  
  ngOnInit(): void {
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._subscription = this.emailCtrl.valueChanges?.subscribe((val) => {
      if(this._userEmail!=val){
        this.showConfirmEmail=true;
      }
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscription?.unsubscribe();
  }

  onLoginRedirect() {
    this.onGoHome.emit();
  }
}
