import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { UserService } from '../../shared/services/user.service';
import { AbstractControl, FormGroup, NgForm } from '@angular/forms';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { Stage, UserModel } from '../../shared/services/forge-rock.interface';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'journey-password-reset',
  templateUrl: './journey-password-reset.component.html'
})
export class JourneyPasswordResetComponent extends AbstractJourneyComponent implements AfterViewInit, OnDestroy{
  @Input() buttonText : string = 'Reset Password';
  @Input() otpButtonText : string = 'Continue';
  @Input() otpInstruction !: string;
  @Input() headerText !: string;
  @Input() resetWarning !: string;
  step = undefined;
  
  @ViewChild('passwordResetForm', { static: true }) ngForm !: NgForm;
  @Output() onPasswordReset = new EventEmitter<any>();

  private passwordControl !: AbstractControl | null;
  private subscriptions: Subscription[] = [];
  protected submitted = false;
  protected username = "";
  private userPassword = undefined;
  private passwordErrors: any;

  protected err !: any;
  protected errorCode !: any;
  protected unknownErrorMessage = "Unknown Error";
  protected errorMessage !: string;
  protected monitorChanges = false;
  private formSubscription !: Subscription;

  constructor(
    protected override router: Router, 
    protected override route:ActivatedRoute,
    protected override FRService: ForgeRockService,
    protected override userService: UserService,
)  {
    super(FRService,router,route,userService);
  }

  override getTreeName(): string {
    return 'resetPassword'
  }

  override ngAfterViewInit():void {
    super.ngAfterViewInit();
    let subscribe = this.OnJourneyStarted.subscribe((val)=>{
      if (val) {
          this.handleLoginFailures();
          subscribe.unsubscribe();
      }
     });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s)=>s?.unsubscribe);
  }

  protected setOtpChoice(choiceResp:any) {
    this.otpChoice = choiceResp;
    this.ngForm.ngSubmit.emit();
  }


  onSubmit() {
    this.showErrors();
    if(this.ngForm.invalid) {
      return;
    }
    let formdata = this.ngForm.form.value;
    this.username = formdata.username;
    this.userPassword = formdata.passwords?.password;
    let userCredentials : UserModel = {} as UserModel;
    userCredentials.username = formdata.username;
    userCredentials.password = formdata.passwords?.password || formdata.onetimePassword;
    userCredentials.otpChoice = this.otpChoice;
    userCredentials.validatePassword = true;
    this.handleResendCode('reject').then((resp) => {
      this.submitted = true
      this.contineJournery(userCredentials).then((nextStage)=>{
        this.submitted = false;
        if (this.nextStage == Stage.PASSWORD && !this.passwordControl) {
          this.monitorPasswordControl();
        }
        if (this.nextStage == Stage.LOGIN) {
            this.onPasswordReset.emit({username:this.username, loginRedirect: this.loginRedirect})
        }
      },(err)=>{
        this.submitted = false;
      });
    });
  }

  private monitorPasswordControl() {
    let controls = this.ngForm.controls["passwords"] as FormGroup;
    if (controls) {
      this.passwordControl = controls.get('newPassword');
      if (this.passwordControl) {
          this.subscriptions.push(this.passwordControl.valueChanges?.subscribe((val)=>{
            if (val!=this.userPassword) {
              this.passwordErrors = null;
            }
          }));
      }
    }
  }

  private contineJournery(credentials:UserModel):Promise<any> {
     let currentStage = this.nextStage;
     return this.nextStageInJourney(credentials).then((nextStage)=>{
      let violations = this.checkForPolicyViolations(currentStage);
      this.passwordControl?.setErrors(violations);
      this.passwordErrors = violations;
      if (violations == null && credentials.validatePassword && currentStage == Stage.PASSWORD) {
        credentials.validatePassword = false;
        return this.contineJournery(credentials);
      }
      return nextStage;
    });
  }

  protected handleLoginFailures() {
    this.subscriptions.push(this.FRService.OnLoginFailure.subscribe((err:any)=>{
      this.errorCode = err && err.code ? err.code : undefined;
      this.errorMessage = this.errorCode && err.message ? err.message : environment.common.errorMessages.loginFailureUnknown; 
      if (this.errorCode) {
          this.monitorChanges = true;
          this.formSubscription = this.ngForm.form.valueChanges.subscribe((val:any)=>{
            if (val && !this.monitorChanges) {
              this.formSubscription.unsubscribe();
              this.errorCode = undefined;
            }
            this.monitorChanges = false;
          });
      }
    }));
  }
}
