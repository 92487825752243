import { getRealmUrlPath as c } from "./realm.js";
function r(t) {
  const o = t.protocol === "http:" && ["", "80"].indexOf(t.port) === -1 || t.protocol === "https:" && ["", "443"].indexOf(t.port) === -1 ? `:${t.port}` : "";
  return `${t.protocol}//${t.hostname}${o}`;
}
function h(t, e, o) {
  const n = c(e),
    s = {
      authenticate: `json/${n}/authenticate`,
      authorize: `oauth2/${n}/authorize`,
      accessToken: `oauth2/${n}/access_token`,
      endSession: `oauth2/${n}/connect/endSession`,
      userInfo: `oauth2/${n}/userinfo`,
      revoke: `oauth2/${n}/token/revoke`,
      sessions: `json/${n}/sessions/`
    };
  return o && o[t] ? o[t] : s[t];
}
function f(t, e) {
  const o = new URL(t);
  if (e.startsWith("/")) return `${r(o)}${e}`;
  const n = o.pathname.split("/"),
    s = e.split("/").filter(i => !!i),
    a = [...n.slice(0, -1), ...s].join("/");
  return `${r(o)}${a}`;
}
function p(t) {
  const e = new URL(t),
    o = {};
  return e.searchParams.forEach((n, s) => o[s] = n), o;
}
function $(t) {
  const e = [];
  for (const o in t) t[o] && e.push(o + "=" + encodeURIComponent(t[o]));
  return e.join("&");
}
export { r as getBaseUrl, h as getEndpointPath, p as parseQuery, f as resolve, $ as stringify };