class p {
  /**
   * @method reduceToObject - goes one to two levels into source to collect attribute
   * @param props - array of strings; can use dot notation for two level lookup
   * @param src - source of attributes to check
   */
  // eslint-disable-next-line
  reduceToObject(n, t) {
    return n.reduce((e, o) => {
      if (o.includes(".")) {
        const l = o.split("."),
          d = l[0],
          i = l[1],
          s = t[d] && t[d][i];
        e[i] = s ?? "";
      } else e[o] = t[o] != null ? t[o] : null;
      return e;
    }, {});
  }
  /**
   * @method reduceToString - goes one level into source to collect attribute
   * @param props - array of strings
   * @param src - source of attributes to check
   */
  // eslint-disable-next-line
  reduceToString(n, t) {
    return n.reduce((e, o) => (e = `${e}${t[o].filename};`, e), "");
  }
}
export { p as default };