import { fontNames as s, devicePlatforms as c, displayProps as d, browserProps as l, hardwareProps as f, platformProps as g, configurableCategories as u, delay as m } from "./defaults.js";
import p from "./collector.js";
import { FRLogger as n } from "../util/logger.js";
import w from "../config/index.js";
class O extends p {
  constructor(t) {
    super(), this.config = {
      fontNames: s,
      devicePlatforms: c,
      displayProps: d,
      browserProps: l,
      hardwareProps: f,
      platformProps: g
    }, t && Object.keys(t).forEach(e => {
      if (!u.includes(e)) throw new Error("Device profile configuration category does not exist.");
      this.config[e] = t[e];
    });
  }
  getBrowserMeta() {
    return typeof navigator > "u" ? (n.warn("Cannot collect browser metadata. navigator is not defined."), {}) : this.reduceToObject(this.config.browserProps, navigator);
  }
  getBrowserPluginsNames() {
    return typeof navigator < "u" && navigator.plugins ? this.reduceToString(Object.keys(navigator.plugins), navigator.plugins) : (n.warn("Cannot collect browser plugin information. navigator.plugins is not defined."), "");
  }
  getDeviceName() {
    if (typeof navigator > "u") return n.warn("Cannot collect device name. navigator is not defined."), "";
    const t = navigator.userAgent,
      e = navigator.platform;
    switch (!0) {
      case this.config.devicePlatforms.mac.includes(e):
        return "Mac (Browser)";
      case this.config.devicePlatforms.ios.includes(e):
        return `${e} (Browser)`;
      case this.config.devicePlatforms.windows.includes(e):
        return "Windows (Browser)";
      case /Android/.test(e) || /Android/.test(t):
        return "Android (Browser)";
      case /CrOS/.test(t) || /Chromebook/.test(t):
        return "Chrome OS (Browser)";
      case /Linux/.test(e):
        return "Linux (Browser)";
      default:
        return `${e || "Unknown"} (Browser)`;
    }
  }
  getDisplayMeta() {
    return typeof screen > "u" ? (n.warn("Cannot collect screen information. screen is not defined."), {}) : this.reduceToObject(this.config.displayProps, screen);
  }
  getHardwareMeta() {
    return typeof navigator > "u" ? (n.warn("Cannot collect OS metadata. Navigator is not defined."), {}) : this.reduceToObject(this.config.hardwareProps, navigator);
  }
  getIdentifier() {
    const t = `${w.get().prefix}-DeviceID`;
    if (!(typeof globalThis.crypto < "u" && globalThis.crypto.getRandomValues)) return n.warn("Cannot generate profile ID. Crypto and/or getRandomValues is not supported."), "";
    if (!localStorage) return n.warn("Cannot store profile ID. localStorage is not supported."), "";
    let e = localStorage.getItem(t);
    return e || (e = globalThis.crypto.getRandomValues(new Uint32Array(3)).join("-"), localStorage.setItem(t, e)), e;
  }
  getInstalledFonts() {
    if (typeof document > "u") return n.warn("Cannot collect font data. Global document object is undefined."), "";
    const t = document.createElement("canvas");
    if (!t) return n.warn("Cannot collect font data. Browser does not support canvas element"), "";
    const e = t.getContext && t.getContext("2d");
    if (!e) return n.warn("Cannot collect font data. Browser does not support 2d canvas context"), "";
    const o = "abcdefghi0123456789";
    e.font = "72px Comic Sans";
    const i = e.measureText(o).width;
    return this.config.fontNames.reduce((r, a) => (e.font = `72px ${a}, Comic Sans`, e.measureText(o).width !== i && (r = `${r}${a};`), r), "");
  }
  async getLocationCoordinates() {
    return typeof navigator < "u" && navigator.geolocation ? new Promise(async t => {
      navigator.geolocation.getCurrentPosition(e => t({
        latitude: e.coords.latitude,
        longitude: e.coords.longitude
      }), e => {
        n.warn("Cannot collect geolocation information. " + e.code + ": " + e.message), t({});
      }, {
        enableHighAccuracy: !0,
        timeout: m,
        maximumAge: 0
      });
    }) : (n.warn("Cannot collect geolocation information. navigator.geolocation is not defined."), Promise.resolve({}));
  }
  getOSMeta() {
    return typeof navigator > "u" ? (n.warn("Cannot collect OS metadata. navigator is not defined."), {}) : this.reduceToObject(this.config.platformProps, navigator);
  }
  async getProfile({
    location: t,
    metadata: e
  }) {
    const o = {
      identifier: this.getIdentifier()
    };
    return e && (o.metadata = {
      hardware: {
        ...this.getHardwareMeta(),
        display: this.getDisplayMeta()
      },
      browser: {
        ...this.getBrowserMeta(),
        plugins: this.getBrowserPluginsNames()
      },
      platform: {
        ...this.getOSMeta(),
        deviceName: this.getDeviceName(),
        fonts: this.getInstalledFonts(),
        timezone: this.getTimezoneOffset()
      }
    }), t && (o.location = await this.getLocationCoordinates()), o;
  }
  getTimezoneOffset() {
    try {
      return ( /* @__PURE__ */new Date()).getTimezoneOffset();
    } catch {
      return n.warn("Cannot collect timezone information. getTimezoneOffset is not defined."), null;
    }
  }
}
export { O as default };