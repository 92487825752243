function f(t, {
  type: a,
  payload: o
}) {
  const i = Object.freeze({
    type: a,
    payload: o
  });
  return e => {
    if (!Array.isArray(e)) return t;
    const c = e.map(r => r);
    function n() {
      const r = c.shift();
      return r && r(t, i, n), t;
    }
    return n();
  };
}
export { f as default };