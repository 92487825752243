import v from "../config/index.js";
import w from "../auth/index.js";
import { CallbackType as x } from "../auth/enums.js";
import I from "./fr-login-failure.js";
import k from "./fr-login-success.js";
import R from "./fr-step.js";
class f {
  static get previousStepKey() {
    return `${v.get().prefix}-PreviousStep`;
  }
  /**
   * Requests the next step in the authentication tree.
   *
   * Call `FRAuth.next()` recursively.  At each step, check for session token or error, otherwise
   * populate the step's callbacks and call `next()` again.
   *
   * Example:
   *
   * ```js
   * async function nextStep(previousStep) {
   *   const thisStep = await FRAuth.next(previousStep);
   *
   *   switch (thisStep.type) {
   *     case StepType.LoginSuccess:
   *       const token = thisStep.getSessionToken();
   *       break;
   *     case StepType.LoginFailure:
   *       const detail = thisStep.getDetail();
   *       break;
   *     case StepType.Step:
   *       // Populate `thisStep` callbacks here, and then continue
   *       thisStep.setInputValue('foo');
   *       nextStep(thisStep);
   *       break;
   *   }
   * }
   * ```
   *
   * @param previousStep The previous step with its callback values populated
   * @param options Configuration overrides
   * @return The next step in the authentication tree
   */
  static async next(t, r) {
    const e = await w.next(t ? t.payload : void 0, r);
    if (e.authId) {
      const a = r ? r.callbackFactory : void 0;
      return new R(e, a);
    }
    return !e.authId && e.ok ? new k(e) : new I(e);
  }
  /**
   * Redirects to the URL identified in the RedirectCallback and saves the full
   * step information to localStorage for retrieval when user returns from login.
   *
   * Example:
   * ```js
   * forgerock.FRAuth.redirect(step);
   * ```
   */
  static redirect(t) {
    const e = t.getCallbackOfType(x.RedirectCallback).getRedirectUrl();
    localStorage.setItem(this.previousStepKey, JSON.stringify(t)), location.assign(e);
  }
  /**
   * Resumes a tree after returning from an external client or provider.
   * Requires the full URL of the current window. It will parse URL for
   * key-value pairs as well as, if required, retrieves previous step.
   *
   * Example;
   * ```js
   * forgerock.FRAuth.resume(window.location.href)
   * ```
   */
  static async resume(t, r) {
    const e = new URL(t),
      a = e.searchParams.get("code"),
      n = e.searchParams.get("error"),
      i = e.searchParams.get("errorCode"),
      m = e.searchParams.get("errorMessage"),
      s = e.searchParams.get("form_post_entry"),
      l = e.searchParams.get("nonce"),
      u = e.searchParams.get("RelayState"),
      o = e.searchParams.get("responsekey"),
      d = e.searchParams.get("scope"),
      c = e.searchParams.get("state"),
      p = e.searchParams.get("suspendedId"),
      g = e.searchParams.get("authIndexValue") ?? void 0;
    let h;
    function S() {
      return a && c || s || o;
    }
    if (S()) {
      const y = localStorage.getItem(this.previousStepKey);
      if (!y) throw new Error("Error: could not retrieve original redirect information.");
      try {
        h = JSON.parse(y);
      } catch {
        throw new Error("Error: could not parse redirect params or step information");
      }
      localStorage.removeItem(this.previousStepKey);
    }
    const P = {
      ...r,
      query: {
        // Conditionally spread properties into object. Don't spread props with undefined/null.
        ...(a && {
          code: a
        }),
        ...(n && {
          error: n
        }),
        ...(i && {
          errorCode: i
        }),
        ...(m && {
          errorMessage: m
        }),
        ...(s && {
          form_post_entry: s
        }),
        ...(l && {
          nonce: l
        }),
        ...(u && {
          RelayState: u
        }),
        ...(o && {
          responsekey: o
        }),
        ...(d && {
          scope: d
        }),
        ...(c && {
          state: c
        }),
        ...(p && {
          suspendedId: p
        }),
        // Allow developer to add or override params with their own.
        ...(r && r.query)
      },
      ...((r?.tree ?? g) && {
        tree: r?.tree ?? g
      })
    };
    return await this.next(h, P);
  }
  /**
   * Requests the first step in the authentication tree.
   * This is essentially an alias to calling FRAuth.next without a previous step.
   *
   * @param options Configuration overrides
   * @return The next step in the authentication tree
   */
  static async start(t) {
    return await f.next(void 0, t);
  }
}
export { f as default };