import a from "./index.js";
class s extends a {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the callback's data.
   */
  getData() {
    return this.getOutputByName("data", {});
  }
}
export { s as default };