import { plural as r } from "../util/strings.js";
import { PolicyKey as e } from "./enums.js";
import { getProp as i } from "./helpers.js";
const u = {
  [e.CannotContainCharacters]: (a, n) => {
    const t = i(n, "forbiddenChars", "");
    return `${a} must not contain following characters: "${t}"`;
  },
  [e.CannotContainDuplicates]: (a, n) => {
    const t = i(n, "duplicateValue", "");
    return `${a}  must not contain duplicates: "${t}"`;
  },
  [e.CannotContainOthers]: (a, n) => {
    const t = i(n, "disallowedFields", "");
    return `${a} must not contain: "${t}"`;
  },
  [e.LeastCapitalLetters]: (a, n) => {
    const t = i(n, "numCaps", 0);
    return `${a} must contain at least ${t} capital ${r(t, "letter")}`;
  },
  [e.LeastNumbers]: (a, n) => {
    const t = i(n, "numNums", 0);
    return `${a} must contain at least ${t} numeric ${r(t, "value")}`;
  },
  [e.MatchRegexp]: a => `${a} has failed the "MATCH_REGEXP" policy`,
  [e.MaximumLength]: (a, n) => {
    const t = i(n, "maxLength", 0);
    return `${a} must be at most ${t} ${r(t, "character")}`;
  },
  [e.MaximumNumber]: a => `${a} has failed the "MAXIMUM_NUMBER_VALUE" policy`,
  [e.MinimumLength]: (a, n) => {
    const t = i(n, "minLength", 0);
    return `${a} must be at least ${t} ${r(t, "character")}`;
  },
  [e.MinimumNumber]: a => `${a} has failed the "MINIMUM_NUMBER_VALUE" policy`,
  [e.Required]: a => `${a} is required`,
  [e.Unique]: a => `${a} must be unique`,
  [e.UnknownPolicy]: (a, n) => {
    const t = i(n, "policyRequirement", "Unknown");
    return `${a}: Unknown policy requirement "${t}"`;
  },
  [e.ValidArrayItems]: a => `${a} has failed the "VALID_ARRAY_ITEMS" policy`,
  [e.ValidDate]: a => `${a} has an invalid date`,
  [e.ValidEmailAddress]: a => `${a} has an invalid email address`,
  [e.ValidNameFormat]: a => `${a} has an invalid name format`,
  [e.ValidNumber]: a => `${a} has an invalid number`,
  [e.ValidPhoneFormat]: a => `${a} has an invalid phone number`,
  [e.ValidQueryFilter]: a => `${a} has failed the "VALID_QUERY_FILTER" policy`,
  [e.ValidType]: a => `${a} has failed the "VALID_TYPE" policy`
};
export { u as default };