import a from "./index.js";
class i extends a {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    super(e), this.payload = e;
  }
  /**
   * Gets the callback's failed policies.
   */
  getFailedPolicies() {
    const e = this.getOutputByName("failedPolicies", []);
    try {
      return e.map(t => JSON.parse(t));
    } catch {
      throw new Error('Unable to parse "failed policies" from the ForgeRock server. The JSON within `ValidatedCreatePasswordCallback` was either malformed or missing.');
    }
  }
  /**
   * Gets the callback's applicable policies.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPolicies() {
    return this.getOutputByName("policies", {});
  }
  /**
   * Gets the callback's prompt.
   */
  getPrompt() {
    return this.getOutputByName("prompt", "");
  }
  /**
   * Gets whether the password is required.
   */
  isRequired() {
    return this.getOutputByName("required", !1);
  }
  /**
   * Sets the callback's password.
   */
  setPassword(e) {
    this.setInputValue(e);
  }
  /**
   * Set if validating value only.
   */
  setValidateOnly(e) {
    this.setInputValue(e, /validateOnly/);
  }
}
export { i as default };