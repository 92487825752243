<div class="col-lg-11 col-md-11 col-sm-12 shadow-lg rounded mt-5 ms-md-5 mb-5">
    <dn-progress-spinner *ngIf="initializing"></dn-progress-spinner>
    <div class="" *ngIf="!initializing">
        <confirm-email 
            [data]="translationData"
            [confirmEmailContent]="contentRef"
            [confirmEmailTitle]="titleRef"
            [updateTranslation]="!initializing"
            > 
        </confirm-email>
    </div>
</div>