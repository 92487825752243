import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';
import { AbstractControl, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { DnValidators } from './dn-validators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[monthDay]'
})
export class MonthDayDirective implements Validator, AfterViewInit{
  @Input() errCtrl !: NgModel;
  
  constructor(private ngControl: NgControl) {  }

  private _subscription !: Subscription | undefined;

  ngAfterViewInit(): void {
    this.ngControl.control?.setValidators((c: AbstractControl<any, any>) =>this.validate(c));
    this._subscription = this.errCtrl.valueChanges?.subscribe((val) => {  
      this.ngControl.control?.updateValueAndValidity({onlySelf:true});
    });
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    return DnValidators.validateMonthDate(this.errCtrl?.value)(control);
  }


  @HostListener('window:beforeunload')  
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();      
  }

}
