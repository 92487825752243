<div class="modal-header">
  <h5 class="modal-title" [innerHTML]="'username-guidelines.heading' | translate"></h5>
</div>
<div class="modal-body">
  <p [innerHTML]="'username-guidelines.content' | translate :{usernameMinLen: (usernameMinLen), usernameMaxLen: (usernameMaxLen)}"></p>

</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
</div>
