import { AfterViewInit, Directive, HostListener , Input} from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { DnValidators } from './dn-validators';
import { AT_LEAST_ONE_LOWERCASE_CHARACTER_PATTERN } from './dn-validators';
import { AT_LEAST_ONE_UPPERCASE_CHARACTER_PATTERN } from './dn-validators';
import { AT_LEAST_ONE_DIGIT_PATTERN } from './dn-validators';
import { AT_LEAST_ONE_SPECIAL_CHARACTER_PATTERN } from './dn-validators';
import { REPEATING_LETTERS_NUMBERS } from './dn-validators';
import { specialWords } from './dn-validators';
import { months } from './dn-validators';
import { dayOfTheWeek } from './dn-validators';
import { Subscription } from 'rxjs/internal/Subscription';

export enum PasswordErrors {
  minlength = 'minlength',
  maxlength = 'maxlength',
  atLeastOneCharSet = 'atLeastOneCharSet',
  atLeastOneNumSpecChr = 'atLeastOneNumSpecChr',
  moreThan5NumbersNextToEachOther = 'moreThan5NumbersNextToEachOther' ,
  consecutiveCharsOrNums = 'consecutiveCharsOrNums',
  containsSpecialWords = 'containsSpecialWords',
  containsMonths = 'containsMonths',
  containsDayOfTheWeek = 'containsDayOfTheWeek',
  containsRepeatingCharNum = 'containsRepeatingCharNum',
  containsPII = 'containsPII'
}

@Directive({
  selector: '[validPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidPasswordDirective,
      multi: true,
    }
  ]
})
export class ValidPasswordDirective implements Validator  {
  @Input() firstName !: any;
  @Input() lastName !: any;
  private _username !: any;
  @Input()
  set username(val:any) {
    this._username = val;
    if (this._onChange) {
      this._onChange()
    }
  }
  @Input('validPassword') isEnabled : boolean = true;

  private _subscription !: Subscription | undefined;
  private _onChange?: () => void


  validate(control: AbstractControl<any, any>): ValidationErrors | null {

    if (!this.isEnabled) return null;

    let atLeastOneCharSet = DnValidators.shouldMatchAtLeastOne([AT_LEAST_ONE_LOWERCASE_CHARACTER_PATTERN, PasswordErrors.atLeastOneCharSet])(control);
    if (atLeastOneCharSet == null) {
        atLeastOneCharSet =  DnValidators.shouldMatchAtLeastOne([AT_LEAST_ONE_UPPERCASE_CHARACTER_PATTERN, PasswordErrors.atLeastOneCharSet])(control);
    }
    let atLeastOneNumSpecChr =  DnValidators.shouldMatchAtLeastOne([AT_LEAST_ONE_DIGIT_PATTERN, PasswordErrors.atLeastOneNumSpecChr])(control);
    if (atLeastOneNumSpecChr == null) {
      atLeastOneNumSpecChr = DnValidators.shouldMatchAtLeastOne([AT_LEAST_ONE_SPECIAL_CHARACTER_PATTERN, PasswordErrors.atLeastOneNumSpecChr])(control);
    }
    const moreThan5NumbersNextToEachOther = DnValidators.moreThan5NumbersNextToEachOther()(control);
    const consecutiveCharsOrNums = DnValidators.consecutiveCharsOrNums()(control);
    const containsSpecialWords = DnValidators.shouldNotContainWord([specialWords,PasswordErrors.containsSpecialWords])(control);
    const containsMonths =       DnValidators.shouldNotContainWord([months,PasswordErrors.containsSpecialWords])(control);
    const containsDayOfTheWeek = DnValidators.shouldNotContainWord([dayOfTheWeek,PasswordErrors.containsSpecialWords])(control);
    const containsPII =    DnValidators.shouldNotContainWord([this.firstName?[this.firstName]:null,PasswordErrors.containsPII])(control) ||
                           DnValidators.shouldNotContainWord([this.lastName?[this.lastName]:null,PasswordErrors.containsPII])(control) ||
                           DnValidators.shouldNotContainWord([this._username?[this._username]:null,PasswordErrors.containsPII])(control);
    const containsRepeatingCharNum = DnValidators.shouldNotMatch([REPEATING_LETTERS_NUMBERS, PasswordErrors.containsRepeatingCharNum])(control);

    return {
      ...atLeastOneCharSet ,
      ...atLeastOneNumSpecChr ,
      ...moreThan5NumbersNextToEachOther ,
      ...consecutiveCharsOrNums ,
      ...containsSpecialWords ,
      ...containsMonths ,
      ...containsDayOfTheWeek,
      ...containsRepeatingCharNum,
      ...containsPII
    }
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onChange = fn
  }


  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();
  }
}
