import { commonEnvironment } from "./environment.common";

export const environment = {
    common: commonEnvironment,
    debug: false,
    labels: {
        "common": "labels.yml",
        "providerdna": "label-provider.yml"
    }
};
