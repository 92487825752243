import { AfterViewInit, OnInit } from "@angular/core";
import { AfterContentInit, Host, HostListener, Optional} from "@angular/core";
import { Directive, ComponentRef, ViewContainerRef, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { AbstractControl, NgForm } from "@angular/forms";
import { DnErrorMessageService } from "../dn-error-message.service";
import { DnErrorTemplateComponent } from "../templates/dn-error-template.component";

@Directive({
    selector: '[dnErrorControl]',
    exportAs: 'dnErrorControl'
})
export class DnErrorControlDirective implements OnInit, OnDestroy, AfterViewInit {
    @Input('dnErrorControl') errorControl !: string ;
    @Output() onDisplayErrors: EventEmitter<any> = new EventEmitter<any>(true);

    private _dnErrorTargetControls : AbstractControl[] = [];
    private _cmpRef !: ComponentRef<DnErrorTemplateComponent>;
    private _tokens: string[] = [];
    public displayErrors: boolean = false;
    private _trackRadioControls = new Map<string,string>();

    constructor(private viewContainerRef: ViewContainerRef,
                @Host() @Optional() private _form : NgForm,
                private dnErrorMessageService: DnErrorMessageService) {

    }

    ngOnInit(): void {
        if (!this._cmpRef && !(this.dnErrorMessageService.fieldTemplate() || this.dnErrorMessageService.formTemplate())) {
            this._cmpRef = this.viewContainerRef.createComponent(DnErrorTemplateComponent);
            this._cmpRef.instance._id  = "_errTemplate" + this.errorControl;
        }
    }

    ngAfterViewInit(): void {
        if (!this.errorControl) {
            this.errorControl = '_errorControl' + new Date().getUTCMilliseconds()
        }
    }

    @HostListener('window:beforeunload')
    ngOnDestroy(): void {
        this._cmpRef?.destroy();
    }

    public get parent() {
        return this._form;
    }

    public get tokens(): string[] | undefined {
        return this.displayErrors ? this._tokens : undefined;
    }

    public showErrors(token ?: string[] | string | undefined | null) {
        this.displayErrors = true;
        if (Array.isArray(token)) {
            this.setTokens(token);
        }else if (token) {
            this.setTokens([token]);
        }else {
            this.setTokens([]);
        }
    }

    public hideErrors() {
        this.displayErrors = false;
        this.setTokens([]);
    }

    public get dnErrorTargetControls() {
        return this._dnErrorTargetControls;
    }

    public registerErrorTarget(_formControl?: AbstractControl): void {
        if (_formControl &&  this._dnErrorTargetControls.indexOf(_formControl) < 0) {            
            this._dnErrorTargetControls.push(_formControl)
        }
    }

    public unregisterErrorTarget(_formControl?: AbstractControl | null):void {
        if (_formControl) {
            this._dnErrorTargetControls.splice(this._dnErrorTargetControls.indexOf(_formControl))
        }
    }

    public shouldDisplayError(errorKey?:string): boolean {
        return errorKey && this._tokens.length > 0 ? (this.displayErrors && this._tokens.indexOf(errorKey) > -1) : this.displayErrors;
    }

    private setTokens(tokens: string[]) {
        this._tokens = tokens;
        this.onDisplayErrors.emit(this.shouldDisplayError());
    }

    public findElementsBySelector(selector:string):NodeList {
        return this.viewContainerRef.element.nativeElement.querySelectorAll(selector);
    }

    public trackRadioControls(radioName:string, errorBlockId: string) {
        this._trackRadioControls.set(radioName,errorBlockId);
    }

    public getRadioErrorBlockId(radioName:string) {
        return this._trackRadioControls.get(radioName);
    }
}
