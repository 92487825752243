const o = ["userAgent", "appName", "appCodeName", "appVersion", "appMinorVersion", "buildID", "product", "productSub", "vendor", "vendorSub", "browserLanguage"],
  a = ["fontNames", "displayProps", "browserProps", "hardwareProps", "platformProps"],
  e = 3e4,
  r = {
    mac: ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windows: ["Win32", "Win64", "Windows", "WinCE"],
    ios: ["iPhone", "iPad", "iPod"]
  },
  n = ["width", "height", "pixelDepth", "orientation.angle"],
  s = ["cursive", "monospace", "serif", "sans-serif", "fantasy", "Arial", "Arial Black", "Arial Narrow", "Arial Rounded MT Bold", "Bookman Old Style", "Bradley Hand ITC", "Century", "Century Gothic", "Comic Sans MS", "Courier", "Courier New", "Georgia", "Gentium", "Impact", "King", "Lucida Console", "Lalit", "Modena", "Monotype Corsiva", "Papyrus", "Tahoma", "TeX", "Times", "Times New Roman", "Trebuchet MS", "Verdana", "Verona"],
  i = ["cpuClass", "deviceMemory", "hardwareConcurrency", "maxTouchPoints", "oscpu"],
  t = ["language", "platform", "userLanguage", "systemLanguage"];
export { o as browserProps, a as configurableCategories, e as delay, r as devicePlatforms, n as displayProps, s as fontNames, i as hardwareProps, t as platformProps };