<div dnErrorControl="onetimePassword" #errorControl="dnErrorControl">
        <div class="mt-3 form-group">
            <p [innerHTML]="otpText"></p>
             
            <label for="onetimePassword">One-time Code</label> 
            <div class="col-sm-10">
                <input type="text" class="form-control" [id]="name" [name]="name" placeholder="Enter your code"
                    [(ngModel)]="onetimePassword"  #otpCtrl="ngModel" [autofocus]="autofocus"
                    required  [disabled]="disabled"
                    dnDisplayErrors >                    
                <small *ngIf="stage == Stage.OTP_RESEND" id="codeHelp" class="form-text text-muted">Didn't receive the code? Click <a href id="btn-resend" name="btn-resend" title="Resend Code" (click)="resendCode($event)">here</a> to resend.</small>
            </div> 
        </div>
</div>

