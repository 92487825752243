import { ViewChild, TemplateRef, Input, Component, AfterViewInit} from "@angular/core";
import { DnErrorMessageService } from "../dn-error-message.service";

@Component({
    selector: "dnErrorTemplate",
    styles: ['.dn-error-display { display: block; }'],
    template: `
        <ng-template let-data="errorContext" #dnFieldTemplate> 
           <div id="{{data.id}}" [ngClass]="data.class">
              <div *ngFor="let msg of data.messages">{{msg}}</div>
           </div>
        </ng-template>
        <ng-template let-data="errorContext" #dnFormTemplate>
            <div [ngClass]="data.class">
                <label>Please correct the following errors:</label>
                <ul>
                    <li *ngFor="let msg of data.messages">{{msg}}</li>
                </ul>
            </div>
        </ng-template>    
    `
})
export class DnErrorTemplateComponent implements AfterViewInit {  
    @Input('id') _id !: String ;      
    @ViewChild('dnFieldTemplate',{read: TemplateRef}) dnFieldTemplate !: TemplateRef<any>;
    @ViewChild('dnFormTemplate',{read: TemplateRef}) dnFormTemplate !: TemplateRef<any>;    
    
    constructor(private dnErrorMessageService: DnErrorMessageService) {}
    ngAfterViewInit(): void {
        if (!this.dnErrorMessageService.fieldTemplate()) {
            this.dnErrorMessageService.fieldTemplate(this.dnFieldTemplate);
        }
        if (!this.dnErrorMessageService.formTemplate()) {
            this.dnErrorMessageService.formTemplate(this.dnFormTemplate);
        }  
    }
}