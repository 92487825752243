import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractJourneyComponent } from '../../abstract-journey/abstract-journey.component';
import { ForgeRockService } from '../../../shared/services/forge-rock.service';
import { UserService } from '../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'provider-password-reset',
  templateUrl: './provider-password-reset.component.html'
})
export class ProviderPasswordResetComponent {
  
  action !: string | null;
  headerText : string = 'Forgot your password?';
  icon : string = 'fa-solid fa-key fa-2xl';
  resetting = true;
  translationData:any = {}

  onStepComplete(data:any) {
    //let userType = this.route.parent?.snapshot.data['type'];
    this.translationData = {emailAddr: undefined, 
                            username: data.username, 
                            loginRedirect: data.loginRedirect,
                            hideEmail: 'd-none'} 

    this.resetting = false;  
  }
}
