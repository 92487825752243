import e from "./index.js";
class a extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the terms and conditions content.
   */
  getTerms() {
    return this.getOutputByName("terms", "");
  }
  /**
   * Gets the version of the terms and conditions.
   */
  getVersion() {
    return this.getOutputByName("version", "");
  }
  /**
   * Gets the date of the terms and conditions.
   */
  getCreateDate() {
    const t = this.getOutputByName("createDate", "");
    return new Date(t);
  }
  /**
   * Sets the callback's acceptance.
   */
  setAccepted(t = !0) {
    this.setInputValue(t);
  }
}
export { a as default };