import e from "./index.js";
class r extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the callback's failed policies.
   */
  getFailedPolicies() {
    return this.getOutputByName("failedPolicies", []);
  }
  /**
   * Gets the callback's applicable policies.
   */
  getPolicies() {
    return this.getOutputByName("policies", []);
  }
  /**
   * Gets the callback's prompt.
   */
  getPrompt() {
    return this.getOutputByName("prompt", "");
  }
  /**
   * Sets the password.
   */
  setPassword(t) {
    this.setInputValue(t);
  }
}
export { r as default };