import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../shared/services/user.service';
import { EmailConfirmStatus, NavStep, Stage } from '../../../shared/services/forge-rock.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgeRockService } from '../../../shared/services/forge-rock.service';
import { AbstractJourneyComponent } from '../../abstract-journey/abstract-journey.component';
import { TranslateService } from '@ngx-translate/core';
 

@Component({
    selector: 'provider-registration',
    templateUrl: './provider-registration.component.html',
    styleUrls: ['./provider-registration.component.css']
})
export class ProviderRegistrationComponent extends AbstractJourneyComponent implements OnInit {

  steps :NavStep[] = [{'stage':Stage.TOU,'navText':'Accept Terms Of User'},
                      {'stage':Stage.USER_IDENTITY,'navText':'Verify Identity'},
                      {'stage':Stage.VERIFIED_EMAIL,'navText':'Change Email'},
                      {'stage':Stage.USER_CREATION,'navText':'Complete Registration'}];
  activeStep !: NavStep | undefined;


  active !: string | undefined;
  customClasses:string[] = [];
  focusemail = false;
  focusIdentity = false;
  registered = false; 
  sentVerificattionCode = false;
  translationData:any = {}  
  showSpinner = false;
  journeyStarted = false;
  @ViewChild('nav', { static: true }) nav !: NgbNav;

  constructor(
    protected override router: Router, 
    protected override route:ActivatedRoute,
    protected override FRService: ForgeRockService,
    protected override userService: UserService,
    private translate: TranslateService
)  {
    super(FRService,router,route,userService);
  }

  override getTreeName(): string {
    return 'registration';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.showSpinner = true;
  }

  override ngAfterViewInit():void {
    super.ngAfterViewInit();
    this.nav?.shown.subscribe((activeStep) => {
      this.focusemail = (activeStep == Stage.VERIFIED_EMAIL.toString()) ;
      this.focusIdentity = (activeStep == Stage.USER_IDENTITY.toString())
    })

   let subscribe = this.OnJourneyStarted.subscribe((val)=>{
    this.journeyStarted = true;
    if (this.nextStage == Stage.LOGIN) {
      this.confirmSuccess(this.sentVerificattionCode);
    }
    if (val) {
       this.showSpinner = false;
       let currentStage = this.getNextNavStep()?.stage;
       this.active = currentStage?.toString();
       if (currentStage == Stage.TOU && this.userService.touAccepted) {
        this.hasStepResponse(undefined,currentStage);
       }
       subscribe.unsubscribe();
    }
   });
  }

  hasStepResponse(data:any, currentStage:Stage) {
    if (this.unlockedStage(currentStage)) {
        let formdata = [Stage.USER_CREATION, Stage.VERIFIED_EMAIL].includes(currentStage) ? data : undefined;
        this.nextStageInJourney(formdata).then((nextStage)=>{
          this.onStepComplete(currentStage);
        },(err)=>{

        });
    
    }
  }

  onStepComplete(currentStage:Stage) {
    if (this.hasStepCompleted(currentStage)) {
        this.active = this.getNextNavStep(currentStage)?.stage.toString();
        if (this.registered) {
          if (this.nextStage == Stage.EMAIL_SENT) {
            this.sentVerificattionCode = true;
            this.hasStepResponse({},this.nextStage); //acknowledge message received to IDP
          }else{
            this.confirmSuccess(this.sentVerificattionCode);
          }
        }
    }
  }

  private getNextNavStep(stage ?:Stage) {
    let nextStage = this.nextStage && [Stage.USERNAME,Stage.PASSWORD,Stage.EMAIL_SENT].includes(this.nextStage) ? Stage.USER_CREATION : this.nextStage;
    return nextStage? this.steps.find((s)=>s.stage == nextStage) : undefined;
  } 


  protected unlockedStage(stage:Stage) {
    switch (stage) {
      case Stage.TOU: return true;
      case Stage.USER_IDENTITY: return this.userService.touAccepted;
      case Stage.VERIFIED_EMAIL: return this.userService.touAccepted && this.userService.identityVerified;
      case Stage.USER_CREATION: return this.userService.touAccepted && this.userService.identityVerified && this.userService.emailConfirmed;
      case Stage.EMAIL_SENT: return this.registered;
    }
    return false;
  }

  protected hasStepCompleted(stage:Stage) {
    switch (stage) {
      case Stage.TOU: return this.userService.touAccepted;
      case Stage.USER_IDENTITY: return this.userService.identityVerified;
      case Stage.VERIFIED_EMAIL: return this.userService.emailConfirmed;
      case Stage.USER_CREATION: this.registered = this.userService.registered; return this.userService.registered;
      case Stage.EMAIL_SENT: return this.sentVerificattionCode;
    }
    return false;
  }

}
