<form id="passwordChangeForm" name="passwordChangeForm" #passwordChangeForm="ngForm" (ngSubmit)="onSubmit()"
      dnErrorControl="errorControl" #errorControl="dnErrorControl" novalidate scrollToErrorForm>
    <div class="col-sm-10">
        <input-password name="currentPassword" *ngIf="nextStage==Stage.CURRENT_PASSWORD"
                        passwordLabel="Current Password"
                        [passwordOnly]="true"
                        passwordPlacehold="Enter current password">
        </input-password>
        <input-password *ngIf="nextStage == Stage.PASSWORD"
                        name="aNewPassword"
                        newPasswordLabel="New Password"
                        [registeredUsername]=""
                        [firstName]=""
                        [lastName]=""
                        [passwordOnly]="false">
        </input-password>
    </div>
    <div class="mt-4 d-flex justify-content-center">
        <button type="submit" class="btn btn-primary"  #subButton
            [disabled]="!passwordChangeForm.form.valid || submitted || resending">{{buttonText}} <i class="fa-solid fa-spinner" *ngIf="submitted"></i></button>
    </div>

</form>
