import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import {  NgForm, NgModel} from '@angular/forms';
import { AppFormsService } from '../app-forms.service';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { catchError, Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { FRStepError } from '../../shared/services/forge-rock.interface';


@Component({
  selector: 'input-identity',
  templateUrl: './input-identity.component.html'
})
export class InputIdentityComponent  extends AbstractErrorControlComponent implements OnInit, AfterViewInit{
  @Input() retries = 3;

  protected override dispayErrorList = ['identityAnswer'];
  @ViewChild('identityVerificationForm', { static: true }) identityVerificationForm !: NgForm;
  @ViewChild('answer') answerCtrl !: NgModel;
  @ViewChild('errModel') errModel !: NgModel;

  @Output() OnIdentityAnswer = new EventEmitter<boolean>();

  submitted = false;
  private attempts = 0;
  autofocus = true;
  
  identityError = {} as FRStepError;
  verified = false;

  constructor(protected userService: UserService,
    override formsService: AppFormsService,
    protected router: Router
  ) {
    super(formsService);
  }
  
  monthDay : string = '';

  ngOnInit(): void {
    window.scrollTo(0, 0);
  };

  override ngAfterViewInit(): void{
    super.ngAfterViewInit();
    if(this.userService.identityCheckMonthDay){
      this.monthDay = this.userService.identityCheckMonthDay;
    }
     this.answerCtrl.control.valueChanges?.subscribe(val=>{
       if (val!=this.monthDay) {
           this.identityError = {} as FRStepError;
           this.showErrors(this.dispayErrorList);
       }
     });
    this.verified = this.userService.identityVerified;
    this.showErrors(this.dispayErrorList);
  }

  verifyIdentity() {
    this.showErrors();
    if (this.identityVerificationForm.valid){
      this.submitted = true;
      this.userService.setIdentityCheckMonthDay(this.monthDay,this.OnIdentityAnswer).pipe(
        catchError(err=>{
          return this.handleError(err);
        })
      )
      .subscribe((verified)=>{
          this.submitted = false;
          this.verified = verified;
          if (!this.verified && (this.attempts > this.retries)) {
            this.router.navigate([`${this.userService.userType}/invalidRegLink`],{replaceUrl: true});
          }
      });
    }
  }

  isVerified() {
    return this.userService.identityVerified == true;
  }

  private handleError(err:any):Observable<boolean> {
    this.identityError=err;
    ++this.attempts;
    return of(false);
  }
} 
