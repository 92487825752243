<div class="d-flex justify-content-center">
    <div class="col-lg-4 col-md-5 col-sm-12 shadow-lg rounded pb-5 bg-white mt-5 px-3" *ngIf="resetting">
        <div class="text-primary">
            <h5 class="mt-3 d-flex justify-content-center pt-5">
                <i [ngClass]="icon"></i><br>
            </h5>
            <h5 class="d-flex justify-content-center">
            <p>{{headerText}}</p>
        </h5>
        </div>
        <div>
            <journey-password-reset
                headerText="{{'password.reset.instruction' | translate}}"
                resetWarning="{{'password.reset.warning' | translate}}"
                buttonText="{{'password.reset.button-text' | translate}}"
                otpButtonText="{{'otp.button-text' | translate}}"
                otpInstruction="otp.instruction"
                (onPasswordReset)="onStepComplete($event)">
            </journey-password-reset>
        </div>
    </div>
    <div class="" *ngIf="!resetting">
        <confirm-email 
            [data]="translationData"
            confirmEmailContent="{{'password.reset.success.content' | translate}}"
            confirmEmailTitle=""
            headerText="{{'password.reset.success.title' | translate}}"
            [updateTranslation]="!resetting"
            > 
        </confirm-email>
    </div>

</div>
