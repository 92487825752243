import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dn-progress-spinner',
  templateUrl: './dn-progress-spinner.component.html',
    styleUrl: './dn-progress-spinner.component.css'
})
export class DnProgressSpinnerComponent implements OnInit {

  @Input() text = "Loading, please wait...";
  @Input() icons = "fa-solid fa-circle-notch fa-spin fa-4x";

  ngOnInit(): void {
  
  }
}
