import i from "./index.js";
class d extends i {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(r) {
    super(r), this.payload = r;
  }
  /**
   * Gets the available providers.
   */
  getProviders() {
    return this.getOutputByName("providers", []);
  }
  /**
   * Sets the provider by name.
   */
  setProvider(r) {
    const t = this.getProviders().find(e => e.provider === r);
    if (!t) throw new Error(`"${r}" is not a valid choice`);
    this.setInputValue(t.provider);
  }
}
export { d as default };