<div class="d-flex justify-content-center">
    <div class="col-lg-4 col-md-5 col-sm-12 shadow-lg rounded pb-5 bg-white mt-5 px-3" *ngIf="recovering">
        <div class="row text-primary">
            <h5 class="mt-3 d-flex justify-content-center pt-5">
                <i class="fa-regular fa-id-card fa-2xl"></i><br>
            </h5>
            <h5 class="d-flex justify-content-center">
                <p>{{headerText}}</p>
            </h5>
        </div>
        <div class="row">
            <journey-recover-username
                headerText="{{'recover.username.instruction' | translate}}"
                buttonText="{{'recover.username.button-text' | translate}}"
                (onUsernameRequested)="onStepComplete($event)">
            </journey-recover-username>
        </div>
    </div>
    <div class="" *ngIf="!recovering">
        <confirm-email
            [data]="translationData"
            confirmEmailContent="{{'recover.username.success.content' | translate}}"
            confirmEmailTitle=""
            headerText="{{'recover.username.success.title' | translate}}"
            [updateTranslation]="!recovering"
            >
        </confirm-email>
    </div>
</div>
