import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';
import { AbstractControl, FormGroup, NgForm } from '@angular/forms';
import { Stage, UserModel } from '../../shared/services/forge-rock.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'journey-change-password',
  templateUrl: './journey-change-password.component.html'
})
export class JourneyChangePasswordComponent extends AbstractJourneyComponent implements OnDestroy{
  @Input() buttonText = "Submit";
  @ViewChild('passwordChangeForm', { static: true }) ngForm !: NgForm;
  @Output() onPasswordChanged= new EventEmitter<any>();


  protected submitted = false;

  private currentPassword !: string;
  private newPassword !:string;
  private passwordControl !: AbstractControl | null;
  private subscriptions:Subscription[] = [];
  private passwordErrors: any;
  private sentEmail = false; 

  override getTreeName(): string {
    return 'changePassword';
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s)=>s?.unsubscribe);
  }

  onSubmit() {
    this.showErrors();
    if(this.ngForm.invalid) {
      return;
    }
    let formdata = this.ngForm.form.value;
    this.currentPassword = formdata.currentPassword?.password;
    this.newPassword = formdata.aNewPassword?.password;
    let userCredentials : UserModel = {} as UserModel;
    userCredentials.password = this.nextStage==Stage.CURRENT_PASSWORD ? this.currentPassword : this.newPassword;
    userCredentials.otpChoice = this.otpChoice;
    userCredentials.validatePassword = this.nextStage==Stage.PASSWORD
   // this.handleResendCode('reject').then((resp) => {
      this.submitted = true
      this.continueJournery(userCredentials).then((nextStage)=>{
        this.submitted = false;
        if (this.nextStage == Stage.PASSWORD && !this.passwordControl) {
          this.monitorPasswordControl();
        }
        if (Stage.LOGIN) {
            this.onPasswordChanged.emit({loginRedirect: this.loginRedirect})
            this.sentEmail = false;
        }
      },(err)=>{
        this.submitted = false;
      });
    //});
  }

  private continueJournery(credentials:UserModel):Promise<any> {
     let currentStage = this.nextStage;
     return this.nextStageInJourney(credentials).then((nextStage)=>{
 
           if (currentStage == Stage.PASSWORD) {
              let violations = this.checkForPolicyViolations(currentStage);
              this.passwordControl?.setErrors(violations);
              this.passwordErrors = violations;
              if (violations == null && credentials.validatePassword) {
                credentials.validatePassword = false;
                return this.continueJournery(credentials);
              }
          }
 
      return nextStage;
    });
  }

  private monitorPasswordControl() {
    let controls = this.ngForm.controls["aNewPassword"] as FormGroup;
    this.passwordControl = controls.get('newPassword');
    if (this.passwordControl) {
        this.subscriptions.push(this.passwordControl.valueChanges?.subscribe((val)=>{
          if (val!=this.newPassword) {
            this.passwordErrors = null;
          }
        }));
    }
  }

}
