import t from "../fr-policy/index.js";
import { StepType as r } from "./enums.js";
class i {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    this.payload = e, this.type = r.LoginFailure;
  }
  /**
   * Gets the error code.
   */
  getCode() {
    return Number(this.payload.code);
  }
  /**
   * Gets the failure details.
   */
  getDetail() {
    return this.payload.detail;
  }
  /**
   * Gets the failure message.
   */
  getMessage() {
    return this.payload.message;
  }
  /**
   * Gets processed failure message.
   */
  getProcessedMessage(e) {
    return t.parseErrors(this.payload, e);
  }
  /**
   * Gets the failure reason.
   */
  getReason() {
    return this.payload.reason;
  }
}
export { i as default };