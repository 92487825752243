<div class="d-flex align-items-center justify-content-center" style="min-height: 500px;">
  <div class="card text-center border-0">
      <div class="card-body text-danger">
        <h5 class="card-title">
          <p><i class="fa-solid fa-chain-broken fa-2xl"></i></p>
          <p [innerHTML]="'registration.invalid-title' | translate"></p>
        </h5>
          <span [innerHTML]="'registration.invelid-content'| translate:{phone: ('support.phone'|translate)}"></span>&nbsp;          
      </div>
  </div>
</div>
