import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const AT_LEAST_ONE_LOWERCASE_CHARACTER_PATTERN = /[a-z]/g;
export const AT_LEAST_ONE_UPPERCASE_CHARACTER_PATTERN = /[A-Z]/g;
export const AT_LEAST_ONE_DIGIT_PATTERN = /[0-9]/g;
export const AT_LEAST_ONE_SPECIAL_CHARACTER_PATTERN = /[~!@#$%^&*()[{}]/g;
export const MORE_THAN_5_CONCECUTIVE_NUMS_PATTERN = /\d{6,}/g;
export const specialWords = ['SYS', 'LOG', 'TSO', 'PAS', 'NEW', 'NET', 'ROS', 'XXX', 'SIGN', 'VTAM', 'DEMO', 'ASDF', '1234', 'BASIC', 'FOCUS', 'CADAM', 'VALID', 'GAME', 'IL1', 'TX1', 'OK1', 'NM1', 'MT1', 'DG1', 'APPL'];
export const months = ['JAN', 'FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
export const dayOfTheWeek = ['MON','TUE','WED','THU','FRI','SAT','SUN'];
export const ALPHANUMERIC_SYMBOLS_PATTERN = /[A-Za-z0-9]+$/g;
export const ALPHANUMERIC_SYMBOLS_ONLY_PATTERN = /^[a-zA-Z0-9]+$/g;
export const USER_NAME_NOT_ALLOWED_PATTERH =/[a-zA-Z]\d{6,9}$/g;
export const REPEATING_LETTERS_NUMBERS =/([a-zA-Z0-9])\1\1/gi;

export class DnValidators {

  static textMatch(textToMatch: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      return control.value === textToMatch ? null: { 'textMatch': true, value: control.value } ;
    }
  };

  //More than 5 numbers next to each other
  static moreThan5NumbersNextToEachOther(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      return control.value.match(MORE_THAN_5_CONCECUTIVE_NUMS_PATTERN) ? { 'moreThan5NumbersNextToEachOther': true, value: control.value } : null ;
    }
  };

  //Letters or numbers in order, like abc123
  static consecutiveCharsOrNums(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      for (let i = 2; i < control.value.length; i++) {
        if (!control.value.charAt(i).match(ALPHANUMERIC_SYMBOLS_PATTERN) ||
            !control.value.charAt(i - 1).match(ALPHANUMERIC_SYMBOLS_PATTERN) ||
            !control.value.charAt(i - 2).match(ALPHANUMERIC_SYMBOLS_PATTERN)) {
          continue;
        }
        if ((control.value.charCodeAt(i) - control.value.charCodeAt(i - 1) === 1 && control.value.charCodeAt(i - 1) - control.value.charCodeAt(i - 2) === 1) ||
            (control.value.charCodeAt(i) - control.value.charCodeAt(i - 1) === -1 && control.value.charCodeAt(i - 1) - control.value.charCodeAt(i - 2) === -1)) {
          return { 'consecutiveCharsOrNums': true, value: control.value };
        }
      }
      return null;
    }
  };

  static shouldNotContainWord([shouldNotContainList, errorName]: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      if (!shouldNotContainList || shouldNotContainList.lenght == 0) return null;
      for (let i=0; i<shouldNotContainList.length; i++) {
        if (control.value.toUpperCase().includes(shouldNotContainList[i].toUpperCase())) {
          return {[errorName]: true, value: control.value};
        }
      }
      return null;
    }
  };

  static shouldMatchAtLeastOne([pattern, errorName]: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      return !control.value.match(pattern) ? { [errorName]: true, value: control.value } : null ;
    }
  }

  static shouldNotMatch([pattern, errorName]: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value && control.value)) {
        return null;
      }
      return control.value.match(pattern) ? { [errorName]: true, value: control.value } : null ;
    }
  }

  static validateMonthDate(errorName?:string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control && control.value)) {
        return null;
      }
      if (errorName) {
        return  { [errorName]: true, value: control.value }
      }
      let mon = 0;
      let dt = 0;
      const values = control.value;
      if(!values)
        return null;

      if(values.length <= 2) {
        mon = parseInt(values);
      }
      else{
        const month = values.substring(0, 2);
        mon = parseInt(month);
        dt = parseInt(values.replace(month, ''));
      }
      let dateNotOk = { 'invalidDate': true, value: control.value }
      let date = new Date(2020,mon-1,dt)
      if(date.getFullYear()=== 2020 && date.getMonth()=== mon-1 && date.getDate()===dt)
        return null;
      else
        return dateNotOk;
    }
  };

}


