  <div class="mt-3">
    <div id="disclaimer-div" (scroll)="onDisclaimerScroll($event)" class="row overflow-auto content square scrollbar-dusty-grass text-muted smaller-font">
      <p style="height: 550px;" [innerHTML]="touContent"></p>
    </div>

    <div class="col-md-12 d-flex align-items-center justify-content-center" style="margin-top:10px" *ngIf="!isRegistered">
      <div class="d-flex" style="margin-right:10px">
        <div class="form-check">
          <input [disabled]="disableIAgreeCheckbox()" (click)="onCheckUncheck()" class="form-check-input" style="border-color: grey;" type="checkbox" [(ngModel)]="iAgreeCheckbox" id="iAgreeCheckBox">
          <label class="form-check-label" for="iAgreeCheckBox" title="iAgreeCheckBox">
            {{touCheckBoxName}}
          </label>
        </div>
      </div>
      <div class="d-flex">
        <button #btnContinue type="button" id="btnContinue" class="btn btn-primary"
          [disabled]="!iAgreeCheckbox || isAccepted() || isRunning" *ngIf= "!isAccepted()"
          (click)="onAccept()">Accept <i class="fa-solid fa-spinner" *ngIf="isRunning"></i></button>
      </div>
    </div>
</div>


