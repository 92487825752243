import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import {  AbstractControl, FormGroup, NgControl, NgForm, ValidationErrors } from '@angular/forms';
import { AppFormsService } from '../app-forms.service';
import { FRResponseError, UserModel } from '../../shared/services/forge-rock.interface';
import { UserService } from '../../shared/services/user.service';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { DnConfirmationComponent } from '../../dn-common/components/dn-confirmation/dn-confirmation.component';
import { catchError, concatMap, map, Observable, of, Subscription, switchMap, takeWhile, throwError } from 'rxjs';

export enum RegistrationAction {
  CONFIRM_EMAIL,
  REGISTER
}

@Component({
  selector: 'registration-form',
  templateUrl: './registration-form.component.html'
})
export class RegistrationFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() action !: RegistrationAction;
  @Input() buttonText !: string;
  @Input() autofocus = false;

  @ViewChild('errorControl', { static: true }) _errorControl!: DnErrorControlDirective;
  @ViewChild('registrationForm', { static: true }) registrationForm !: NgForm;
  @ViewChild('dnConfirm', {static: false, read: DnConfirmationComponent}) dnConfirmation !: DnConfirmationComponent;

  @Output() onRegister = new EventEmitter<any>();
  @Output() onRegisterEmail = new EventEmitter<any>();
  @Output() onRegistrationRestart = new EventEmitter<any>();

  private savedState !: {};
  private subscriptions: Subscription[] = [];
  user !: UserModel;

  editable = false;
  running = false;
  usernameErrors: any;
  passwordErrors: any;
  validating = false;
  registered = false;
  usernameAccepted = false;

  private usernameControl !: AbstractControl;
  private passwordControl !: AbstractControl | null;

  constructor(private formsService: AppFormsService,
              private userService: UserService,
              private frService : ForgeRockService
  ) {}

  ngOnInit(): void {
    this.registered = this.userService.registered;
    this.initModel();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.saveFormState();
      this.usernameControl = this.registrationForm.controls["username"];
      this.subscriptions.push(this.usernameControl?.valueChanges?.subscribe((val)=>{
        if (val!=this.user.username) {
          this.usernameErrors = null;
        }
      }));

      let controls = this.registrationForm.controls["passwords"] as FormGroup;
      this.passwordControl = controls.get('newPassword');
      if (this.passwordControl) {
          this.subscriptions.push(this.passwordControl.valueChanges?.subscribe((val)=>{
            if (val!=this.user.password) {
              this.passwordErrors = null;
            }
          }));
      }
    });

    this.subscriptions.push(this.formsService.onUserNameChanged.subscribe((val)=>{
      if (val && val != this.user.username) {
          this.user.username = val;
          this.validating = true;
          let subscription = this.validateOrcreateUsername(this.user.username,true,this.onRegister).subscribe((val)=>{
            this.validating = false;
            subscription.unsubscribe();
          });
      }
    }));
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach((s)=>s?.unsubscribe());
  }

  emailChanged(val:any) {
    this.onRegisterEmail.emit(val)
  }

  onSubmit() {
      this.showErrors();
      if(this.registrationForm.invalid) {
        return;
      }
      let formdata = this.registrationForm.form.value;
      this.user.username = formdata.username;
      this.user.password = formdata.passwords.password;

      //bidm steps: 1. create username, 2. validate password, 3. complete registration
      this.running = true;
      let usercreated = false;
      let subscription:Subscription = this.validateOrcreateUsername(formdata.username,false,this.onRegister).subscribe({
          next: val =>usercreated = (val == this.user.username),
          complete: () => {
            subscription?.unsubscribe();
            if (usercreated) {
              this.usernameAccepted = true;
              this.validatePassword();
            }else{
              this.running = false;
            }
          }
      })
  }

  //Step 2
  private validatePassword() {
    let formdata = this.registrationForm.form.value;
    this.validating = true;
    let validPassword = false;
    let subscription:Subscription = this.userService.validateNewPassword(formdata.passwords.password,this.onRegister).subscribe({
      next: val => validPassword = val,
      error: (errors) =>  this.setControlErrors("newPassword",errors),
      complete: () => {
        this.validating = false;
        subscription?.unsubscribe();
        if (validPassword) {
          this.register()
        }else{
          this.running = false;
        }
      }
    });
  }

  //Step 3
  register(){
     this.userService.registerUser(this.user,this.onRegister).pipe(
        catchError((errors)=>{
          let errs = errors instanceof FRResponseError ? {'createUser':true} : errors;
          this.setControlErrors("newPassword",errs);
          this.validating = false;
          return of(errors);
        })).subscribe(val => {
          this.running = false;
        });
  }

  validateOrcreateUsername(username:string,validateCredentials:boolean,eventToRaise:any):Observable<any> {
      return this.userService.uniqueUsername(username,validateCredentials,eventToRaise).pipe(
        catchError((errors)=>{
          this.setControlErrors("username",errors);
          return of(errors);
        }));
  }


  resetForm() {
    this.formsService.showErrors(false);
    this._errorControl.hideErrors();
    this.registrationForm.resetForm(this.savedState);
    this.initModel();
    this.validating = false;
    this.running = false;
  }

  restartJourney() {
    this.onRegistrationRestart.emit();
  }

  get RegistrationAction() {
    return RegistrationAction;
  }

  private showErrors() {
    this.formsService.showErrors(true);
    this._errorControl.showErrors();
  }

  private saveFormState() {
    this.savedState = {...this.registrationForm.form.value};
  }

  private initModel() {
    this.user = this.userService.user;
    if (this.userService.registered) {

    }
  }
  private setControlErrors(name:string,errors:any) {
     if (name=="username") {
      this.usernameControl.setErrors(errors);
      this.usernameErrors = errors;
     }
     if (name=="newPassword") {
      this.passwordControl?.setErrors(errors);
      this.passwordErrors = errors;
     }
  }
}
