import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppHttpInterceptor {
  onAjaxError: BehaviorSubject<Object> = new BehaviorSubject({}); 
  onAuthorizationError: BehaviorSubject<Object> = new BehaviorSubject({});

 
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    return next.handle(req)
           .pipe(
            catchError((error) => { 
                let method;
                let requestUrl;
                if (error instanceof HttpErrorResponse && 
                    !(error.error instanceof ErrorEvent) && 
                    !(error.status == 400 && req.url?.endsWith(environment.common.fr.endpoints.verifyEmail))) {
                  if (error.status == 403) {
                     this.onAuthorizationError.next(error);
                  }            
                  if (error.status != 401 && req.url != 'version') {
                    this.onAjaxError.next(error); 
                  }
                   
                } 
                return throwError(()=>error);
            }),
            map((e: HttpEvent<any>) =>{
                return e;
            })   
           );
  }
  
 
}
