import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { Stage } from '../../shared/services/forge-rock.interface';

@Component({
  selector: 'otp-choices',
  templateUrl: './otp-choices.component.html'
})
export class OtpChoicesComponent implements OnInit {
  @Input() stage !: Stage; 

  @Output() OnOtpMethodSelected = new EventEmitter<any>();

  choices : string[] = [];
  
  constructor( private FRService: ForgeRockService) {
  }
  ngOnInit(): void {
    this.choices  = this.FRService.getChoices(this.stage); //supporting only email at this time
     let emailChoiceNdx = this.choices.findIndex((c)=>c.toLowerCase().includes('email'));
     if (emailChoiceNdx > -1) {
        this.onSelection(emailChoiceNdx);
     }else{
        throw new Error("Contact Email is missing");
     }
  }

  private onSelection(index: number) {
      let properties = this.choices[index].split(":");
      let resp = {otpMethod:properties[0], otpMaskedValue:properties[1], choiceIndex: index}
      this.OnOtpMethodSelected.emit(resp);
  }

}
