import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { Subscription } from 'rxjs';
import { AppFormsService } from '../app-forms.service';
import { PasswordErrors } from '../../dn-common/directives/valid-password.directive';

@Component({
  selector: 'abstract-error-control',
  templateUrl: './abstract-error-control.component.html'
})
export abstract class AbstractErrorControlComponent implements OnDestroy, AfterViewInit{
  protected dispayErrorList:any[] = []
  
  @ViewChild('errorControl', { static: false }) _errorControl !: DnErrorControlDirective;
 
  private errorControlSubscription !: Subscription;
  
  constructor(protected formsService: AppFormsService){}

  ngOnDestroy(): void {
    this.errorControlSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {     
      this.errorControlSubscription = this.formsService.onShowErrors.subscribe((showErrors)=>{
        if (this._errorControl) {
          if (showErrors) {
            this._errorControl.showErrors();
          }else{
            this._errorControl.hideErrors();
            if (this.dispayErrorList.length > 0) this._errorControl.showErrors(this.dispayErrorList);
          }
        }
      });    
  }

  protected showErrors(val ?: string | string[] | null | undefined) {
    this._errorControl.showErrors(val);
  }

  protected hideErrors() {
    this._errorControl.hideErrors();
  }
}
