import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorComponent } from './components/global-error/global-error.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { YmlLoaderService } from './services/yml-loader.service';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppNavComponent } from './components/app-nav/app-nav.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PasswordGuidelinesModalComponent } from '../dn-common/components/password-guidelines-modal/password-guidelines.component-modal';
import { InvalidRegistrationLinkComponent } from './components/invalid-registration-link/invalid-registration-link.component';
import { UsernameGuidelinesModalComponent } from '../dn-common/components/username-guidelines-modal/username-guidelines-modal.component';

@NgModule({
  declarations: [
    GlobalErrorComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppNavComponent,
    PageNotFoundComponent,
    PasswordGuidelinesModalComponent,
    InvalidRegistrationLinkComponent,
    UsernameGuidelinesModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: YmlLoaderService
      },
      defaultLanguage: 'en'
    })
  ],
  exports: [
    GlobalErrorComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppNavComponent,
    InvalidRegistrationLinkComponent
  ]
})
export class SharedModule { }
