
<form id="registrationForm" name="registrationForm" #registrationForm="ngForm"
      (ngSubmit)="onSubmit()" novalidate dnErrorControl="errorControl" #errorControl="dnErrorControl">

  <dn-confirmation #dnConfirm></dn-confirmation>
  <div class="col-md-8">
    <div class="mt-3  form-group">
      <label for="firstName">First Name</label>
      <input type="text" class="form-control readOnlyColor disable-click" id="firstName" name="firstName" placeholder="Enter first name"
            [(ngModel)]="user.firstname"
            [readonly]="editable != true"
            dnDisplayErrors>
    </div>
    <div class="mt-3  form-group">
      <label for="lastName">Last Name</label>
      <input type="text" class="form-control readOnlyColor disable-click" id="lastName" name="lastName" placeholder="Enter last name"
            [(ngModel)]="user.lastname"
            [readonly]="editable != true"
            dnDisplayErrors>
    </div>
    <div class="mt-3  form-group">
      <label for="phone">Phone Number</label>
      <input type="text" class="form-control readOnlyColor disable-click" id="phone" name="phone" placeholder="Enter phone number"
            [(ngModel)]="user.phoneNumber" mask="(999) 999-9999"
            [readonly]="editable != true"
            dnDisplayErrors>
    </div>
  </div>

  <div class="col-md-9">
    <input-email name="userEmail" [userEmail]="user.unverifiedEmail"
        [editable]="false">
      </input-email>
  </div>

  <div class="col-md-8">
    <input-username name="username"
          [autofocus]="true"
          [passwordOnly]="false"
          [isUnique]="usernameErrors"
          [editable]="!usernameAccepted"
          [showUsernameGuidelines]="true"></input-username>
  </div>

  <div class="col-md-8" *ngIf="!registered ">
    <input-password name="passwords" [passwordOnly]="false"
                                     [firstName]="user.firstname"
                                     [lastName]="user.lastname"
                                    ></input-password>
  </div>


  <div class="mt-5 row" *ngIf="!registered">
    <div class="col-sm-9 d-flex justify-content-center">
      <button type="button" *ngIf="!usernameAccepted"
              class="btn btn-secondary me-1" title="Reset form" (mousedown)="resetForm()" [disabled]="running || validating">Reset</button>
      <button type="submit" class="btn btn-primary"
              title="Click to complete registration"
              [disabled]="running || validating">{{buttonText}} <i class="fa-solid fa-spinner" *ngIf="running"></i></button>
    </div>
    <div class="col-sm-2">
      <button type="button"
      class="btn btn-outline-secondary mx-1" title="Restart registration process" (mousedown)="restartJourney()" [disabled]="running || validating">Restart</button>
    </div>
  </div>

</form>
