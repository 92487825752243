import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../shared/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ForgeRockService } from '../../../shared/services/forge-rock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmStatus } from '../../../shared/services/forge-rock.interface';

@Component({
  selector: 'provider-email-verification',
  templateUrl: './provider-email-verified.component.html'
})
export class ProviderEmailVerifiedComponent implements OnInit {

  initializing = false;
  translationData:any = {};
  resendVerificationCode = false;
  titleRef = "";
  contentRef = "";
  status:any = undefined;

  constructor(
    protected userService: UserService,
    private translate: TranslateService,
    protected FRService: ForgeRockService,
    protected router: Router,
    protected route:ActivatedRoute,
)  {
  }
  ngOnInit(): void {
    const userName = this.route.snapshot.queryParams['userName'];
    const userType = this.route.snapshot.data['type'];
    this.status = this.route.snapshot.queryParams['status'];
    this.initializing = true;
    this.FRService.loadConfig(userType).subscribe((config)=>{
          let loginRedirect = config.redirects?.find(r => r.userType == userType && r.name == 'login')?.value;
          let hideUsername =  this.userService.username == undefined && userName == undefined;
          let hideEmail = this.userService.unVerifiedEmail == undefined || this.userService.unVerifiedEmail == "";
          this.translationData = {emailAddr: this.userService.unVerifiedEmail,
                                  username: this.userService.username || userName,
                                  loginRedirect: undefined,
                                  hideDetails: hideEmail && hideUsername ?  'd-none' : '',
                                  hideUsername: hideUsername ? 'd-none' : '',
                                  hideEmail: hideEmail ? 'd-none' : '',
                                  stopRedirect: false} //classes to hide email
          if (this.shouldRedirectToLogin()) {
              this.translationData['loginRedirect'] = loginRedirect;
              this.translationData['stopRedirect'] = false;
          }
          if([EmailConfirmStatus.REGISTRATION_SUCCESS].includes(this.status)){
            this.translationData['stopRedirect'] = true;
            this.translationData['loginRedirect'] = loginRedirect;
          }
          this.titleRef = this.contentTitle();
          this.contentRef = this.contentLabel();
          this.initializing = false;

    });
  }


  contentTitle() {
    if ([EmailConfirmStatus.EMAIL_VERIFIED_SUCCESS,EmailConfirmStatus.EMAIL_VERIFIED_FAILED].includes(this.status)) {
      return 'registration.success.title';
    }
    return this.shouldEmailVerify() || this.status == EmailConfirmStatus.VERIFICATION_CODE_SENT ? 'registration.verification-code.title': 'registration.success.title'
  }

  contentLabel() {
    if ([EmailConfirmStatus.EMAIL_VERIFIED_SUCCESS,EmailConfirmStatus.EMAIL_VERIFIED_FAILED].includes(this.status)) {
       return this.status == EmailConfirmStatus.EMAIL_VERIFIED_SUCCESS ? 'registration.verification-code.content-success' : 'registration.verification-code.content-red';
    }
    if (this.shouldEmailVerify() || this.status == EmailConfirmStatus.VERIFICATION_CODE_SENT ) {
      return this.userService.unVerifiedEmail ?  'registration.verification-code.content' :'registration.verification-code.content-noemail';
    } else {
      return 'registration.success.content'
    }
  }

  shouldEmailVerify() {
    return this.userService.unVerifiedEmail != "" && !(this.userService.isEmailsMatch());
  }

  private shouldRedirectToLogin() {
    if ([EmailConfirmStatus.VERIFICATION_CODE_SENT].includes(this.status)){
      return false;
    }
    return true;
  }

}
