import t from "./index.js";
class u extends t {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    super(e), this.payload = e;
  }
  /**
   * Gets the message content.
   */
  getMessage() {
    return this.getOutputByName("message", "");
  }
  /**
   * Gets the message type.
   */
  getMessageType() {
    return this.getOutputByName("messageType", "");
  }
}
export { u as default };