import r from "../config/index.js";
class a {
  /**
   * Retrieve tokens.
   */
  static async get(e) {
    const t = sessionStorage.getItem(`${r.get().prefix}-${e}`);
    if (t) try {
      return JSON.parse(t || "");
    } catch {
      throw new Error("Could not parse token from sessionStorage");
    }
  }
  /**
   * Saves tokens.
   */
  static async set(e, t) {
    const s = JSON.stringify(t);
    sessionStorage.setItem(`${r.get().prefix}-${e}`, s);
  }
  /**
   * Removes stored tokens.
   */
  static async remove(e) {
    sessionStorage.removeItem(`${r.get().prefix}-${e}`);
  }
}
export { a as default };