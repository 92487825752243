import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextMatchDirective } from './directives/text-match.directive';
import { ValidPasswordDirective } from './directives/valid-password.directive';
import { ValidUserNameDirective } from './directives/valid-username.directive';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { ScrollToErrorFormDirective } from './directives/scroll-to-error-form.directive';
import { HasFrErrorDirective } from './directives/has-fr-error.directive';
import { MonthDayDirective } from './directives/month-day.directive';
import { DnConfirmationComponent } from './components/dn-confirmation/dn-confirmation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { DnProgressSpinnerComponent } from './components/dn-progress-spinner/dn-progress-spinner.component';
import { UniqueValueDirective } from './directives/unique-value.directive';
 

@NgModule({
  declarations: [
    TextMatchDirective, ValidPasswordDirective, ValidUserNameDirective, PasswordRulesComponent, AutofocusDirective, CapitalizePipe,
    ScrollToErrorFormDirective, HasFrErrorDirective, MonthDayDirective,DnConfirmationComponent, PhoneFormatPipe, DnProgressSpinnerComponent,
    UniqueValueDirective
  ],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports: [
    TextMatchDirective, ValidPasswordDirective, ValidUserNameDirective, PasswordRulesComponent, AutofocusDirective, CapitalizePipe,
    ScrollToErrorFormDirective, HasFrErrorDirective,MonthDayDirective,DnConfirmationComponent,DnProgressSpinnerComponent,
    UniqueValueDirective
  ]
})
export class DnCommonModule { }
