class c {
  /**
   * Creates a random state.
   */
  static createState() {
    return this.createRandomString(16);
  }
  /**
   * Creates a random verifier.
   */
  static createVerifier() {
    return this.createRandomString(32);
  }
  /**
   * Creates a SHA-256 hash of the verifier.
   *
   * @param verifier The verifier to hash
   */
  static async createChallenge(e) {
    const t = await this.sha256(e);
    return this.base64UrlEncode(t);
  }
  /**
   * Creates a base64 encoded, URL-friendly version of the specified array.
   *
   * @param array The array of numbers to encode
   */
  static base64UrlEncode(e) {
    const t = Array.prototype.slice.call(e);
    return btoa(String.fromCharCode.apply(null, t)).replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
  }
  /**
   * Creates a SHA-256 hash of the specified string.
   *
   * @param value The string to hash
   */
  static async sha256(e) {
    const t = new TextEncoder().encode(e),
      a = await crypto.subtle.digest("SHA-256", t);
    return new Uint8Array(a);
  }
  /**
   * Creates a random string.
   *
   * @param size The number for entropy (default: 32)
   */
  static createRandomString(e = 32) {
    const t = new Uint8Array(e);
    return crypto.getRandomValues(t), btoa(t.join("")).replace(/[^a-zA-Z0-9]+/, "");
  }
}
export { c as default };