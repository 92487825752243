import e from "./index.js";
class c extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the redirect URL.
   */
  getRedirectUrl() {
    return this.getOutputByName("redirectUrl", "");
  }
}
export { c as default };