import { Component, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-username-guidelines-modal',
  templateUrl: './username-guidelines-modal.component.html'
})
export class UsernameGuidelinesModalComponent implements OnInit {

  constructor() { }

  activeModal = inject(NgbActiveModal);
  usernameMinLen : any = null;
  usernameMaxLen : any = null;

  ngOnInit() {
    this.usernameMinLen = environment.common.constants.userNameMinLength;
    this.usernameMaxLen = environment.common.constants.userNameMaxLength;
  }


}
