import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivate, canJourneyStart, canRegister, confirmEmail } from './shared/services/auth.service';
import { ProviderBaseComponent } from './journey/provider/provider-base/provider-base.component';
import { ProviderLoginComponent } from './journey/provider/provider-login/provider-login.component';
import { ProviderRegistrationComponent } from './journey/provider/provider-registration/provider-registration.component';
import { ProviderUsernameForgotComponent } from './journey/provider/provider-username-forgot/provider-username-forgot';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { InvalidRegistrationLinkComponent } from './shared/components/invalid-registration-link/invalid-registration-link.component';
import { ProviderEmailVerifiedComponent } from './journey/provider/provider-email-verified/provider-email-verified.component';
import { ProviderPasswordResetComponent } from './journey/provider/provider-password-reset/provider-password-reset.component';
import { ProviderProfileComponent } from './journey/provider/provider-profile/provider-profile.component';
import { JourneyLogoutComponent } from './journey/journey-logout/journey-logout.component';


const routes: Routes = [
  { path: 'provider', title: 'Provider', component: ProviderBaseComponent, canActivate: [canActivate], data: {type: 'provider'},
    children: [
      {path: 'login', title : 'Provider Login', canActivate: [canJourneyStart],component: ProviderLoginComponent },
      {path: 'register',  title : 'Provider Registration', canActivate: [canJourneyStart,canRegister], data: {type: 'provider'}, component: ProviderRegistrationComponent },
      {path: 'confirm-email',  title : 'Provider Email Verification', canActivate:[confirmEmail], data: {type: 'provider'}, component: ProviderEmailVerifiedComponent },
      {path: 'password', component: ProviderPasswordResetComponent,canActivate: [canJourneyStart]},
      {path: 'username', component: ProviderUsernameForgotComponent,canActivate: [canJourneyStart] },
      {path: 'profile/:profileType', component: ProviderProfileComponent,canActivate: [canJourneyStart] },
      {path: 'logout',component: JourneyLogoutComponent },
      {path: 'invalidRegLink',component: InvalidRegistrationLinkComponent },
      {path: '', pathMatch: "full", redirectTo: "login" }
    ]
  },
  { path: 'unauthorized',component: PageNotFoundComponent },
  { path: '', pathMatch: "full", redirectTo: "provider" },
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
