<div class="container-lg content-wrapper">
    <div class="bg-container">
        <div class="bg-top"></div>
        <div class="bg-bottom"></div>
    </div> 
    <div>
        <dn-progress-spinner *ngIf="showSpinner"></dn-progress-spinner>
        <router-outlet></router-outlet>
    </div>
</div>    
