<div class="mx-auto">
    <div id="wrapper" class="container-lg">
        <app-header></app-header>
        <app-nav></app-nav>
        <div class="d-flex justify-content-center">
            <main id="main-wrapper">
                <global-error></global-error>
                <router-outlet></router-outlet>
            </main>
        </div>
        <app-footer></app-footer>
    </div>
</div>
