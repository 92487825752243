import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { YmlLoaderService } from './shared/services/yml-loader.service';
import { GlobalErrorHandler } from './shared/services/global-error-handler';
import { AppHttpInterceptor } from './shared/services/app-http-interceptor';
import { HttpSessionHandler } from './shared/services/http-session-handler';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JourneyModule } from './journey/journey.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    JourneyModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: YmlLoaderService
      },
      defaultLanguage: 'en'
    }),
    NgbModule
  ],
  exports: [],
  providers: [
    AppHttpInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: HttpSessionHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useExisting: AppHttpInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
