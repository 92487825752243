import r from "../config/index.js";
class n {
  /**
   * Retrieve tokens.
   */
  static async get(t) {
    const e = localStorage.getItem(`${r.get().prefix}-${t}`);
    if (e) try {
      return JSON.parse(e || "");
    } catch {
      throw new Error("Could not parse token object from localStorage");
    }
  }
  /**
   * Saves tokens.
   */
  static async set(t, e) {
    const o = JSON.stringify(e);
    localStorage.setItem(`${r.get().prefix}-${t}`, o);
  }
  /**
   * Removes stored tokens.
   */
  static async remove(t) {
    localStorage.removeItem(`${r.get().prefix}-${t}`);
  }
}
export { n as default };