import o from "./index.js";
class h extends o {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the choice's prompt.
   */
  getPrompt() {
    return this.getOutputByName("prompt", "");
  }
  /**
   * Gets the choice's default answer.
   */
  getDefaultChoice() {
    return this.getOutputByName("defaultChoice", 0);
  }
  /**
   * Gets the choice's possible answers.
   */
  getChoices() {
    return this.getOutputByName("choices", []);
  }
  /**
   * Sets the choice's answer by index position.
   */
  setChoiceIndex(t) {
    const e = this.getChoices().length;
    if (t < 0 || t > e - 1) throw new Error(`${t} is out of bounds`);
    this.setInputValue(t);
  }
  /**
   * Sets the choice's answer by value.
   */
  setChoiceValue(t) {
    const e = this.getChoices().indexOf(t);
    if (e === -1) throw new Error(`"${t}" is not a valid choice`);
    this.setInputValue(e);
  }
}
export { h as default };