import e from "./index.js";
class i extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Get callback's initialization config settings
   */
  getConfig() {
    return {
      envId: this.getOutputByName("envId", ""),
      consoleLogEnabled: this.getOutputByName("consoleLogEnabled", !1),
      deviceAttributesToIgnore: this.getOutputByName("deviceAttributesToIgnore", []),
      customHost: this.getOutputByName("customHost", ""),
      lazyMetadata: this.getOutputByName("lazyMetadata", !1),
      behavioralDataCollection: this.getOutputByName("behavioralDataCollection", !0),
      deviceKeyRsyncIntervals: this.getOutputByName("deviceKeyRsyncIntervals", 14),
      enableTrust: this.getOutputByName("enableTrust", !1),
      disableTags: this.getOutputByName("disableTags", !1),
      disableHub: this.getOutputByName("disableHub", !1)
    };
  }
  setClientError(t) {
    this.setInputValue(t, /clientError/);
  }
}
export { i as default };