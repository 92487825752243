import r from "../config/index.js";
class t {
  static enabled() {
    const {
      logLevel: o
    } = r.get();
    return {
      none: 0,
      error: 25,
      warn: 50,
      info: 75,
      debug: 100
    }[o];
  }
  static info(...o) {
    const {
      logger: e
    } = r.get();
    this.enabled() >= 50 && (e && e.info ? e.info(...o) : console.info(...o));
  }
  static warn(...o) {
    const {
      logger: e
    } = r.get();
    this.enabled() >= 50 && (e && e.warn ? e.warn(...o) : console.warn(...o));
  }
  static error(...o) {
    const {
      logger: e
    } = r.get();
    this.enabled() >= 25 && (e && e.error ? e.error(...o) : console.error(...o));
  }
  static log(...o) {
    const {
      logger: e
    } = r.get();
    this.enabled() >= 75 && (e && e.log ? e.log(...o) : console.log(...o));
  }
}
export { t as FRLogger };