import { FRLogger as t } from "../util/logger.js";
import s from "../oauth2-client/index.js";
import i from "../session-manager/index.js";
import c from "../token-manager/index.js";
import l from "../token-storage/index.js";
class k {
  /**
   * Logs the user in with the specified step handler, acquires OAuth tokens, and retrieves
   * user profile.  **Currently not implemented.**
   *
   * @typeparam T The type of user object expected
   * @param handler The function to invoke when handling authentication steps
   * @param options Configuration overrides
   */
  static async login(e, o) {
    throw t.info(e, o), new Error("FRUser.login() not implemented");
  }
  /**
   * Ends the user's session and revokes OAuth tokens.
   *
   * @param options Configuration overrides
   */
  static async logout(e) {
    const o = {
      ...e
    };
    delete o.redirect;
    try {
      await i.logout(o);
    } catch {
      t.warn("Session logout was not successful");
    }
    try {
      await s.revokeToken(o);
    } catch {
      t.warn("OAuth revokeToken was not successful");
    }
    const n = await l.get(),
      {
        idToken: a = ""
      } = n || {};
    await c.deleteTokens();
    try {
      await s.endSession({
        ...e,
        idToken: a
      });
    } catch {
      t.warn("OAuth endSession was not successful");
    }
  }
}
export { k as default };