import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { NgModel } from '@angular/forms';
import { PasswordErrors } from '../../directives/valid-password.directive';

@Component({
  selector: 'password-rules',
  templateUrl: './password-rules.component.html'
})
export class PasswordRulesComponent implements OnInit {
  @Input() showRules:boolean = false;
  @Input() ctrl !: NgModel;

  hasErrors = false;

  passwordMinLen = environment.common.constants.passwordMinLength;
 
  ruleList = [  {rule:  'minlength', initialStatus: true}, 
                {rule: PasswordErrors.atLeastOneCharSet, initialStatus: true},
                {rule: PasswordErrors.atLeastOneNumSpecChr, initialStatus: true},
                {rule: PasswordErrors.containsPII, initialStatus: false},
                {rule: PasswordErrors.consecutiveCharsOrNums, initialStatus: false},
                {rule: PasswordErrors.moreThan5NumbersNextToEachOther, initialStatus: false},
                {rule: PasswordErrors.containsRepeatingCharNum, initialStatus: false},
                {rule: PasswordErrors.containsSpecialWords, initialStatus: false} ];       
  
  rules: any[] = [];

  constructor() {}

  ngOnInit(): void {


    this.ruleList.forEach((r)=>{
  
      let rule = {rule: r.rule, 
                  text: this.getRuleText(r.rule ),
                  error: r.initialStatus};
      this.rules.push(rule);
    });

    this.ctrl.valueChanges?.subscribe((val) => {
        this.hasErrors = this.shouldShowRules();
        if (this.hasErrors) {
          let errors = this.ctrl.errors;
          this.rules.forEach((r) => {
            if (this.ctrl.hasError(r.rule)) {
                  r.error = true;
            }else{
                  r.error = false; 
            }
          })
        }  
     });
  }

  private shouldShowRules():boolean {
    return (this.ctrl?.errors && !(this.ctrl?.errors['required'])) ? true : false;
  } 

  private getRuleText(rule:string) {
    let minLenText = "Must be at least "+ this.passwordMinLen + " characters long.";
    let text = rule == 'minlength' ? minLenText : environment.common.errorMessages[rule as keyof {}];
    text = text?.trim();
    if (text?.toLowerCase().startsWith('password')) {
      text = text.substring(8)?.trim();
    }
    return text;
  }
}
