import { HttpStatusCode } from "@angular/common/http";


export const commonEnvironment = {
    errorMessages: {
        required: 'This is a required field',
        minlength: 'The value entered is too small',
        min: 'Please enter a valid number. The value entered is too small',
        max: 'The value entered is too large',
        maxlength: 'The value entered is too large',
        date: 'Please enter a valid date',
        uniqueUserId: 'This User Id is not available',
        email: 'This email address is invalid',
        pattern: 'Please match the requested format. ',
        phoneFormat: 'Please enter a valid Phone Number (e.g. 123-456-7890)',
        maxValue: 'Value exceeds the allowed maximum',
        atLeastOneCharSet: 'Password must contain at least one lowercase and an uppercase letter.',
        atLeastOneNumSpecChr: 'Password must contain at least one number and a special character.',
        at_least_X_character_types: 'Password must contain at least one number and a special character.',
        consecutiveCharsOrNums:          'Password must not contain a sequence of three or more numbers or letters (e.g. 123, abc).',
        seq_chars_not_allowed:  'Password must not contain a sequence of three or more numbers or letters (e.g. 123, abc).',
        moreThan5NumbersNextToEachOther: 'Password must not contain more than five numbers next to each other.',
        consecutive_digits_not_allowed: 'Password must not contain more than five numbers next to each other.',
        containsSpecialWords:            'Password must not contain any reserved words. See the tooltip for a full list.',
        month_abbreviations:  'Password must not contain any reserved words. See the tooltip for a full list.',
        week_day_abbreviations: 'Password must not contain any reserved words. See the tooltip for a full list.',
        blacklisted_terms: 'Password must not contain any reserved words. See the tooltip for a full list.',
        containsRepeatingCharNum:        'Password must not contain more than two repeating letters or numbers, like "111" or "aaa".',
        repeated_chars_not_allowed:      'Password must not contain more than two repeating letters or numbers, like "111" or "aaa".',
        containsPII: 'Password must not contain your first name, last name or username.',
        cannot_contain_others: 'Password contains forbidden characters. Please try another password.',
        chars_not_allowed: 'Password contains forbidden characters.',
        passwordPolicy: 'There was an error processing your password change request. Please verify that your new password does not match one of your previous passwords.',
        is_new: 'You used this password recently. Please try another password.',
        userNameAlphanumericOnly: 'Username should only contain alpha numeric characters.',
        userNameShouldNotMatch: 'Username already exists, please choose a different user name.',
        loginFailure401: "You have entered an invalid Username or Password. Please try again. Please note your account will be frozen for 30 minutes after 3 tries.",
        accountRevoked: "Your account is not active. Please contact customer service for help.",
        accountInactive: "Your account is not active. Please contact customer service for help.",
        accountLocked: "Your account has been locked. Please try again after 30 minutes.",
        emailNotVerified: "Your email has not been verified.",
        loginFailureUnknown: "We encountered an issue completing your request. Please contact customer service.",
        invalidCredential: "Invalid Credentials. Please retry.",
        invalidUsername: "Please enter a valid username. If you have received an email for verification, please complete the verification before you retry.",
        invalidOtp: "The one-time verification code is incorrect.",
        identityAnswer: "The value you entered is incorrect. Please retry. ",
        invalidMonthDate:'Invalid month or date',
        invalidMonth:'Invalid month',
        invalidDate:'Invalid date',
        uniqueEmail: 'This email is already registered',
        uniqueUsername: 'Username already exists, please choose a different user name.',
        uniqueValue: 'Value already exists, please try a different value.',
        createUser: "We encountered an issue completing your request. Please contact customer service.",
      },
    fr: {
      redirectDelay: 5000,
      knownErrorCodes : ['401'],
      knownMessages: [
        {id:'accountRevoked', message: 'account has been revoked'},
        {id:'accountInactive', message: 'Account is inactive'},
        {id:'accountLocked' , message:  'User Locked Out'},
        {id:'emailNotVerified',message: 'email not verified'},
        {id:'invalidCredential',message: 'Invalid Credentials'},
        {id:'invalidOtp',message: 'Invalid Otp'},
        {id:'invalidUsername',message: 'Invalid username'},
        {id:'identityAnswer',message: 'Invalid identity'},
        {id: 'passwordPolicy',message: 'Invalid password'},
        {id:'uniqueEmail', message:'Dearborn_Uniqueness: false'}
      ],
      passwordPrompts: [
        {stage: 'OTP', prompt: 'One Time Password', name: undefined , value: undefined},
        {stage: 'CURRENT_PASSWORD', prompt: '#Password#', name: undefined , value: undefined}
      ],
      messages: [
        {stage: 'AUTO_SUBMIT', prompt: '401: AccountInActive', dnMessage: 'Account is inactive'},
        {stage: 'EMAIL_SENT',  prompt: 'emailVerifyLinkSent'} ,
        {stage: 'EMAIL_SENT',  prompt: 'emailVerificationLink Sent'},
        {stage: 'EMAIL_SENT',  prompt: 'email sent with the required information'},
      ],
      textPrompts: [
        {stage: 'VERIFIED_EMAIL', prompt: 'Email'},
        {stage: 'USER_IDENTITY', prompt: '#DoB (mm/dd)#'},
        {stage: 'TOU', prompt: '#TOU#'},
        {stage: 'TOKEN', prompt: '#token#'}  ,
        {stage: 'APPNAME', prompt: '#clientId#'},
        {stage: 'CORPCODE',prompt: '#corpCode#'},
        {stage: 'VERIFIED_EMAIL',prompt: '#businessEmail#'},
        {stage: 'VERIFIED_EMAIL',prompt: '#mail#'},
        {stage: 'USERNAME',prompt: '#username#'},
        {stage: 'USERNAME',prompt: 'User Name'},
        {stage: 'PASSWORD',prompt: '#password#'}
      ],
      choicePrompts: [
        { stage: 'LOGIN_ERROR', prompt: 'Invalid Credentials', name: 'choices', value:['true','false']},
        { stage: 'OTP_METHOD', prompt: 'OTP Method', name: 'choices', value: ['Email','Mobile']},
        { stage: 'OTP_RESEND',  prompt: 'The one-time verification code is incorrect.', name: 'choices', value: ['Resend','Reject']},
        { stage: 'AUTO_SUBMIT',  prompt: 'invalid DoB', name: 'choices', value: ["true","false"]},
        { stage: 'AUTO_SUBMIT', prompt: 'retry_password', name: 'choices', value:['true','false']},
      ],
      journeys:
        {  registration : 'DearbornRegistration'}  ,
      endpoints: {
            verifyEmail: "/verify-email",
        },
      slResponseCodes: {
        emailVerified: HttpStatusCode.NoContent
      }
    },
    constants: {
        passwordMinLength: 8,
        passwordMaxLength: 40,
        userNameMinLength: 7,
        userNameMaxLength: 100
      }
}
