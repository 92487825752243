import a from "../config/index.js";
import { FRLogger as n } from "../util/logger.js";
import i from "./local-storage.js";
import o from "./session-storage.js";
class d {
  /**
   * Gets stored tokens.
   */
  static async get() {
    const {
      clientId: t,
      tokenStore: e
    } = this.getClientConfig();
    if (e === "sessionStorage") return await o.get(t);
    if (e === "localStorage") return await i.get(t);
    if (e === "indexedDB") n.warn("IndexedDB is not supported in this version.");else if (e && e.get) return await e.get(t);
    return await i.get(t);
  }
  /**
   * Saves tokens.
   */
  static async set(t) {
    const {
      clientId: e,
      tokenStore: r
    } = this.getClientConfig();
    if (r === "sessionStorage") return await o.set(e, t);
    if (r === "localStorage") return await i.set(e, t);
    if (r === "indexedDB") n.warn("IndexedDB is not supported in this version.");else if (r && r.set) return await r.set(e, t);
    return await i.set(e, t);
  }
  /**
   * Removes stored tokens.
   */
  static async remove() {
    const {
      clientId: t,
      tokenStore: e
    } = this.getClientConfig();
    if (e === "sessionStorage") return await o.remove(t);
    if (e === "localStorage") return await i.remove(t);
    if (e === "indexedDB") n.warn("IndexedDB is not supported in this version.");else if (e && e.remove) return await e.remove(t);
    return await i.remove(t);
  }
  static getClientConfig() {
    const {
      clientId: t = "unconfiguredClient",
      tokenStore: e = "localStorage"
    } = a.get();
    return {
      clientId: t,
      tokenStore: e
    };
  }
}
export { d as default };