import { HostListener, Input, Optional } from "@angular/core";
import { Directive, OnInit, OnDestroy, Host, ViewContainerRef, EmbeddedViewRef, Renderer2, AfterViewInit } from "@angular/core";
import { FormControl, FormControlName, FormGroup, NgControl, ValidationErrors } from "@angular/forms";
import { Subscription } from "rxjs";
import { DnErrorMessageService } from "../dn-error-message.service";
import { DnErrorControlDirective } from "./dn-error-control.directive";
import { DnErrorTargetDirective } from "./dn-error-target.directive";


  @Directive({
    selector: '[dnDisplayErrors]'  
  })
  export class DnDisplayErrorsDirective implements OnInit, OnDestroy, AfterViewInit {    
    @Input() errorMessages: ValidationErrors = {};
    @Input() errorTarget !: DnErrorTargetDirective ; 

    private _errorTargetViewRef : EmbeddedViewRef<any> | undefined;
    private _errorTarget !: ViewContainerRef;
    private _errorBlockId !: string; 
    private _errorControl !: NgControl | FormGroup;
    private _subscription !: Subscription | undefined;
    private _hasErrorClass !: string[];    
    private radioRefClass !: string; 

    constructor(@Host() private dnErrorContol: DnErrorControlDirective,           
                private dnErrorMessageService: DnErrorMessageService,                
                @Optional() private ngControl: NgControl,                
                private _viewContainerRef: ViewContainerRef,
                private renderer: Renderer2 ,
                @Optional() private formControlName: FormControlName) {
    }

    ngOnInit(): void { 
        this.setErrorBlockId();         
    }

    ngAfterViewInit(): void {
        this._errorTarget = this.errorTarget?.viewContainerRef || this._viewContainerRef;            
        if (this.ngControl) {
            if (this.errorTarget?.viewContainerRef && this.ngControl.control) {
               this.dnErrorContol.registerErrorTarget(this.ngControl.control)
            }
            this._errorControl = this.ngControl ;
            let name = (this.ngControl instanceof FormControlName) ? (<FormControlName>this.ngControl).name : this.hostElement.getAttribute("name");
            if (name && this.isRadioControl()) {
                this.dnErrorContol.trackRadioControls(""+name,this._errorBlockId)
            }
        }else {
            this._errorControl = this.dnErrorContol.parent?.form;
        }               
        if ( this._errorControl ) {            
            this._subscription = this._errorControl.valueChanges?.subscribe(()=> {                     
                this.displayErrors();                 
            });
            this.dnErrorContol.onDisplayErrors.subscribe(() => {
                this.displayErrors();
            }); 
        }             
    }
      
    @HostListener('window:beforeunload')  
    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
        this.dnErrorContol.onDisplayErrors.unsubscribe();        
        this.clearTargetView(); 
        if (this.ngControl?.control) {
            this.dnErrorContol.unregisterErrorTarget(this.ngControl.control) 
        }  
    }

    private displayErrors() {
    
        this.clearTargetView();          
        let viewContext = this.getViewContext();        
        if (viewContext.hasError) {            
            this._hasErrorClass = viewContext.hasError;          
            if (this.shouldEmbedView()) {
                this._errorTargetViewRef = this._errorTarget.createEmbeddedView(viewContext.errorTemplate,{errorContext: viewContext.context});
            } 
            this.addRemoveAreaDescBy(true);
            this._hasErrorClass.forEach((_class) => {this.renderer.addClass(this.hostElement,_class)});             
        }
    }

    private shouldEmbedView() {
        if (this.isRadioControl()) {
           return  this.controlName && 
                    this.dnErrorContol.getRadioErrorBlockId(this.controlName) == this._errorBlockId   
        }
        return true;
    }

    private getViewContext() {
        let classes = this.dnErrorMessageService.helperClasses();         
        let errors = this.showErrors();
        let errorTexts = errors ? this.errorTexts(errors) : undefined; 
        return {context: {
                    id: this._errorBlockId,
                    class: this._errorControl instanceof FormGroup ? classes.alerts : classes.errorText,
                    messages: errorTexts
                },
                errorTemplate :  this._errorControl instanceof NgControl ? this.dnErrorMessageService.fieldTemplate() : this.dnErrorMessageService.formTemplate(),
                hasError : errors ? classes.hasError : undefined
            }
    }

    private errorTexts(errors:string[]):string[] {
        let messages:Set<string> = new Set<string>();      
        errors.forEach(error => {                        
            let message = this.errorMessages[error] || this.dnErrorMessageService.errorMessage(error)
            if (message) {
                messages.add(message)
            }             
        });
        return Array.from(messages)
    }

    private showErrors():string[] | undefined {
        let tokens = this.dnErrorContol.tokens
        let validationErrors = this.getValidationErrors();
        let errors = tokens &&  validationErrors ? Object.keys( validationErrors) : undefined;
        if (errors && tokens && tokens.length > 0) {            
            errors = errors.filter(k => tokens?.includes(k));
        }
        return errors && errors.length > 0 ? errors: undefined;
    }

    private getValidationErrors() {
       let validationErrors = this._errorControl?.errors;
       if (this._errorControl instanceof FormGroup && this._errorControl.controls) {
           let controls = this._errorControl.controls;
            Object.keys(controls).forEach((key:any) => {
                if (controls[key] && this.dnErrorContol.dnErrorTargetControls.indexOf(controls[key]) == -1) {
                    validationErrors = {...validationErrors,...controls[key].errors}
                }
            });
       }
       return validationErrors;
    }
    
    private get hostElement():HTMLElement {        
        return this._viewContainerRef.element.nativeElement;
    }
    
    private setErrorBlockId() {
        let name = this.hostElement.id || this.hostElement.getAttribute("name") || undefined        
        this._errorBlockId = 'dn-model-'+  name  + '-' +  new Date().getUTCMilliseconds() + "-" + 'error';
    }

    private isRadioControl() {
        return (this.hostElement instanceof HTMLInputElement && (<HTMLInputElement>this.hostElement).type == 'radio') ;
    }


    private addRemoveAreaDescBy(add:boolean) { //For bootstrap
        let areaDescBy:Set<string> = new Set<string>((this.hostElement.getAttribute("aria-describedby") || " ").split(" "));
        (add) ? areaDescBy.add(this._errorBlockId) : areaDescBy.delete(this._errorBlockId);        
        this.renderer.setAttribute(this.hostElement,"aria-describedby",Array.from(areaDescBy).join(" "));    
    }

    private clearTargetView() {
       if (this._errorTargetViewRef && (this._errorTarget?.indexOf(this._errorTargetViewRef) > -1)) {
            this._errorTarget.remove(this._errorTarget.indexOf(this._errorTargetViewRef));
       }
       this._hasErrorClass?.forEach((_class) => {this.renderer.removeClass(this.hostElement,_class);})     
       this.addRemoveAreaDescBy(false); 
    }

    get controlName() {
        let name = (this.ngControl instanceof FormControlName) ? (<FormControlName>this.ngControl).name : this.hostElement.getAttribute("name");
        return name ? ""+name : undefined;
    }
}