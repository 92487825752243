import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { ControlContainer, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppFormsService } from '../app-forms.service';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { Stage } from '../../shared/services/forge-rock.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'input-otp',
  templateUrl: './input-otp.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class InputOtpComponent  extends AbstractErrorControlComponent implements OnInit, AfterViewInit {
  @Input() name = 'onetimePassword';
  @Input() otpInstruction !: string;
  @Input() autofocus = false;
  @Input() otpMethod !: string;
  @Input() otpMaskedValue !: string;
  @Input() disabled = false;
  @Input() stage !: Stage;
  @Input() parentUrl !: string;

  @Output() OnResendCode = new EventEmitter<any>();

  onetimePassword !: string | undefined;
  otpText !: string;

  private resendChoice !: any;

  constructor(private translate: TranslateService,
              override formsService: AppFormsService,
              private FRService: ForgeRockService,
              private route: Router
  ){
    super(formsService)
  }

  @HostListener('window:popstate',['$event'])
  popState(e:PopStateEvent) {
    window.location.reload();
  }

  ngOnInit(): void {
    this.onetimePassword = undefined;
    this.otpMethod = this.otpMethod.trim();
    this.translate.get(this.otpInstruction,{otpMethod: this.otpMethod.toLowerCase(), maskedValue: this.otpMaskedValue}).subscribe((text)=>{
      this.otpText = text;
    }); 
  }
  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (history.state != this.parentUrl) {
      history.pushState(null,'',this.parentUrl);
    }
  }

  resendCode(e:Event) {
    e.preventDefault();
    this.onetimePassword = undefined;
    this.OnResendCode.emit('resend');
  }

  protected get Stage() {
    return Stage;
  }


}
