import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { load } from 'js-yaml';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class YmlLoaderService implements TranslateLoader {
  private labelUrlBase = "assets/labels";
  private labelRequests :Observable<any>[] = [] ;
  
  private cache !: any;
  
  constructor(private httpClient: HttpClient,
              @Inject(DOCUMENT) private document: Document) { 
    this.buildHttpRequests();
  }

  getTranslation(lang: string): Observable<any> {
    return this.getLabels();
  }

  private loadYml() {
    return forkJoin(this.labelRequests)
      .pipe(
        catchError(err => of(err)),
        map( (resposes) => {   
          resposes.forEach((resp: string) => {
            let properties:any = load(resp ? resp : "");
            this.cache = {...this.cache,...properties}
          })     
          return this.cache;
        })
    );
  }

  private getLabels() {
    if (!this.cache) {
        return this.loadYml();       
    }  
    return of(this.cache);
  }

  private getLabelFilenames() {
    let hostname = this.document.location.hostname;
    let labelnames = [environment.labels.common];
    Object.keys(environment.labels).forEach( (k) => {
        if (hostname.includes(k)) {
          labelnames.push(environment.labels[k as keyof{}])
        };
    });
    return labelnames;
  }

  private buildHttpRequests(): void {
    let filenames = this.getLabelFilenames();
    filenames.forEach(filename => {
      let httpRequest =  this.httpClient.get(`${this.labelUrlBase}/${filename}`, {
                              observe: "body",
                              responseType: "text"
                      }).pipe(
                          catchError(err => {
                            console.log("Unable to load labels => " + err);
                            return of()
                      }));
      this.labelRequests.push(httpRequest);
    })  
  }
}

