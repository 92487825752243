import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../../shared/services/forge-rock.interface';

@Component({
  selector: 'provider-username-forgot',
  templateUrl: './provider-username-forgot.component.html'
})
export class ProviderUsernameForgotComponent implements OnInit {

  action !: string | null;
  headerText : string = 'Recover Username';


  user !: UserModel;
  editable = false;
  autofocus = true;

  recovering = true;
  translationData:any = {}

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.action = this.route.snapshot.paramMap.get('action');
  }

  onStepComplete(data:any) {
    //let userType = this.route.parent?.snapshot.data['type'];
    this.translationData = {emailAddr: data.unverifiedEmail,
                            username: "",
                            loginRedirect: data.loginRedirect,
                            hideEmail: 'd-none'}

    this.recovering = false;
  }
}
