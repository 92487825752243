import a from "./index.js";
import { CallbackType as r } from "../../auth/enums.js";
import t from "./attribute-input-callback.js";
import n from "./choice-callback.js";
import o from "./confirmation-callback.js";
import i from "./device-profile-callback.js";
import l from "./hidden-value-callback.js";
import C from "./kba-create-callback.js";
import m from "./metadata-callback.js";
import u from "./name-callback.js";
import p from "./password-callback.js";
import s from "./ping-protect-evaluation-callback.js";
import c from "./ping-protect-initialize-callback.js";
import d from "./polling-wait-callback.js";
import b from "./recaptcha-callback.js";
import f from "./recaptcha-enterprise-callback.js";
import w from "./redirect-callback.js";
import k from "./select-idp-callback.js";
import P from "./suspended-text-output-callback.js";
import I from "./terms-and-conditions-callback.js";
import T from "./text-input-callback.js";
import O from "./text-output-callback.js";
import g from "./validated-create-password-callback.js";
import h from "./validated-create-username-callback.js";
function X(e) {
  switch (e.type) {
    case r.BooleanAttributeInputCallback:
      return new t(e);
    case r.ChoiceCallback:
      return new n(e);
    case r.ConfirmationCallback:
      return new o(e);
    case r.DeviceProfileCallback:
      return new i(e);
    case r.HiddenValueCallback:
      return new l(e);
    case r.KbaCreateCallback:
      return new C(e);
    case r.MetadataCallback:
      return new m(e);
    case r.NameCallback:
      return new u(e);
    case r.NumberAttributeInputCallback:
      return new t(e);
    case r.PasswordCallback:
      return new p(e);
    case r.PingOneProtectEvaluationCallback:
      return new s(e);
    case r.PingOneProtectInitializeCallback:
      return new c(e);
    case r.PollingWaitCallback:
      return new d(e);
    case r.ReCaptchaCallback:
      return new b(e);
    case r.ReCaptchaEnterpriseCallback:
      return new f(e);
    case r.RedirectCallback:
      return new w(e);
    case r.SelectIdPCallback:
      return new k(e);
    case r.StringAttributeInputCallback:
      return new t(e);
    case r.SuspendedTextOutputCallback:
      return new P(e);
    case r.TermsAndConditionsCallback:
      return new I(e);
    case r.TextInputCallback:
      return new T(e);
    case r.TextOutputCallback:
      return new O(e);
    case r.ValidatedCreatePasswordCallback:
      return new g(e);
    case r.ValidatedCreateUsernameCallback:
      return new h(e);
    default:
      return new a(e);
  }
}
export { X as default };