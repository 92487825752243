<dn-progress-spinner *ngIf="showspinner"></dn-progress-spinner>

<div dnErrorControl="userEmail" #errorControl="dnErrorControl">
  <div class="mt-3 form-group">
      <label for="userEmail">Email</label>
      <div>
      <input type="email" class="form-control disable-click"
      [id]="name" [name]="name" placeholder="example@domain.com"
          [readonly]="_editable != true"
          [ngClass]="{'readOnlyColor': _editable != true, 'disable-click' : _editable != true }"
          [(ngModel)]="_userEmail" [autofocus]="autofocus && _editable==true" #emailCtrl="ngModel"
          required [uniqueValue]="_isUnique"
          pattern=".+@.+\..+$"
          dnDisplayErrors
          [errorMessages]="{pattern: 'Please enter valid email address.',
                            uniqueEmail: 'This email is already registered.',
                            uniqueValue: 'This email is already registered.'
          }">
          <p class="mt-1 d-flex invalid-feedback" *ngIf="emailCtrl.hasError('uniqueEmail')">
            Please change the email or&nbsp;<a [href]="loginRedirect" class="link" title="Click here to access portal" (click)="showspinner=true">click here to login.</a>
          </p>
  </div>
</div>

  <div class="mt-3 form-group" *ngIf="showConfirmEmail==true && isVerifyEmail==true">
    <label for="userEmail">Confirm Email</label>
    <div>
      <input type="email" class="form-control readOnlyColor disable-click"
      id="confirmEmail" name="confirmEmail"
        placeholder="Enter confirm email"
        [ngClass]="{'readOnlyColor': _editable != true, 'disable-click' : _editable != true }" [(ngModel)]="confirmEmail"
        required pattern=".+@.+\..+$" dnDisplayErrors [errorTarget]="errTargetConfirmEmail"
        [textMatch]="true" [sourceTextCtrl]="emailCtrl"
        [errorMessages]="{pattern: 'Please enter valid email address.',
                              textMatch: 'Email addresses do not match'
            }">
      <div dnErrorTarget #errTargetConfirmEmail="dnErrorTarget"></div>
    </div>
  </div>
</div>
