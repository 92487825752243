import m from "../config/index.js";
import { ActionTypes as l } from "../config/enums.js";
import { X_REQUESTED_PLATFORM as c, REQUESTED_WITH as f } from "../shared/constants.js";
import { withTimeout as y } from "../util/timeout.js";
import { stringify as g, getEndpointPath as R, resolve as q } from "../util/url.js";
import P from "../util/middleware.js";
class J {
  /**
   * Gets the next step in the authentication tree.
   *
   * @param {Step} previousStep The previous step, including any required input.
   * @param {StepOptions} options Configuration default overrides.
   * @return {Step} The next step in the authentication tree.
   */
  static async next(t, e) {
    const {
        middleware: r,
        platformHeader: n,
        realmPath: u,
        serverConfig: i,
        tree: o,
        type: a
      } = m.get(e),
      d = e ? e.query : {},
      p = this.constructUrl(i, u, o, d),
      s = P({
        url: new URL(p),
        init: this.configureRequest(t)
      }, {
        type: t ? l.Authenticate : l.StartAuthenticate,
        payload: {
          tree: o,
          type: a || "service"
        }
      })(r);
    n && (s.init.headers instanceof Headers ? s.init.headers.set("X-Requested-Platform", c) : Array.isArray(s.init.headers) ? s.init.headers.push(["X-Requested-Platform", c]) : s.init.headers ? s.init.headers["X-Requested-Platform"] = c : s.init.headers = {
      "X-Requested-Platform": c
    });
    const h = await y(fetch(s.url.toString(), s.init), i.timeout);
    return await this.getResponseJson(h);
  }
  static constructUrl(t, e, r, n) {
    const i = {
        ...n,
        ...(r ? {
          authIndexType: "service",
          authIndexValue: r
        } : void 0)
      },
      o = Object.keys(i).length > 0 ? `?${g(i)}` : "",
      a = R("authenticate", e, t.paths);
    return q(t.baseUrl, `${a}${o}`);
  }
  static configureRequest(t) {
    return {
      body: t ? JSON.stringify(t) : void 0,
      credentials: "include",
      headers: new Headers({
        Accept: "application/json",
        "Accept-API-Version": "protocol=1.0,resource=2.1",
        "Content-Type": "application/json",
        "X-Requested-With": f
      }),
      method: "POST"
    };
  }
  static async getResponseJson(t) {
    const e = t.headers.get("content-type"),
      n = e && e.indexOf("application/json") > -1 ? await t.json() : {};
    return n.status = t.status, n.ok = t.ok, n;
  }
}
export { J as default };