import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { ForgeRockService } from './forge-rock.service';
import { UserService } from './user.service';
import { EmailConfirmStatus } from './forge-rock.interface';
 
export const canActivate: CanActivateFn = ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.canActivate(next, state).pipe(
          map((data) => {
              return data;
          }),
          catchError((e) => {
              router.navigate(['unauthorized']);
              return of(false);
          }
      )
  );
}

export const canJourneyStart: CanActivateFn = ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.canJourneyStart(next, state);
}


export const canRegister: CanActivateFn = ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  let userType = next.data['type'];
  return authService.canRegister(next, state).pipe(
          map((data) => {
              return data;
          }),
          catchError((e) => {
              router.navigate([`${userType}/invalidRegLink`]);
              return of(false);
          }
      )
  );
}

export const confirmEmail: CanActivateFn = ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  let userType = next.data['type'];
  return authService.verifyCode(next, state).pipe(
          map((resp) => {
              if (resp != undefined) {
                let url = `${userType}/confirm-email`
                router.navigate([url],{skipLocationChange:true,queryParams:{ status: resp}})
                return false;  
              };
              return true;
          }),
          catchError((e) => {
              router.navigate([`${userType}/invalidRegLink`]);
              return of(false);
          }
      )
  );
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private FRService: ForgeRockService,
              private http: HttpClient,
              private userService: UserService
  ) { }

  public canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> { 
    let userType = next.data['type'];
    return this.FRService.loadConfig(userType).pipe(
      map(resp => {
        return true;
      })
    );
  }

  public canJourneyStart(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.userService.resetUser();
    return true;
  }

  public canRegister( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> {
    let userType = next.data['type'];
    const data = next.queryParams['data'];
    let info = {token:data,userType:userType};
    return this.userService.info(info).pipe(
      map((val=>{
        if (val && Object.keys(val).length > 0) { 
          this.userService.touAlreadyAccepted();
        }
        return val;
      })
    ));
  }

  public verifyCode( next: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<any> {
    const userName = next.queryParams['userName'];
    const verificationCode = next.queryParams['verificationCode'];  
    const appName = next.queryParams['appName'];  
    if (userName && verificationCode && appName) {
      return this.FRService.verifyEmailCode({userName:userName,
                                      verificationCode:verificationCode,
                                      corpCode: next.queryParams['corpCode'],
                                      appName: appName},next.data['type']).pipe(
        map(resp =>{
          return resp ? EmailConfirmStatus.EMAIL_VERIFIED_SUCCESS : EmailConfirmStatus.EMAIL_VERIFIED_FAILED;
        }));
    }
    return of(undefined);
  }
}
