import m from "../config/index.js";
import { FRLogger as g } from "../util/logger.js";
import u, { allowedErrors as t } from "../oauth2-client/index.js";
import d from "../token-storage/index.js";
import { getStoredAuthUrlValues as w, generateAndStoreAuthUrlValues as k } from "../oauth2-client/state-pkce.js";
import { parseQuery as y } from "../util/url.js";
import { tokensWillExpireWithinThreshold as E } from "./helpers.js";
import { ResponseType as q } from "../oauth2-client/enums.js";
class f {
  /**
     * Token Manager class that provides high-level abstraction for Authorization Code flow,
     * PKCE value generation, token exchange and token storage.
     *
     * Supports both embedded authentication as well as external authentication via redirects
     *
     Example 1:
  
     ```js
     const tokens = forgerock.TokenManager.getTokens({
       forceRenew: true, // If you want to get new tokens, despite existing ones
       login: 'embedded', // If user authentication is handled in-app
       serverConfig: {
         timeout: 5000,
       },
     });
     ```
  
     Example 2:
  
     ```js
     const tokens = forgerock.TokenManager.getTokens({
       forceRenew: false, // Will immediately return stored tokens, if they exist
       login: 'redirect', // If user authentication is handled in external Web app
     });
     ```
  
     Example 3:
  
     ```js
     const tokens = forgerock.TokenManager.getTokens({
       query: {
         code: 'lFJQYdoQG1u7nUm8 ... ', // Authorization code from redirect URL
         state: 'MTY2NDkxNTQ2Nde3D ... ', // State from redirect URL
       },
     });
     ```
     */
  static async getTokens(r) {
    const {
      clientId: a,
      oauthThreshold: h,
      prefix: c
    } = m.get(r);
    if (!a) throw new Error("Client ID is required");
    const i = await d.get();
    if (i && !r?.forceRenew && !r?.query?.code && !E(h, i.tokenExpiry)) return i;
    if (i) try {
      await u.revokeToken(r), await f.deleteTokens();
    } catch (e) {
      g.warn("Existing tokens could not be revoked or deleted", e);
    }
    if (r?.query?.code && r?.query?.state) {
      const {
        state: e,
        verifier: n
      } = w(a, c);
      if (e === void 0 || n === void 0) throw new Error("`state` and/or `verifier` not found in sessionStorage. Debugging: sessionStorage is not accessible in separate tabs.");
      return await this.tokenExchange(r, {
        state: e,
        verifier: n
      });
    }
    const o = Object.assign({}, r);
    delete o.forceRenew;
    const [s, l] = k({
      ...o,
      clientId: a,
      prefix: c,
      responseType: q.Code
    });
    try {
      const e = new URL(await u.getAuthCodeByIframe(s));
      if (e.searchParams.get("error")) throw Error(`${e.searchParams.get("error_description")}`);
      if (!e.searchParams.get("code")) throw Error(t.AuthenticationConsentRequired);
      const n = y(e.toString());
      r || (r = {}), r.query = n;
    } catch (e) {
      if (!(e instanceof Error) || r?.login !== "redirect" || t.AuthenticationIsRequired !== e.message && t.AuthenticationConsentRequired !== e.message && t.AuthorizationTimeout !== e.message && t.FailedToFetch !== e.message && t.NetworkError !== e.message && t.InteractionNotAllowed !== e.message && t.RequestRequiresConsent !== e.message &&
      // Check for Ping Identity Login Required error
      // Long message, so just check substring
      !e.message.includes(t.LoginRequired) &&
      // Safari has a very long error message, so we check for a substring
      !e.message.includes(t.CORSError)) throw e;
      const n = await u.createAuthorizeUrl(s);
      return l(), location.assign(n);
    }
    return await this.tokenExchange(r, {
      state: s.state,
      verifier: s.verifier
    });
  }
  static async deleteTokens() {
    await d.remove();
  }
  static async tokenExchange(r, a) {
    if (r.query?.state !== a.state) throw new Error("State mismatch");
    if (!r.query?.code || Array.isArray(r.query?.code)) throw new Error("Failed to acquire authorization code");
    const h = r.query?.code,
      c = a.verifier,
      i = {
        ...r,
        authorizationCode: h,
        verifier: c
      },
      o = await u.getOAuth2Tokens(i);
    if (!o || !o.accessToken) throw new Error("Unable to exchange authorization for tokens");
    try {
      await d.set(o);
    } catch (s) {
      g.error("Failed to store tokens", s);
    }
    return o;
  }
}
export { f as default };