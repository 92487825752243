import t from "./index.js";
class i extends t {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    super(e), this.payload = e;
  }
  /**
   * Gets the callback's data.
   */
  getMessage() {
    return this.getOutputByName("message", "");
  }
  /**
   * Does callback require metadata?
   */
  isMetadataRequired() {
    return this.getOutputByName("metadata", !1);
  }
  /**
   * Does callback require location data?
   */
  isLocationRequired() {
    return this.getOutputByName("location", !1);
  }
  /**
   * Sets the profile.
   */
  setProfile(e) {
    this.setInputValue(JSON.stringify(e));
  }
}
export { i as default };