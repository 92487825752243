import c from "./callbacks/factory.js";
import { StepType as r } from "./enums.js";
class i {
  /**
   * @param payload The raw payload returned by OpenAM
   * @param callbackFactory A function that returns am implementation of FRCallback
   */
  constructor(t, e) {
    this.payload = t, this.type = r.Step, this.callbacks = [], t.callbacks && (this.callbacks = this.convertCallbacks(t.callbacks, e));
  }
  /**
   * Gets the first callback of the specified type in this step.
   *
   * @param type The type of callback to find.
   */
  getCallbackOfType(t) {
    const e = this.getCallbacksOfType(t);
    if (e.length !== 1) throw new Error(`Expected 1 callback of type "${t}", but found ${e.length}`);
    return e[0];
  }
  /**
   * Gets all callbacks of the specified type in this step.
   *
   * @param type The type of callback to find.
   */
  getCallbacksOfType(t) {
    return this.callbacks.filter(e => e.getType() === t);
  }
  /**
   * Sets the value of the first callback of the specified type in this step.
   *
   * @param type The type of callback to find.
   * @param value The value to set for the callback.
   */
  setCallbackValue(t, e) {
    const a = this.getCallbacksOfType(t);
    if (a.length !== 1) throw new Error(`Expected 1 callback of type "${t}", but found ${a.length}`);
    a[0].setInputValue(e);
  }
  /**
   * Gets the step's description.
   */
  getDescription() {
    return this.payload.description;
  }
  /**
   * Gets the step's header.
   */
  getHeader() {
    return this.payload.header;
  }
  /**
   * Gets the step's stage.
   */
  getStage() {
    return this.payload.stage;
  }
  convertCallbacks(t, e) {
    return t.map(l => (e || c)(l) || c(l));
  }
}
export { i as default };