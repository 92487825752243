import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective {

  constructor(private elementRef: ElementRef) { };
  @Input()
  set autofocus(value: any) {
    if (value == undefined || (value && value !== 'false')) {
      setTimeout(() => {
        this.elementRef.nativeElement.focus();
    }, 200);

    }
  }
}
