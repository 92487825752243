import { ActionTypes as l } from "../config/enums.js";
import f from "../config/index.js";
import m from "../token-storage/index.js";
import { isOkOr4xx as k } from "../util/http.js";
import v from "../util/pkce.js";
import { withTimeout as _ } from "../util/timeout.js";
import { stringify as p, getEndpointPath as E, resolve as U } from "../util/url.js";
import T from "../util/middleware.js";
const A = {
  // AM error for consent requirement
  AuthenticationConsentRequired: "Authentication or consent required",
  // Ping federate consent requirement
  AuthenticationIsRequired: "Authentication is required.",
  // Manual iframe error
  AuthorizationTimeout: "Authorization timed out",
  // Chromium browser error
  FailedToFetch: "Failed to fetch",
  // Mozilla browser error
  NetworkError: "NetworkError when attempting to fetch resource.",
  // Webkit browser error
  CORSError: "Cross-origin redirection",
  // prompt=none errors
  InteractionNotAllowed: "The request requires some interaction that is not allowed.",
  // PingOne login error
  LoginRequired: "User authentication is required",
  RequestRequiresConsent: "The request requires consent."
};
class b {
  static async createAuthorizeUrl(e) {
    const {
        clientId: r,
        middleware: s,
        redirectUri: o,
        scope: n
      } = f.get(e),
      t = {
        ...e.query,
        client_id: r,
        redirect_uri: o,
        response_type: e.responseType,
        scope: n,
        state: e.state,
        ...(e.prompt ? {
          prompt: e.prompt
        } : {})
      };
    if (e.verifier) {
      const a = await v.createChallenge(e.verifier);
      t.code_challenge = a, t.code_challenge_method = "S256";
    }
    const i = T({
        url: new URL(this.getUrl("authorize", t, e)),
        init: {}
      }, {
        type: l.Authorize
      }),
      {
        url: c
      } = i(s);
    return c.toString();
  }
  /**
   * Calls the authorize URL with an iframe. If successful,
   * it returns the callback URL with authentication code,
   * optionally using PKCE.
   * Method renamed in v3.
   * Original Name: getAuthorizeUrl
   * New Name: getAuthCodeByIframe
   */
  static async getAuthCodeByIframe(e) {
    const r = await this.createAuthorizeUrl({
        ...e,
        prompt: "none"
      }),
      {
        serverConfig: s
      } = f.get(e);
    return new Promise((o, n) => {
      const t = document.createElement("iframe"),
        i = () => {};
      let c = i,
        a = i,
        d = 0;
      a = () => {
        clearTimeout(d), t.removeEventListener("load", c), t.remove();
      }, c = () => {
        if (t.contentWindow) {
          const u = t.contentWindow.location.href;
          (this.containsAuthCode(u) || this.containsAuthError(u)) && (a(), o(u));
        }
      }, d = setTimeout(() => {
        a(), n(new Error(A.AuthorizationTimeout));
      }, s.timeout), t.style.display = "none", t.addEventListener("load", c), document.body.appendChild(t), t.src = r;
    });
  }
  /**
   * Exchanges an authorization code for OAuth tokens.
   */
  static async getOAuth2Tokens(e) {
    const {
        clientId: r,
        redirectUri: s
      } = f.get(e),
      o = {
        client_id: r,
        code: e.authorizationCode,
        grant_type: "authorization_code",
        redirect_uri: s
      };
    e.verifier && (o.code_verifier = e.verifier);
    const n = p(o),
      t = {
        body: n,
        headers: new Headers({
          "Content-Length": n.length.toString(),
          "Content-Type": "application/x-www-form-urlencoded"
        }),
        method: "POST"
      },
      i = await this.request("accessToken", void 0, !1, t, e),
      c = await this.getBody(i);
    if (i.status !== 200) {
      const u = typeof c == "string" ? `Expected 200, received ${i.status}` : this.parseError(c);
      throw new Error(u);
    }
    const a = c;
    if (!a.access_token) throw new Error("Access token not found in response");
    let d;
    return a.expires_in && (d = Date.now() + a.expires_in * 1e3), {
      accessToken: a.access_token,
      idToken: a.id_token,
      refreshToken: a.refresh_token,
      tokenExpiry: d
    };
  }
  /**
   * Gets OIDC user information.
   */
  static async getUserInfo(e) {
    const r = await this.request("userInfo", void 0, !0, void 0, e);
    if (r.status !== 200) throw new Error(`Failed to get user info; received ${r.status}`);
    return await r.json();
  }
  /**
   * Invokes the OIDC end session endpoint.
   * Can result in a redirect to `/signoff` if using PingOne
   * It's best to explicitly provide the logout redirect URL in options
   *
   * @function endSession - call authorization server to end associated session
   * @param options {LogoutOptions} - an extension of ConfigOptions, but with two additional props
   * @param options.logoutRedirectUri {string} - the URL you want the AS to redirect to after signout
   * @param options.redirect {boolean} - to explicitly deactivate redirect, pass `false`
   */
  static async endSession(e) {
    const r = {
      ...e
    };
    delete r.redirect, delete r.logoutRedirectUri;
    const s = {},
      o = await m.get();
    s.id_token_hint = o && o.idToken || (e && "idToken" in e ? e.idToken : "");
    const n = await this.request("endSession", s, !0, void 0, r, {
      redirect: e?.redirect,
      logoutRedirectUri: e?.logoutRedirectUri
    });
    if (!k(n)) throw new Error(`Failed to end session; received ${n.status}`);
    return n;
  }
  /**
   * Immediately revokes the stored access token.
   */
  static async revokeToken(e) {
    const {
        clientId: r
      } = f.get(e),
      s = await m.get(),
      o = s && s.accessToken,
      n = {
        client_id: r
      };
    o && (n.token = o);
    const t = {
        body: p(n),
        credentials: "include",
        headers: new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        }),
        method: "POST"
      },
      i = await this.request("revoke", void 0, !1, t, e);
    if (!k(i)) throw new Error(`Failed to revoke token; received ${i.status}`);
    return i;
  }
  static async request(e, r, s, o, n, t) {
    const {
        redirectUri: i,
        middleware: c,
        serverConfig: a
      } = f.get(n),
      d = t?.logoutRedirectUri ? t.logoutRedirectUri : i,
      u = this.getUrl(e, r, n),
      g = w => {
        switch (w) {
          case "accessToken":
            return l.ExchangeToken;
          case "endSession":
            return l.EndSession;
          case "revoke":
            return l.RevokeToken;
          default:
            return l.UserInfo;
        }
      };
    if (o = o || {}, s) {
      const w = await m.get(),
        y = w && w.accessToken;
      o.credentials = "include", o.headers = o.headers || new Headers(), o.headers.set("Authorization", `Bearer ${y}`);
    }
    const h = T({
      url: new URL(u),
      init: o
    }, {
      type: g(e)
    })(c);
    return g(e) === l.EndSession &&
    // endSession action only
    t?.redirect === !0 ? (h.url.searchParams.append("post_logout_redirect_uri", d || ""), window.location.assign(h.url.toString()), new Response()) : g(e) === l.EndSession &&
    // endSession action only
    t?.redirect !== !1 && (
    // Only `false` explicitly disables this behavior for rare edge cases
    // If we explicitly get a logout redirect URL, then that's enough of a hint to redirect
    // If we don't have that, let's see if they are calling the typical PingOne `/signoff` endpoint
    t?.logoutRedirectUri || this.getUrl("endSession").includes("/as/signoff")) ? (h.url.searchParams.append("post_logout_redirect_uri", d || ""), window.location.assign(h.url.toString()), new Response()) : await _(fetch(h.url.toString(), h.init), a.timeout);
  }
  static containsAuthCode(e) {
    return !!e && /code=([^&]+)/.test(e);
  }
  static containsAuthError(e) {
    return !!e && /error=([^&]+)/.test(e);
  }
  static async getBody(e) {
    const r = e.headers.get("Content-Type");
    return r && r.indexOf("application/json") > -1 ? await e.json() : await e.text();
  }
  static parseError(e) {
    if (e) {
      if (e.error && e.error_description) return `${e.error}: ${e.error_description}`;
      if (e.code && e.message) return `${e.code}: ${e.message}`;
    }
  }
  static getUrl(e, r, s) {
    const {
        realmPath: o,
        serverConfig: n
      } = f.get(s),
      t = E(e, o, n.paths);
    let i = U(n.baseUrl, t);
    return r && (i += `?${p(r)}`), i;
  }
}
export { A as allowedErrors, b as default };