import { ViewContainerRef } from "@angular/core";
import { Directive } from "@angular/core";

  @Directive({
    selector: '[dnErrorTarget]',
    exportAs: 'dnErrorTarget'
  })
  export class DnErrorTargetDirective  {      
      constructor(private _viewContainerRef: ViewContainerRef){}    
      public get viewContainerRef() {
          return this._viewContainerRef;
      }
  }