import { AfterViewInit, Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[uniqueValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UniqueValueDirective,
      multi: true,
    }
  ]
})
export class UniqueValueDirective implements Validator {
  private uniqueVal: ValidationErrors | null = null;
  constructor() { }
  private _onChange?: () => void

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
       return this.uniqueVal; //this.uniqueVal ? null : {'uniqueValue':true}
    } 
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onChange = fn
  }

  @Input('uniqueValue')
  public set uniqueValue(val: ValidationErrors | null) {
    this.uniqueVal = val;
    if (this._onChange && val!=null) {
      this._onChange()
    }
  }
}
