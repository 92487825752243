import { StepType as s } from "./enums.js";
class r {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    this.payload = e, this.type = s.LoginSuccess;
  }
  /**
   * Gets the step's realm.
   */
  getRealm() {
    return this.payload.realm;
  }
  /**
   * Gets the step's session token.
   */
  getSessionToken() {
    return this.payload.tokenId;
  }
  /**
   * Gets the step's success URL.
   */
  getSuccessUrl() {
    return this.payload.successUrl;
  }
}
export { r as default };