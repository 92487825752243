import { Component, inject } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'password-guidelines-Modal',
  templateUrl: './password-guidelines-modal.component.html'
})
export class PasswordGuidelinesModalComponent {
	activeModal = inject(NgbActiveModal);
}
