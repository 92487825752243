import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, FormControl, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DnValidators } from './dn-validators';

@Directive({
  selector: '[textMatch]'
})
export class TextMatchDirective implements Validator, AfterViewInit {

  @Input() textMatch = true;
  @Input() sourceTextCtrl !: NgModel;
  textVal !: string ;
  @Input()
  set textValue(val:string) {
    this.textVal = val;
    this.ngControl.control?.updateValueAndValidity();
  }

  private _subscription !: Subscription | undefined;

  constructor(private ngControl: NgControl) { }

  ngAfterViewInit(): void {    
    if (this.textMatch) {
      this.ngControl.control?.setValidators(c =>this.validate(c));
      this._subscription = this.sourceTextCtrl?.valueChanges?.subscribe((val) => {  
        this.ngControl.control?.updateValueAndValidity();
      }); 
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {   
    let val = this.sourceTextCtrl ? this.sourceTextCtrl.value : this.textVal;
    return DnValidators.textMatch(val)(control); 
  }
 
  @HostListener('window:beforeunload')  
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();      
  }

}
