<div class=" sticky-sm-top" *ngIf="globalError"> 
    <div class="alert alert-danger alert-dismissible fade show rounded-0 text-center">
      <span style='font-size: 1.5em'><i class="fa-solid fa-triangle-exclamation"></i></span>
      <span class="ps-3">
        <span *ngIf='globalError[ERRORS.INVALID_STAGE]'>Unable to Proceed. </span>
        <span *ngIf='globalError[ERRORS.UNEXPECTED_STAGE]'>We encountered an issue completing your request. <br> </span>
        <span *ngIf='!authorizationError'>An error has occured. <br> Please refresh the page. If the error continues, please contact Customer Service at <a href="tel:{{'support.phone' | translate}}" class="link">{{'support.phone' | translate}}</a>.</span>
        <span *ngIf='exceptionId'>Please refer to error code <strong>{{exceptionId}}</strong> when reporting this error.</span>
      </span>
      <button type="button" class="btn-close" (click)="dismiss()" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>