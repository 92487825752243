<div dnErrorControl="password" #errorControl="dnErrorControl">
    <fieldset class="row" #inputPasswordType="ngModelGroup" [ngModelGroup]="name" >

        <div class="mt-3 form-group" *ngIf="passwordOnly != true">
            <label for="newPassword">{{newPasswordLabel}}</label>
            <div class="input-group">
                <input [type]="textType" class="form-control" id="newPassword{{name}}" name="newPassword" placeholder="Enter password"
                    [(ngModel)]="newPassword"  #newPasswordCtrl="ngModel"
                    (focus)="displayRules(true)" (blur)="displayRules(false)"
                    required [minlength]="passwordMinLen" [maxlength]="passwordMaxLen"
                    [validPassword]="true" [firstName]="firstName" [lastName]="lastName" [username]="username"
                    [uniqueValue]="hasValErrors"
                    dnDisplayErrors [errorTarget]="errTarget"
                    [errorMessages]="{'minlength': 'Password must be at least '+passwordMinLen+' characters long.',
                                      'min_length': 'Password must be at least '+passwordMinLen+' characters long.',
                                      'maxLength': 'Password cannot be longer than '+passwordMaxLen+' characters.',
                                      'max_length': 'Password cannot be longer than '+passwordMaxLen+' characters.',
                                      'failedPolicy': 'Please try another password'}"
                    [attr.aria-describedby]="addonWrappingId+name">

                <button type="button" tabindex="-1" class="input-group-text" id="showHide{{name}}" title="Show password" (blur)="toggleTextType('textType',true)" (click)="toggleTextType('textType')"><i class="fa" [ngClass]="{ 'fa-eye-slash': textType=='password','fa-eye': textType!='password'}"></i></button>

                <button type="button" tabindex="-1" class="input-group-text rounded-right-border" [id]="addonWrappingId+name" (click)="openPasswordGuidelinesModal()">
                    <i title="Click here for password guidelines" class="fa-solid fa-circle-info text-primary"></i>
                </button>
                <div dnErrorTarget #errTarget="dnErrorTarget"></div>
            </div>


            <div class="smaller-font">
                <password-rules [ctrl]="newPasswordCtrl" [showRules]="showRules"></password-rules>
            </div>
        </div>

        <div class="mt-3 form-group">
            <div class="">
                <label for="password" *ngIf="passwordLabel">{{passwordLabel}}</label>
                 <ng-container *ngIf="passwordLabel && inlineForgotPassword">
                    <ng-container *ngTemplateOutlet="forgotPassword; context: {$implicit: 'd-none d-md-flex justify-content-end mt-md-n4'}"></ng-container>
                </ng-container>
                <div class="input-group">
                  <input [type]="pwdTextType"  class="form-control" id="password{{name}}" name="password" [placeholder]="passwordPlacehold"
                      [(ngModel)]="confirmPassword"
                      required [maxlength]="passwordMaxLen"
                      [textMatch]="passwordOnly != true" [textValue]="newPassword"
                      dnDisplayErrors [errorTarget]="pwdErrTarget"
                      [errorMessages]="{'textMatch': 'Passwords do not match'}"
                      [attr.aria-describedby]="showHidePwdId +name"
                  >
                  <button type="button" tabindex="-1" class="input-group-text rounded-right-border"
                          [id]="showHidePwdId +name" title="Show password"

                          (blur)="toggleTextType('pwdTextType',true)" (click)="toggleTextType('pwdTextType')">
                          <i class="fa" [ngClass]="{ 'fa-eye-slash': pwdTextType=='password','fa-eye': pwdTextType!='password','rounded-right-border': passwordOnly==true}"></i>
                  </button>
                  <button *ngIf="passwordOnly != true" type="button" tabindex="-1" class="input-group-text ngHide rounded-right-border" >
                    <i class="fa-solid fa-circle-info text-primary"></i>
                  </button>
                  <div dnErrorTarget #pwdErrTarget="dnErrorTarget"></div>
                </div>


                <ng-container *ngIf="passwordLabel && inlineForgotPassword">
                  <ng-container *ngTemplateOutlet="forgotPassword; context: {$implicit: 'd-flex d-md-none justify-content-end'}"></ng-container>
                </ng-container>
            </div>

        </div>

    </fieldset>
</div>

<ng-template #forgotPassword let-classes>
    <div [ngClass]="classes">
        <a href class="link" (click)="onForgotPassword($event)">Forgot password</a>
    </div>
 </ng-template>
