import { Component } from '@angular/core';
import { DnErrorMessageService } from './dn-common/dn-error-display/dn-error-message.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Login and Registration';
  constructor(private dnErrorMessageService: DnErrorMessageService) { 
    this.dnErrorMessageService.errorMessages = environment.common.errorMessages;
  }  
}
