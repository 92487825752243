import e from "./index.js";
class r extends e {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(t) {
    super(t), this.payload = t;
  }
  /**
   * Gets the reCAPTCHA site key.
   */
  getSiteKey() {
    return this.getOutputByName("recaptchaSiteKey", "");
  }
  /**
   * Sets the reCAPTCHA result.
   */
  setResult(t) {
    this.setInputValue(t);
  }
}
export { r as default };