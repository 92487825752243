   <dn-progress-spinner *ngIf="showSpinner"></dn-progress-spinner>
<div class="col-lg-11 col-md-11 col-sm-12 shadow-lg rounded mt-5 ms-md-5 mb-5">
		<div class="d-flex p-4" *ngIf="!registered">
			<div ngbNav #nav="ngbNav"[(activeId)]="active" class="nav-pills flex-column col-auto"
				orientation="vertical">
				<div class="row text-primary">
					<h5 class="mt-2 d-flex justify-content-center ">
						<i class="fa-regular fa-user fa-2xl"></i><br>
					</h5>
					<h5 class="d-flex justify-content-center">
						<span>Register</span>
					</h5>
				</div>
				<ng-container [ngbNavItem]="step.stage.toString()" *ngFor="let step of steps" [disabled]="!unlockedStage(step.stage)">
					<button ngbNavLink>
						<i class="mt-1 ms-2 fa-solid" [ngClass]="{'fa-lock': !unlockedStage(step.stage), 'fa-lock-open': unlockedStage(step.stage) && !hasStepCompleted(step.stage), 'fa-check': hasStepCompleted(step.stage)}" style="float: left;" ></i>
						<span class="d-none d-sm-inline ms-3" style="float: inline-start;">{{step.navText}}</span>
					</button>

					<ng-template ngbNavContent *ngIf="step.stage==Stage.TOU">
						<terms-of-use [touContent]="'tou.content' | translate"
								[touCheckBoxName]="'tou.checkBoxName' | translate"
								(touAcceptedEvent)="hasStepResponse($event,step.stage)">
						</terms-of-use>
					</ng-template>

					<ng-template ngbNavContent *ngIf="step.stage==Stage.USER_IDENTITY">
						<div class="col-md-10">
							<input-identity [autofocus]="focusIdentity"
											(OnIdentityAnswer)="hasStepResponse($event,step.stage)">
							</input-identity>
						</div>
					</ng-template>

					<ng-template ngbNavContent *ngIf="step.stage==Stage.VERIFIED_EMAIL">
						<div class="col-md-10">
							<verify-email   instructions="{{'registration.edit-email' | translate}}"
											[autofocus]="focusemail"
											[loginUrl]="loginRedirect"
											(onVerifyEmail)="hasStepResponse($event,step.stage)">
							</verify-email>
						</div>
					</ng-template>

					<ng-template ngbNavContent *ngIf="step.stage==Stage.USER_CREATION">
						<registration-form (onRegister)="hasStepResponse($event,step.stage)"
											(onRegisterEmail)="hasStepResponse($event,step.stage)"
											(onRegistrationRestart)="restartJourney()"
									buttonText="{{'registration.button-text' | translate}}"
									[autofocus]="focusemail">
						</registration-form>
					</ng-template>

				</ng-container>


			</div>
			<div class="d-flex ms-4 text-primary">
				<div class="vr"></div>
			</div>
			<div [ngbNavOutlet]="nav" class="mt-3 ms-4 col-md-8 pt-5"></div>
		</div>
		<div class=" rounded p-1 text-muted smaller-font" *ngIf="!registered" >
			<p style="text-align: center;">
			  {{'registration.reg-contact-info'| translate}} <a href="tel:{{'support.phone' | translate}}" class="link">{{'support.phone' | translate}}</a>.</p>
		</div>
</div>

