import { PolicyKey as n } from "./enums.js";
import i from "./message-creator.js";
class y {
  /**
   * Parses policy errors and generates human readable error messages.
   *
   * @param {Step} err The step containing the error.
   * @param {MessageCreator} messageCreator
   * Extensible and overridable custom error messages for policy failures.
   * @return {ProcessedPropertyError[]} Array of objects containing all processed policy errors.
   */
  static parseErrors(e, r) {
    const s = [];
    return e.detail && e.detail.failedPolicyRequirements && e.detail.failedPolicyRequirements.map(t => {
      s.push.apply(s, [{
        detail: t,
        messages: this.parseFailedPolicyRequirement(t, r)
      }]);
    }), s;
  }
  /**
   * Parses a failed policy and returns a string array of error messages.
   *
   * @param {FailedPolicyRequirement} failedPolicy The detail data of the failed policy.
   * @param {MessageCreator} customMessage
   * Extensible and overridable custom error messages for policy failures.
   * @return {string[]} Array of strings with all processed policy errors.
   */
  static parseFailedPolicyRequirement(e, r) {
    const s = [];
    return e.policyRequirements.map(t => {
      s.push(this.parsePolicyRequirement(e.property, t, r));
    }), s;
  }
  /**
   * Parses a policy error into a human readable error message.
   *
   * @param {string} property The property with the policy failure.
   * @param {PolicyRequirement} policy The policy failure data.
   * @param {MessageCreator} customMessage
   * Extensible and overridable custom error messages for policy failures.
   * @return {string} Human readable error message.
   */
  static parsePolicyRequirement(e, r, s = {}) {
    const t = typeof r == "string" ? JSON.parse(r) : {
        ...r
      },
      a = t.policyRequirement,
      o = s[a] || i[a] || i[n.UnknownPolicy],
      c = t.params ? {
        ...t.params,
        policyRequirement: a
      } : {
        policyRequirement: a
      };
    return o(e, c);
  }
}
export { n as PolicyKey, y as default };