import { AfterViewInit, Directive, HostListener, Input } from '@angular/core';
import { AbstractControl, NgControl, NgModel, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[hasFrError]'
})
export class HasFrErrorDirective implements Validator, AfterViewInit {
    @Input() hasFrError !: string;  
    private knownErrors = environment.common.fr.knownErrorCodes;
    private errorMessages: any = environment.common.fr.knownMessages;

    private _subscription !: Subscription | undefined;
  

    constructor(private ngControl: NgControl) { }
  
    ngAfterViewInit(): void {    
        this.ngControl.control?.setValidators(c =>this.validate(c));
    }
  
    validate(control: AbstractControl): ValidationErrors | null {  
        if (control.value) { 
          let knownError = this.knownErrors.findIndex(e => e == control.value) > -1;
          let errorId = 'loginFailure'+(knownError ? control.value : 'Unknown');

          if (this.hasFrError) {
            let errorMessage = this.errorMessages.find((m:any) =>  this.hasFrError.toLowerCase().includes(m.message.toLowerCase()));
            if (errorMessage) {
               errorId = errorMessage.id;
            }
          }
          return { [errorId]: true, value: control.value } 
        }
        return null;     
    }
   
    @HostListener('window:beforeunload')  
    ngOnDestroy(): void {
        this._subscription?.unsubscribe();      
    }
  
}
