import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ForgeRockService } from '../../../shared/services/forge-rock.service';

@Component({
  selector: 'provider-base',
  templateUrl: './provider-base.component.html'
})
export class ProviderBaseComponent implements OnInit {
  showSpinner = false;
  subscription !: Subscription;
  constructor(private FRService: ForgeRockService){}

  ngOnInit(): void {
    this.subscription = this.FRService.OnRedirecting.subscribe((resp:any)=>{
      if (resp && resp.redirecting) {
        this.showSpinner = resp.redirecting;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }
}
