import { AfterViewInit, Directive, HostListener , Input} from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { DnValidators } from './dn-validators';
import { Subscription } from 'rxjs/internal/Subscription';
import { ALPHANUMERIC_SYMBOLS_ONLY_PATTERN } from './dn-validators';
import { USER_NAME_NOT_ALLOWED_PATTERH } from './dn-validators';

@Directive({
  selector: '[validUserName]'
})
export class ValidUserNameDirective implements Validator, AfterViewInit {
  @Input('validUserName') isEnabled : boolean = true;
  private _subscription !: Subscription | undefined;

  constructor(private ngControl: NgControl) { }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {

    if (!this.isEnabled) return null;

    const userNameAlphanumericOnly = DnValidators.shouldMatchAtLeastOne([ALPHANUMERIC_SYMBOLS_ONLY_PATTERN, 'userNameAlphanumericOnly'])(control);
    const userNameShouldNotMatch = DnValidators.shouldNotMatch([USER_NAME_NOT_ALLOWED_PATTERH, 'userNameShouldNotMatch'])(control);

    return {
      ...userNameAlphanumericOnly ,
      ...userNameShouldNotMatch
    }
  }

  ngAfterViewInit(): void {
    this.ngControl.control?.setValidators((c: AbstractControl<any, any>) =>this.validate(c));
  }
  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
      this._subscription?.unsubscribe();
  }
}
