<form id="loginForm" name="loginForm" #loginForm="ngForm" (ngSubmit)="onSubmit()"
                dnErrorControl="errorControl" #errorControl="dnErrorControl" scrollToErrorForm>

            <dn-confirmation #dnConfirm></dn-confirmation>

            <div>
                <input class="form-control" type="hidden" id="Err" name="Err" [(ngModel)]="errorCode"
                        dnDisplayErrors #ErrModel="ngModel" [hasFrError]="errorMessage"
                        [errorMessages]="{'loginFailureUnknown': errorMessage}">
            </div>


            <div *ngIf='nextStage == Stage.LOGIN'>
                <input-username  name="username" [passwordOnly]="true" [showUsernameGuidelines]="false" [autofocus]="nextStage == Stage.LOGIN"></input-username>
                <input-password  name="passwords" passwordLabel="Password" passwordPlacehold="Enter password"></input-password>
            </div>

            <otp-choices [stage]="nextStage" (OnOtpMethodSelected)="setOtpChoice($event)" *ngIf="nextStage == Stage.OTP_METHOD"></otp-choices>

            <input-otp name="onetimePassword" *ngIf="nextStage == Stage.OTP || nextStage == Stage.OTP_RESEND"
                [parentUrl]="getParentUrl()"
                [disabled]="resending"
                [otpInstruction]="otpInstruction"
                [otpMethod]="otpChoice.otpMethod"
                [otpMaskedValue]="otpChoice.otpMaskedValue"
                (OnResendCode)="handleResendCode($event)"
                [stage]="nextStage"
                [autofocus]="nextStage == Stage.OTP || nextStage == Stage.OTP_RESEND">
            </input-otp>

            <div class="mt-4 d-flex justify-content-center" *ngIf="nextStage!=undefined">
                <button type="submit" class="btn btn-primary"  #subButton
                    [disabled]="!loginForm.form.valid || submitted || resending">{{nextStage == Stage.OTP ? oneTimeButtonText:buttonText}} <i class="fa-solid fa-spinner" *ngIf="submitted"></i></button>
            </div>

        </form>
