import { AfterViewInit, Component, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ControlContainer, NgForm, NgModel, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DnErrorControlDirective } from '../../dn-common/dn-error-display/directives/dn-error-control.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractErrorControlComponent } from '../abstract-error-control/abstract-error-control.component';
import { AppFormsService } from '../app-forms.service';
import { PasswordGuidelinesModalComponent } from '../../dn-common/components/password-guidelines-modal/password-guidelines.component-modal';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { PasswordRulesComponent } from '../../dn-common/components/password-rules/password-rules.component';
import { PasswordErrors } from '../../dn-common/directives/valid-password.directive';

@Component({
  selector: 'input-password',
  templateUrl: './input-password.component.html',
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }]
})
export class InputPasswordComponent  extends AbstractErrorControlComponent implements OnInit, AfterViewInit{
  protected override dispayErrorList = Object.values(PasswordErrors).map(k => PasswordErrors[k].toString());
  @Input() passwordOnly : boolean = true;
  @Input() passwordLabel !: string;
  @Input() newPasswordLabel = "Password";
  @Input() passwordPlacehold = 'Confirm password'
  @Input() name = 'passwords';
  @Input() inlineForgotPassword = false;
  @Input() firstName !: any;
  @Input() lastName !: any;
  @Input() registeredUsername !: any;
  protected _valErrors : ValidationErrors | null = null;
  @Input()
  public set hasValErrors(val: ValidationErrors | null) {
    this._valErrors = val;
  }
  @ViewChild('newPasswordCtrl') ctrl !: NgModel;

  newPassword !: string;
  confirmPassword !: string;
  showRules : boolean = false;
  textType = 'password';
  pwdTextType = 'password';
  username !: string;
  private _subscription !: Subscription | undefined;
  passwordMinLen = environment.common.constants.passwordMinLength;
  passwordMaxLen = environment.common.constants.passwordMaxLength;
  
  showHidePwdId = "showHidePwd";
  addonWrappingId = "addon-wrapping";

  constructor(private modalService : NgbModal,
              private router: Router,
              private route:ActivatedRoute,
              private loginForm: NgForm,
              override formsService: AppFormsService
  ) {
    super(formsService);
  }

  ngOnInit(): void {
    this.dispayErrorList.push('textMatch');
    if (this.registeredUsername) {
      this.username = this.registeredUsername;
    }
  }
  override ngAfterViewInit(): void {
     super.ngAfterViewInit();
     this.showErrors(this.dispayErrorList);
     this.formsService.onUserNameChanged.subscribe(val =>{
        this.username = val;
     })
  }

  openPasswordGuidelinesModal() {
		const modalRef = this.modalService.open(PasswordGuidelinesModalComponent, { size: 'lg', centered: true, keyboard: false });
	}


  onForgotPassword(e:Event) {
    e.preventDefault();
    let action = 'forgot';
    let userEmail =  this.loginForm.form.controls['userEmail'];
    this.router.navigate(['../password',action], { relativeTo: this.route ,
                                                    skipLocationChange: false
                                                  });
  }


displayRules(showRule:boolean) {
  this.showRules=showRule;
  if (this.showRules) {
    this.showErrors('textMatch');
  } else{
    this.showErrors(this.dispayErrorList);
  }
}


  toggleTextType(name:string, isBlur?:boolean) {
    if (isBlur == true) {
      setTimeout(() => {
        if (name=='textType') {
          this.textType = 'password';
        }
        if (name=='pwdTextType') {
          this.pwdTextType = 'password';
        }
      },15000);

    }else{
      if (name=='textType') {
        this.textType = this.textType == 'password' ? 'text' : 'password';
      }
      if (name=='pwdTextType') {
        this.pwdTextType = this.pwdTextType == 'password' ? 'text' : 'password';
      }

    }
  }


}
