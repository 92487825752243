import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';
import { Stage } from '../../shared/services/forge-rock.interface';

@Component({
  selector: 'journey-change-email',
  templateUrl: './journey-change-email.component.html'
})
export class JourneyChangeEmailComponent extends AbstractJourneyComponent implements AfterViewInit{
 @Input() instructions !: string;
  @Input() 
  set loginUrl(val:string) {
    this.loginRedirect = val;
  }
  @Output() onProfileChanged = new EventEmitter<any>();

  sentVerificattionCode = false;
  submitted= false;
  override getTreeName(): string {
    return 'changeEmail';
  }

  override ngAfterViewInit():void {
    super.ngAfterViewInit();
    const profileItem = this.route.snapshot.params['item'];
  }


  changeEmail(data:any) {
    this.submitted = true;
     this.continueJournery(data).then((nextStage)=>{
        this.submitted = false;
        if (this.sentVerificattionCode) {
          this.onProfileChanged.emit({ unverifiedEmail: data.unverifiedEmail, loginRedirect: this.loginRedirect });
          this.sentVerificattionCode = false;
        }
     },(err)=>{
      this.submitted = false;
     }); 
 
 
  }

  private continueJournery(data:any):Promise<any> {
    return this.nextStageInJourney(data).then((nextStage)=>{
        if (this.nextStage == Stage.EMAIL_SENT) {
            this.sentVerificattionCode = true;
            return this.continueJournery(data);
        }
        return nextStage
    });
  }


}
