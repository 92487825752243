import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { startWith } from 'rxjs';

export enum ProfileType {
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD"
}
@Component({
  selector: 'provider-profile',
  templateUrl: './provider-profile.component.html'
})
export class ProviderProfileComponent implements OnInit {
  headerText !: string;
  successHeaderText !: string;
  successContent !: string;
  icon : string = 'fa-solid fa-key fa-2xl';
  redirecting = false
  translationData:any = {}
  
  profType !: ProfileType;
  constructor(
    private router: Router, 
    private route: ActivatedRoute
)  {}
  
  ngOnInit(): void {
      let type:string = this.route.snapshot.params['profileType']?.toUpperCase();
      this.profType = ProfileType[type as  keyof typeof ProfileType]
      switch (this.profType) {
        case ProfileType.EMAIL: 
             this.headerText = "Change Email";
             this.icon = 'fa-solid fa-envelope fa-2xl';
             this.successHeaderText = "Email Change Request Submitted"
             this.successContent = 'changeEmail.verification-code.content'
             break;
        case ProfileType.PASSWORD:
              this.headerText = "Change Password";
              this.icon = 'fa-solid fa-key fa-2xl';
              this.successHeaderText = "Success!"
              this.successContent = 'changePassword.success.content'
              break;     
      }

  }

  onStepComplete(data:any) {

    this.translationData = {
        emailAddr: data.unverifiedEmail,
        username: "",
        loginRedirect: this.profType == ProfileType.PASSWORD ? data.loginRedirect : "",
        hideEmail: ""
      }

      this.redirecting = true;
  }

  get ProfileType() {
    return ProfileType;
  }
}
