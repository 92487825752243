import f from "../config/index.js";
import { REQUESTED_WITH as u, X_REQUESTED_PLATFORM as t } from "../shared/constants.js";
import { isOkOr4xx as c } from "../util/http.js";
import { withTimeout as p } from "../util/timeout.js";
import { ActionTypes as h } from "../config/enums.js";
import g from "../util/middleware.js";
import { getEndpointPath as w, resolve as P } from "../util/url.js";
class M {
  /**
   * Ends the current session.
   */
  static async logout(i) {
    const {
        middleware: s,
        platformHeader: a,
        realmPath: n,
        serverConfig: r
      } = f.get(i),
      d = {
        credentials: "include",
        headers: new Headers({
          "Accept-API-Version": "protocol=1.0,resource=2.0",
          "X-Requested-With": u
        }),
        method: "POST"
      },
      l = `${w("sessions", n, r.paths)}?_action=logout`,
      m = P(r.baseUrl, l),
      e = g({
        url: new URL(m),
        init: d
      }, {
        type: h.Logout
      })(s);
    a && (e.init.headers instanceof Headers ? e.init.headers.set("X-Requested-Platform", t) : Array.isArray(e.init.headers) ? e.init.headers.push(["X-Requested-Platform", t]) : e.init.headers ? e.init.headers["X-Requested-Platform"] = t : e.init.headers = {
      "X-Requested-Platform": t
    });
    const o = await p(fetch(e.url.toString(), e.init), r.timeout);
    if (!c(o)) throw new Error(`Failed to log out; received ${o.status}`);
    return o;
  }
}
export { M as default };