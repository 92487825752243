class r {
  /**
   * @param payload The raw payload returned by OpenAM
   */
  constructor(e) {
    this.payload = e;
  }
  /**
   * Gets the name of this callback type.
   */
  getType() {
    return this.payload.type;
  }
  /**
   * Gets the value of the specified input element, or the first element if `selector` is not
   * provided.
   *
   * @param selector The index position or name of the desired element
   */
  getInputValue(e = 0) {
    return this.getArrayElement(this.payload.input, e).value;
  }
  /**
   * Sets the value of the specified input element, or the first element if `selector` is not
   * provided.
   *
   * @param selector The index position or name of the desired element
   */
  setInputValue(e, t = 0) {
    this.getArrayElement(this.payload.input, t).value = e;
  }
  /**
   * Gets the value of the specified output element, or the first element if `selector`
   * is not provided.
   *
   * @param selector The index position or name of the desired element
   */
  getOutputValue(e = 0) {
    return this.getArrayElement(this.payload.output, e).value;
  }
  /**
   * Gets the value of the first output element with the specified name or the
   * specified default value.
   *
   * @param name The name of the desired element
   */
  getOutputByName(e, t) {
    const n = this.payload.output.find(a => a.name === e);
    return n ? n.value : t;
  }
  getArrayElement(e, t = 0) {
    if (e === void 0) throw new Error(`No NameValue array was provided to search (selector ${t})`);
    if (typeof t == "number") {
      if (t < 0 || t > e.length - 1) throw new Error(`Selector index ${t} is out of range`);
      return e[t];
    }
    if (typeof t == "string") {
      const n = e.find(a => a.name === t);
      if (!n) throw new Error(`Missing callback input entry "${t}"`);
      return n;
    }
    if (typeof t == "object" && t.test && t.exec) {
      const n = e.find(a => t.test(a.name));
      if (!n) throw new Error(`Missing callback input entry "${t}"`);
      return n;
    }
    throw new Error("Invalid selector value type");
  }
}
export { r as default };