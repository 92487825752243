
<form id="verifyEmailForm" name="verifyEmailForm" #verifyEmailForm="ngForm" (ngSubmit)="onSubmit()"
    dnErrorControl="errorControl" #errorControl="dnErrorControl" novalidate>
    <p class="mt-3">{{instructions}}</p>
    <div class="col-md-9">
        <input-email name="businessEmail" [userEmail]="unverifiedEmail"
            [editable]="emailReadOnly() != true" [isVerifyEmail]="true"
            [isUnique]="uniqueEmailError"
            [autofocus]="true"
            [loginUrl]="loginRedirect">
          </input-email>
      </div>

    <div class="mt-3 d-flex justify-content-center"  *ngIf="emailReadOnly() != true">
        <button type="submit" class="btn btn-primary"
        title="Confirm Email"
        [disabled]="running">Confirm Email <i class="fa-solid fa-spinner" *ngIf="running"></i></button>
    </div>

  </form>

