import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value && value.trim()) {
      value = value.trim();
      return value.charAt(0).toUpperCase() + value.substring(1); 
    }
    return value;
  }

}
