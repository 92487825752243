import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppHttpInterceptor } from '../../services/app-http-interceptor';
import { ForgeRockService } from '../../services/forge-rock.service';
 
export enum ERRORS {
  AJAX = 'ajaxError',
  AUTHORIZATION = 'authorizationError',
  PAGINATION = 'paginationError',
  INVALID_STAGE = 'invalidStage',
  UNEXPECTED_STAGE = 'unexpectedStage'
}

@Component({
  selector: 'global-error',
  templateUrl: './global-error.component.html',
  styles: [
  ]
})
export class GlobalErrorComponent implements OnInit, OnDestroy {
  globalError: { [key: string]: boolean } | undefined = undefined;
  exceptionId: string = '';
  authorizationError: boolean = false;
  initialized: boolean = false;

  private subscriptions: { [key: string]: Subscription } = {};

  constructor(
    private appHttpInterceptor: AppHttpInterceptor,
    private FRService: ForgeRockService,
    private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.dismiss();
      }
    });
  }

  ngOnInit(): void {
    this.subscriptions[ERRORS.AJAX] = this.appHttpInterceptor.onAjaxError.subscribe((errorResp) => {
      this.errorHandler(ERRORS.AJAX, errorResp);
    });
    this.subscriptions[ERRORS.AUTHORIZATION] = this.appHttpInterceptor.onAuthorizationError.subscribe((errorResp) => {
      this.errorHandler(ERRORS.AUTHORIZATION, errorResp);
    });
    this.subscriptions[ERRORS.INVALID_STAGE] = this.FRService.onFRResponseError.subscribe((errorResp) =>{
      this.errorHandler(ERRORS.INVALID_STAGE, errorResp);
    })
    this.initialized = true;
  }

  ngOnDestroy(): void {
    Object.values(this.subscriptions).forEach( s => { 
      s.unsubscribe()
    }) ;
  }

  dismiss() {
    this.globalError = undefined;
  }

  private errorHandler(errorType: ERRORS, response: any) {
    if (this.initialized) {
      if (response && response.status === 403) {
        return;
      }
      setTimeout(() => {
        this.globalError = {};
        this.globalError[errorType] = true;
        if (response && response.data) {
          this.exceptionId = response.data.exceptionId;
        }
        window.setTimeout(function () {
          window.scroll(0, 0);
        }, 0);
      });
    }
  }
  get ERRORS() {
    return ERRORS;
  }
}
