import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, Observer, of, throwError } from 'rxjs';
import { FRStepError, Stage, UserInfo, UserModel } from './forge-rock.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url : string = "api/user/info";
  private _user = {} as UserInfo;
  private _model = {} as UserModel;
  private _modelObserver !: Observer<any>; 
  private _currentJourney !: string;

  private regModel = {} as UserModel;
  private duplicateDnEmails:any[] = [];

  constructor(private http: HttpClient
  ) { }

  public info(data:{}): Observable<UserInfo> {
    return this.http.get<UserInfo>(this.url,{params: {data:data['token' as keyof {}]}}).pipe(
      map(resp => {
        this.userInfo = resp ? resp : {} as UserInfo;
        if (Object.keys(this.userInfo).length > 0) {
          this.userInfo.token = data['token' as keyof {}];
          this.userInfo.userType = data['userType' as keyof {}]
          return this.userInfo;
        }
        throw new Error("Bad Request");
      }),
      catchError((e) => {
        console.error(e);
        this.userInfo = {} as UserInfo;
        throw e;
      })
    );
  }

  public get currentJournery() {
    return this._currentJourney;
  }

  public addDuplicateEmail() {
    let email = this._model.unverifiedEmail ? this._model.unverifiedEmail : this._model.dnSecurityEmail;
    this.duplicateDnEmails.push(email);
  }

  public shouldChangeEmail(email:any):boolean {
    return this.duplicateDnEmails.findIndex((e)=>e==email) > -1;
  }

  public get userInfo() {
    return this._user;
  }

  public get userType() {
    return this._user.userType;
  }

  public get user():UserModel {
    if (Object.keys(this._model).length === 0 || //check if registration data changed. If so, initialize model
          this._user.firstname !== this._model.firstname ||
          this._user.lastname !== this._model.lastname ||
          this._user.phoneNumber !== this._model.phoneNumber ||
          this._user.dnSecEmail !== this._model.dnSecurityEmail) {
            if(!this._model) {
              this._model = {} as UserModel;
            }
            this._model.firstname = this._user.firstname;
            this._model.lastname = this._user.lastname;
            this._model.phoneNumber = this._user.phoneNumber;
            this._model.dnSecurityEmail = this._user.dnSecEmail; //Do not change this 
            this._model.unverifiedEmail = this._user.dnSecEmail;
        }
    return {...this._model};
  }

  public resetUser() {
    this._user =  {} as UserInfo;
    this._model = {} as UserModel;
    return this.user;
  }

  public touAlreadyAccepted() {
    if (this._user.tokenId) {
      let accepted = sessionStorage.getItem(`${this._user.tokenId}.tou`);
      if (accepted) {
        this._model.touAccepted = JSON.parse(accepted)?.accepted;
      }
    }
  }

  public set touAccepted(status: boolean) {
    sessionStorage.setItem(`${this._user.tokenId}.tou`,JSON.stringify({accepted:status}));
    this._model.touAccepted = status;
    if (status) {
      this.resolveObserver(status);
    }else{
      this.rejectObserver({code:'401',message:'identityAnswer'} as FRStepError)
    }
  }

  public acceptTou(accepted:boolean,notifyParent:EventEmitter<any>|undefined):Observable<any> {
    if (accepted) {
        return this.createObserverAndNotifyParent(notifyParent,accepted);
    }else{
        sessionStorage.setItem(`${this._user.tokenId}.tou`,JSON.stringify({accepted:accepted}));
        window.location.reload();
    }
    return of(accepted);
  } 

  public isEmailsMatch() {
    return  this._model.unverifiedEmail && this._model.unverifiedEmail != "" && this._model.dnSecurityEmail == this._model.unverifiedEmail;
  }

  public get originalEmail() {
    return this._model.dnSecurityEmail;
  }

  public get touAccepted() {
    return this._model.touAccepted;
  }


  public get identityVerified() {
    return this._model.identityVerified;
  }

  public set identityVerified(verified:boolean) {
    this._model.identityVerified = verified; 
    if (verified) {
      this.resolveObserver(verified);
    }else{
      this.rejectObserver({code:'401',message:'identityAnswer'} as FRStepError)
    }
  }

  public setIdentityCheckMonthDay(identityAnswer : string, notifyParent:EventEmitter<any>):Observable<any> {
    this._model.identityAnswer = identityAnswer;
    this._model.identityVerified = false;
    return this.createObserverAndNotifyParent(notifyParent,this._model.identityAnswer);
  }

  public registerUser(userData : UserModel, notifyParent:EventEmitter<any>):Observable<any> {
    this.regModel = userData;
    this.regModel.dnSecurityEmail = this._model.dnSecurityEmail;
    this.regModel.unverifiedEmail = this._model.unverifiedEmail;
    this.regModel.validatePassword = false;
    this.regModel.validateUsername = false;
    return this.createObserverAndNotifyParent(notifyParent,this.regModel);
  }

  public set registered(response: any) {
    if (response.success && response.success == true) {  
        this._model.registered = true;      
        this.resolveObserver(this._model.registered);
    }else{
      this._model.registered = false;
      this.rejectObserver(response.error ? response.error :  {'loginFailureUnknown':true})
    }
  }

  public validateNewPassword(newPassword: string, notifyParent:EventEmitter<any>):Observable<any> {
    let observable = this.createObserverAndNotifyParent(notifyParent,{password: newPassword,validatePassword:true});
    return observable;
  }

  public set validatedPassword(response: any) {
    if (response.success && response.success == true) {   
      this.resolveObserver(true);
    }else{
      this.rejectObserver(response.error ? response.error :  {'loginFailureUnknown':true})
    }
  }

  public uniqueUsername(val : string, validate: boolean, notifyParent:EventEmitter<any>):Observable<any> {
    this._model.username = val;  
    this._model.validateUsername = validate;  
    let observable = this.createObserverAndNotifyParent(notifyParent,{username:val,validateUsername:validate});
    return observable;
  }

  public set username(response:any) {
    if (response.success && response.success == true) {
      this._model.validateUsername = false;
      this.resolveObserver(this._model.username);
    }else{
      this._model.username = undefined;
      this.rejectObserver(response.error ? response.error :  {'uniqueValue':true})
    }
  }

  public get username() {
    return this._model.username;
  }


  public uniqueEmail(val:string,notifyParent:EventEmitter<any>) {
    if (val == this._model.unverifiedEmail && this.shouldChangeEmail(val)) {
      return throwError(() => ({code:'409',message: 'uniqueEmail'}))
    }
    this._model.unverifiedEmail = val;
    let observable = this.createObserverAndNotifyParent(notifyParent,{unverifiedEmail:val});
    return observable;
  }

  public set emailConfirmed(response: any) {
    if (response.success && response.success == true && !this.shouldChangeEmail(this._model.unverifiedEmail)) {
      this._model.emailConfirmed = true;
      this.resolveObserver(this._model.emailConfirmed);
    }else{
      this._model.emailConfirmed = false;
      let message = response.error ? {code:'401',message: 'unknownError'}  : {code:'409',message: 'uniqueEmail'} ;
      this.rejectObserver(message as FRStepError)
    }
  }

  public get unVerifiedEmail(){
    return this._model.unverifiedEmail;
  }

  public get emailConfirmed():boolean {
    return this._model.emailConfirmed;
  }
  
  public get modelObserver() {
    return this._modelObserver;
  }

  public get identityCheckMonthDay():string | undefined {
    return this._model.identityAnswer;
  }

  public get registered() {
    return this._model.registered;
  }

  private set userInfo(userinfo:UserInfo) {
    this._user = userinfo;
  }

  private createObserverAndNotifyParent(notifyParent:EventEmitter<any> | undefined, value:any){
    let observable = new Observable((observer)=>{
      this._modelObserver = observer;
    })
    if (notifyParent) {
        notifyParent.emit(value);
    }
    return observable;
  }

  private resolveObserver(resolve ?:any) {
    if (this._modelObserver) {
        this._modelObserver.next(resolve);
        this._modelObserver.complete();
    }     
  }

  private rejectObserver(reject ?:any) {
    if (this._modelObserver) {
        this._modelObserver.error(reject);
      }
  }

}
