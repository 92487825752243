import n from "../util/pkce.js";
function a(e, t) {
  return `${t || "FR-SDK"}-authflow-${e}`;
}
function c(e) {
  const t = n.createVerifier(),
    r = n.createState(),
    o = a(e.clientId, e.prefix),
    s = {
      ...e,
      state: r,
      verifier: t
    };
  return [s, () => sessionStorage.setItem(o, JSON.stringify(s))];
}
function u(e, t) {
  const r = a(e, t),
    o = sessionStorage.getItem(r);
  sessionStorage.removeItem(r);
  try {
    return JSON.parse(o);
  } catch {
    throw new Error("Stored values for Auth URL could not be parsed");
  }
}
export { c as generateAndStoreAuthUrlValues, u as getStoredAuthUrlValues };